import { AutoWidthImage } from "@swan-io/lake/src/components/AutoWidthImage";
import { Box } from "@swan-io/lake/src/components/Box";
import { Space } from "@swan-io/lake/src/components/Space";
import { StyleSheet, Text } from "react-native";
import { fontColorVariants } from "../styles/constants";

const styles = StyleSheet.create({
  text: {
    fontSize: 10,
    textAlign: "center",
    color: fontColorVariants.zinc600,
  },
  selected: {
    fontSize: 10,
    color: fontColorVariants.white,
  },
});

type Props = {
  sourceUri: string;
  name: string;
  isSelected: boolean;
};

export const IconChoice = ({ sourceUri, name, isSelected }: Props) => {
  return (
    <Box alignItems="center">
      <AutoWidthImage sourceUri={sourceUri} height={40} />

      {name !== "" && (
        <>
          <Space height={12} />
          <Text style={[styles.text, isSelected && styles.selected]}>{name}</Text>
        </>
      )}
    </Box>
  );
};
