import { Box } from "@swan-io/lake/src/components/Box";
import { StyleSheet, Text } from "react-native";
import { borderColorVariants, fontColorVariants } from "../../../../styles/constants";
import { formatCurrency } from "../../../../utils/i18n";

const styles = StyleSheet.create({
  row: {
    width: "65%",
    borderTopWidth: 1,
    borderTopColor: borderColorVariants.gray400,
    gap: 24,
    padding: 12,
    marginTop: 24,
  },
  text: {
    fontSize: 16,
    fontWeight: "600",
    color: fontColorVariants.gray600,
  },
});

export const Total = ({ label, amount }: { label: string; amount: string }) => {
  return (
    <Box direction="row" justifyContent="end">
      <Box direction="row" justifyContent="spaceBetween" style={styles.row}>
        <Text style={styles.text}>{label}</Text>

        {amount && <Text style={styles.text}>{formatCurrency(parseFloat(amount), "EUR")}</Text>}
      </Box>
    </Box>
  );
};
