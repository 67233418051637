import { isNullish } from "@swan-io/lake/src/utils/nullish";
import { UploadArea, UploadFileStatus } from "@swan-io/shared-business/src/components/UploadArea";
import { useEffect } from "react";
import { useForm } from "react-ux-form";
import { match } from "ts-pattern";
import { t } from "../../../utils/i18n";
import { useLoading } from "../../context/LoadingContext";

export type FormValues = {
  File: FormValue;
};

type FormValue = UploadFileStatus[];

type Props = {
  cameraImage?: File | null;
  onUpload: (file: File) => Promise<void>;
};

export const UploadFile = ({ onUpload, cameraImage = null }: Props) => {
  const { setLoading } = useLoading();

  const { Field, getFieldState, setFieldValue } = useForm<FormValues>({
    File: { initialValue: [], strategy: "onSubmit" },
  });

  const setFinishedStatus = (filename: string, error?: string) => {
    const state = getFieldState("File").value;
    const status = error != null ? "failed" : "finished";
    setFieldValue(
      "File",
      state
        .filter(file => file.id !== filename)
        .map(file =>
          file.id === filename || file.id === "NO_ID_YET"
            ? { ...file, status, error, name: filename, id: filename }
            : null,
        )
        .filter(Boolean) as FormValue,
    );
  };

  const handleUpload = async (files: File[]) => {
    setLoading(true);
    const file = files[0];
    if (!isNullish(file)) {
      try {
        await onUpload(file);
        setFinishedStatus(file.name);
      } catch (error) {
        setFinishedStatus(file.name, (error as Error).message);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (cameraImage != null) {
      const state = getFieldState("File").value;
      setFieldValue("File", [...state, { id: "NO_ID_YET", status: "uploading", progress: 0 }]);
      void handleUpload([cameraImage]);
    }
  }, [cameraImage]);

  return (
    <Field name="File">
      {({ value, onChange, error }) => (
        <UploadArea
          accept={["application/pdf", "image/*"]}
          documents={value}
          onDropAccepted={files => {
            onChange([{ id: "NO_ID_YET", status: "uploading", progress: 0 }]);
            void handleUpload(files);
          }}
          onDropRejected={files => {
            onChange([{ id: "NO_ID_YET", status: "uploading", progress: 0 }]);
            if (files.length > 0 && files[0]?.file) {
              const message = match(files[0].errors?.[0]?.code)
                .with("file-invalid-type", () => t("upload.invalidType", { supportedTypes: "PDF" }))
                .otherwise(() => files[0]?.errors?.[0]?.message);
              setFinishedStatus(files[0].file.name, message);
            }
          }}
          icon="cloud-sync-regular"
          description={t("upload.description", {
            supportedTypes: "PDF, JPG, PNG",
            maxSizeMB: 10,
          })}
          error={error}
        />
      )}
    </Field>
  );
};
