import { AutoWidthImage } from "@swan-io/lake/src/components/AutoWidthImage";
import { Box } from "@swan-io/lake/src/components/Box";
import { Space } from "@swan-io/lake/src/components/Space";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { StyleSheet, Text, View } from "react-native";
import background from "../assets/img/bg-subscription-modal.webp";
import {
  backgroundColorVariants,
  borderColorVariants,
  fontColorVariants,
} from "../styles/constants";
import { t } from "../utils/i18n";
import { CloseIcon } from "../utils/icons";
import { Router } from "../utils/routes";
import { useSubscription } from "../utils/subscription";
import { Button } from "./forms/Button";

const styles = StyleSheet.create({
  base: {
    backgroundColor: backgroundColorVariants.blue,
    margin: -48,
    marginTop: -96,
    minHeight: 200,
    borderRadius: 8,
    padding: 24,
    paddingBottom: 160,
  },
  close: {
    position: "absolute",
    zIndex: 1,
    top: 10,
    right: 10,
    padding: 12,
    color: fontColorVariants.white,
  },
  title: {
    color: fontColorVariants.white,
    fontSize: 20,
    fontWeight: "700",
    marginBottom: 16,
    textAlign: "center",
  },
  text: {
    color: fontColorVariants.white,
    textAlign: "center",
    maxWidth: 400,
    marginHorizontal: "auto",
  },
  cta: {
    color: fontColorVariants.blue,
    borderColor: borderColorVariants.blue,
  },
  cta2: {
    width: "auto",
    color: fontColorVariants.white,
  },
  background: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },
});

export const SubscriptionModal = () => {
  const { title, description, visible, closeModal } = useSubscription();

  const handleCTA = () => {
    closeModal();
    Router.push("UserSubscription");
  };

  return (
    <LakeModal visible={visible} maxWidth={600} onPressClose={closeModal}>
      <View style={styles.base}>
        <Text style={styles.close} onPress={closeModal}>
          <CloseIcon />
        </Text>

        <Space height={40} />
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.text}>{description}</Text>
        <Space height={32} />

        <Box alignItems="center">
          <Button mode="secondary" custom={styles.cta} onPress={handleCTA}>
            {t("common.imInterested")}
          </Button>

          <Button mode="tertiary" size="small" custom={styles.cta2} onPress={closeModal}>
            {t("common.notNow")}
          </Button>
        </Box>

        <AutoWidthImage sourceUri={background} height={150} style={styles.background} />
      </View>
    </LakeModal>
  );
};
