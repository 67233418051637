import { Box } from "@swan-io/lake/src/components/Box";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { Space } from "@swan-io/lake/src/components/Space";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { useEffect, useState } from "react";
import { StyleSheet } from "react-native";
import { combineValidators, useForm } from "react-ux-form";
import { match } from "ts-pattern";
import { Button } from "../../../components/forms/Button";
import { SalesInvoiceRelayQuery, SendSalesInvoiceMailDocument } from "../../../graphql/crm";
import { t } from "../../../utils/i18n";
import { sendForm } from "../../../utils/types";
import { handlerErrors, validateEmail, validateRequired } from "../../../utils/validations";

import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { decode } from "html-entities";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useLoading } from "../../context/LoadingContext";

type Props = {
  invoice: SalesInvoiceRelayQuery | undefined;
  visible: boolean;
  onPressClose?: () => void;
};

const styles = StyleSheet.create({
  container: {
    padding: 8,
  },
});

export const SendModal = ({ invoice, visible, onPressClose }: Props) => {
  const invoiceId = invoice?.id ?? "0";
  const { setLoading } = useLoading();
  const [, sendInvoice] = useUrqlMutation(SendSalesInvoiceMailDocument);
  const { Field, setFieldValue, submitForm, resetForm } = useForm<sendForm>({
    email: {
      initialValue: "",
      validate: combineValidators(validateRequired, validateEmail),
    },
    id: { initialValue: invoiceId as string, validate: validateRequired },
    subject: { initialValue: "" },
    body: { initialValue: "" },
  });

  const statusText = match(invoice?.status)
    .with("DRAFT", () => t("invoice.send.status.draft"))
    .with("PENDING", () => t("invoice.send.status.pending"))
    .with("OVERDUED", () => t("invoice.send.status.overdued"))
    .with("DEFAULTED", () => t("invoice.send.status.defaulted"))
    .with("CHARGED", () => t("invoice.send.status.charged"))
    .otherwise(() => t("invoice.send.status.default"));

  useEffect(() => {
    const subject = match(invoice?.status)
      .with("PROFORMA", () =>
        t("invoice.send.proformaSubject", {
          issuer: invoice?.issuer.name ?? "",
        }),
      )
      .with("QUOTE", () =>
        t("invoice.send.quoteSubject", {
          issuer: invoice?.issuer.name ?? "",
          serialNumber: invoice?.seriesCurrent ?? "",
        }),
      )
      .with("DELIVERY", () =>
        t("invoice.send.deliverySubject", {
          issuer: invoice?.issuer.name ?? "",
          serialNumber: invoice?.seriesCurrent ?? "",
        }),
      )
      .otherwise(() =>
        t("invoice.send.defaultSubject", {
          issuer: invoice?.issuer.name ?? "",
          serialNumber: invoice?.seriesCurrent ?? "",
        }),
      );

    const body = match(invoice?.status)
      .with("PROFORMA", () =>
        decode(
          t("invoice.send.proformaBody", {
            customerName: invoice?.customer?.name ?? "",
            total: String(invoice?.total) ?? "0",
          }),
        ),
      )
      .with("QUOTE", () =>
        decode(
          t("invoice.send.quoteBody", {
            customerName: invoice?.customer?.name ?? "",
            serialNumber: invoice?.seriesCurrent ?? "",
            total: String(invoice?.total) ?? "0",
          }),
        ),
      )
      .with("DELIVERY", () =>
        decode(
          t("invoice.send.deliveryBody", {
            customerName: invoice?.customer?.name ?? "",
            serialNumber: invoice?.seriesCurrent ?? "",
            total: String(invoice?.total) ?? "0",
          }),
        ),
      )
      .otherwise(() =>
        decode(
          t("invoice.send.defaultBody", {
            customerName: invoice?.customer?.name ?? "",
            serialNumber: invoice?.seriesCurrent ?? "",
            state: statusText,
            total: String(invoice?.total) ?? "0",
          }),
        ),
      );

    setFieldValue("email", invoice?.customer?.email ?? "");
    setFieldValue("subject", subject);
    setEditorState(initialState(body));
  }, [invoice]);

  const handleSubmit = () => {
    setLoading(true);
    submitForm(values => {
      sendInvoice({
        input: {
          email: values.email as string,
          subject: values.subject as string,
          body: draftToHtml(convertToRaw(editorState.getCurrentContent())),
          invoiceId: invoiceId as string,
        },
      })
        .mapOk(data => {
          match(data.sendSalesInvoiceMail)
            .with({ __typename: "OperationInfo" }, handlerErrors)
            .otherwise(() => {
              showToast({ variant: "success", title: t("invoice.send.success"), autoClose: true });
              resetForm();
              onPressClose?.();
            });
          setLoading(false);
        })
        .mapError(() => {
          setLoading(false);
          showToast({ variant: "error", title: t("invoice.send.error"), autoClose: true });
        });
    });
  };

  const initialState = (body?: string) => {
    const text = htmlToDraft(decode(body));
    const contentState = ContentState.createFromBlockArray(text.contentBlocks);
    return EditorState.createWithContent(contentState);
  };

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  return (
    <LakeModal
      visible={visible}
      title={t("invoices.send.form")}
      maxWidth={740}
      onPressClose={onPressClose}
    >
      <ResponsiveContainer breakpoint={breakpoints.tiny}>
        {({ large }) => (
          <Box
            style={large && styles.container}
            onTouchEnd={e => {
              e.stopPropagation();
            }}
          >
            <Field name="email">
              {({ value, onChange }) => (
                <LakeLabel
                  label={t("common.email")}
                  render={() => (
                    <LakeTextInput
                      value={value}
                      placeholder={t("common.email.help")}
                      onChangeText={onChange}
                    />
                  )}
                />
              )}
            </Field>

            <Field name="subject">
              {({ value, onChange }) => (
                <LakeLabel
                  label={t("common.subject")}
                  render={() => <LakeTextInput value={value} onChangeText={onChange} />}
                />
              )}
            </Field>

            <Field name="body">
              {() => (
                <LakeLabel
                  label={t("invoice.send.bodyLabel")}
                  render={() => (
                    <Editor
                      editorState={editorState}
                      onEditorStateChange={setEditorState}
                      editorClassName="editorClassName"
                    />
                  )}
                />
              )}
            </Field>

            <Space height={32} />
            <Button onPress={handleSubmit}>{t("common.send")}</Button>
          </Box>
        )}
      </ResponsiveContainer>
    </LakeModal>
  );
};
