import { Box } from "@swan-io/lake/src/components/Box";
import { Link } from "@swan-io/lake/src/components/Link";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { useResponsive } from "@swan-io/lake/src/hooks/useResponsive";
import { StyleSheet, Text } from "react-native";
import { useUser } from "../features/context/UserContext";
import { backgroundColorVariants, fontColorVariants } from "../styles/constants";
import { Router } from "../utils/routes";

const styles = StyleSheet.create({
  contain: {
    position: "absolute",
    left: 16,
    bottom: 16,
    width: "100%",
  },
  containMobile: {
    left: 9,
  },
  avatar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 14,
    fontWeight: "bold",
    color: fontColorVariants.gray600,
    backgroundColor: backgroundColorVariants.neutral300,
    width: 38,
    height: 38,
    borderRadius: 25,
  },
  row: {
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  text: {
    color: fontColorVariants.gray600,
    fontWeight: "500",
  },
});

type Props = {
  open: boolean;
};

export const UserNavigation = ({ open }: Props) => {
  const { company } = useUser();

  const { desktop } = useResponsive(breakpoints.medium);

  return (
    <Box
      direction="row"
      alignItems="center"
      style={[styles.contain, !desktop && styles.containMobile]}
    >
      <Link to={Router.UserEdit()} style={styles.row}>
        <Box style={styles.avatar}>
          <Text>{company?.name?.split("").at(0)}</Text>
        </Box>

        {open && <Text style={styles.text}>{company?.name}</Text>}
      </Link>
    </Box>
  );
};
