import { Heading } from "@components/Heading";
import { Sidebar } from "@components/Sidebar";
import { Button } from "@components/forms/Button";
import { Box } from "@swan-io/lake/src/components/Box";
import { ErrorBoundary } from "@swan-io/lake/src/components/ErrorBoundary";
import { WithPartnerAccentColor } from "@swan-io/lake/src/components/WithPartnerAccentColor";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { useResponsive } from "@swan-io/lake/src/hooks/useResponsive";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { CONTENT_ID, SkipToContent } from "@swan-io/shared-business/src/components/SkipToContent";
import { useEffect, useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { match } from "ts-pattern";
import { ErrorView } from "../../../components/ErrorView";
import { ProjectInfo, ProjectsDocument } from "../../../graphql/crm";
import { t } from "../../../utils/i18n";
import { Router } from "../../../utils/routes";
import { isUnauthorizedError } from "../../../utils/urql";
import { extractQueryData } from "../../../utils/validations";
import { COLORS } from "../../../values/colors";
import { ProjectCreate } from "../components/ProjectCreate";
import { ProjectList } from "../components/ProjectList";

const styles = StyleSheet.create({
  background: {
    flexShrink: 1,
    flexGrow: 1,
    backgroundColor: COLORS.BACKGROUND,
  },
  container: {
    flexShrink: 1,
    flexGrow: 1,
  },
  content: {
    ...commonStyles.fill,
  },
  desktopContainer: {
    flexDirection: "row",
    width: "100%",
    marginHorizontal: "auto",
  },
  mobileContentContainer: {
    minHeight: "100%",
    paddingBottom: 24,
  },
  desktopContentContainer: {
    ...commonStyles.fill,
  },
  header: {
    paddingHorizontal: 24,
    borderBottomWidth: 1,
    borderBottomColor: COLORS.WHITE,
  },
});

export const Projects = () => {
  const { desktop } = useResponsive();
  const route = Router.useRoute(["ProjectsRoot"]);

  const { data } = useUrqlQuery({ query: ProjectsDocument });
  const [projects, setProjects] = useState<ProjectInfo[]>([]);
  const params = match(route)
    .with({ name: "ProjectsRoot" }, ({ params }) => params)
    .otherwise(() => {});

  useEffect(() => {
    if (data?.tag !== "Loading") {
      setProjects(extractQueryData(data, "value.value.projects") as ProjectInfo[]);
    }
  }, [data]);

  const onClose = () => {
    Router.push("ProjectsRoot", {});
  };

  return (
    <WithPartnerAccentColor color="#498AF2">
      <SkipToContent />

      <View style={styles.background}>
        <View style={[styles.container, desktop && styles.desktopContainer]}>
          {desktop && <Sidebar />}

          <ScrollView
            contentContainerStyle={
              desktop ? styles.desktopContentContainer : styles.mobileContentContainer
            }
          >
            <View style={styles.content} id={CONTENT_ID} tabIndex={0}>
              <ErrorBoundary
                key={route?.name}
                fallback={({ error }) =>
                  isUnauthorizedError(error) ? <></> : <ErrorView error={error} />
                }
              >
                <Box
                  direction="row"
                  alignItems="center"
                  justifyContent="spaceBetween"
                  style={styles.header}
                >
                  <Heading title={t("projects")} />

                  <Button
                    style="group"
                    reverse={true}
                    onPress={() => {
                      Router.push("ProjectsRoot", { create: "" });
                    }}
                  >
                    {t("project.new")}
                  </Button>
                </Box>

                {projects.length > 0 && <ProjectList projects={projects} />}
              </ErrorBoundary>
            </View>
          </ScrollView>
        </View>

        <ProjectCreate visible={params?.create !== undefined} onPressClose={onClose} />
      </View>
    </WithPartnerAccentColor>
  );
};
