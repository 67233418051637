import { PlainListView } from "@components/PlainListView";
import { Button } from "@components/forms/Button";
import { DefaultCell } from "@components/forms/DefaultCell";
import { AutoWidthImage } from "@swan-io/lake/src/components/AutoWidthImage";
import { Box } from "@swan-io/lake/src/components/Box";
import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { Space } from "@swan-io/lake/src/components/Space";
import { StyleSheet, Text, View, ViewStyle } from "react-native";
import analysisImage from "../../../assets/img/analysisImage.webp";
import { ProfitAndLossOutput } from "../../../graphql/crm";
import { common } from "../../../styles/common";
import {
  backgroundColorVariants,
  borderColorVariants,
  fontColorVariants,
} from "../../../styles/constants";
import { formatCurrency, t } from "../../../utils/i18n";
import { ColumnHeader } from "../../invoices/components/ColumnHeader";

const styles = StyleSheet.create({
  container: {
    position: "relative",
    overflow: "hidden",
    backgroundColor: backgroundColorVariants.white,
    padding: 24,
  },
  gradient1: {
    position: "absolute",
    top: "0",
    left: -100,
    transform: "translate(0, 0)",
    width: "615px",
    height: "615px",
    backgroundImage:
      "radial-gradient(33.64% 25.02% at 50% 50%, rgba(89, 226, 255, 0.22) 0%, rgba(108, 168, 243, 0.00) 100%)",
  },
  gradient2: {
    position: "absolute",
    top: "50%",
    right: 200,
    transform: "translate(50%, -50%)",
    width: "685px",
    height: "685px",
    backgroundImage:
      "radial-gradient(67.22% 50% at 50% 50%, rgba(255, 100, 51, 0.15) 0%, rgba(245, 228, 255, 0.00) 100%)",
  },
  contain: {
    width: "100%",
    borderRadius: 24,
    borderWidth: 2,
    borderColor: borderColorVariants.white,
    backgroundImage:
      "radial-gradient(80.39% 60.44% at 56.91% 43.17%, rgba(255, 255, 255, 0.75) 0%, rgba(242, 242, 242, 0.75) 100%)",
    boxShadow:
      "0px 67.609px 54.087px 0px rgba(201, 201, 201, 0.07), 0px 28.246px 22.596px 0px rgba(187, 185, 190, 0.05), 0px 15.101px 12.081px 0px rgba(180, 177, 187, 0.04), 0px 8.466px 6.773px 0px rgba(195, 194, 198, 0.04), 0px 4.496px 3.597px 0px rgba(190, 189, 193, 0.03), 0px 1.871px 1.497px 0px rgba(192, 190, 195, 0.02)",
    paddingVertical: 20,
    paddingHorizontal: 40,
  },
  table: {
    borderRadius: 16,
    boxShadow: "0px 0px 12px rgba(163, 163, 163, 0.25)",
    overflow: "hidden",
  },
  header: {
    color: fontColorVariants.white,
    textTransform: "uppercase",
    borderTopStartRadius: 16,
    borderTopEndRadius: 16,
  },
  row: {
    backgroundColor: backgroundColorVariants.gray50,
  },
  red: {
    color: fontColorVariants.destructive500,
    fontWeight: "700",
  },
  green: {
    color: fontColorVariants.success800,
    fontWeight: "700",
  },
  line: {
    width: 16,
    height: "100%",
    backgroundColor: backgroundColorVariants.gray400,
  },
  blocks: {
    justifyContent: "space-between",
    gap: 64,
    borderBottomWidth: 4,
    borderBottomColor: borderColorVariants.white,
    marginBottom: 20,
  },
  result: {
    gap: 16,
    paddingVertical: 20,
    flex: 1,
  },
  title: {
    fontSize: 16,
    fontWeight: "600",
    color: fontColorVariants.gray500,
    marginBottom: 4,
  },
  textResult: {
    fontSize: 22,
    fontWeight: "800",
    color: fontColorVariants.gray700,
  },
  advise: {
    flex: 1,
    gap: 16,
  },
  adviseContain: {
    maxWidth: "50%",
  },
  adviseText: {
    fontSize: 16,
    fontWeight: "600",
    color: fontColorVariants.gray600,
  },
});

type Props = {
  summary: ProfitAndLossOutput;
};

export const ProfitAndLossAnalysis = ({ summary }: Props) => {
  const { analysis } = summary;

  const data = [
    {
      name: t("reports.ingresosExplotacion"),
      amount: analysis?.ingresosExplotacion?.resultAmount,
      percent: analysis?.ingresosExplotacion?.resultPercent,
      sector: analysis?.ingresosExplotacion?.sectorPercent,
    },
    {
      name: t("reports.aprovisionamientos"),
      amount: analysis?.aprovisionamientos?.resultAmount,
      percent: analysis?.aprovisionamientos?.resultPercent,
      sector: analysis?.aprovisionamientos?.sectorPercent,
      diff_amount: analysis?.aprovisionamientos?.diffAmount,
      diff_percent: analysis?.aprovisionamientos?.diffPercent,
    },
    {
      name: t("reports.variacionExistencias"),
      amount: analysis?.variacionExistencias?.resultAmount,
      percent: analysis?.variacionExistencias?.resultPercent,
      sector: analysis?.variacionExistencias?.sectorPercent,
      diff_amount: analysis?.variacionExistencias?.diffAmount,
      diff_percent: analysis?.variacionExistencias?.diffPercent,
    },
    {
      name: t("reports.gastosPersonal"),
      amount: analysis?.gastosPersonal?.resultAmount,
      percent: analysis?.gastosPersonal?.resultPercent,
      sector: analysis?.gastosPersonal?.sectorPercent,
      diff_amount: analysis?.gastosPersonal?.diffAmount,
      diff_percent: analysis?.gastosPersonal?.diffPercent,
    },
    {
      name: t("reports.otrosGastosExplotacion"),
      amount: analysis?.otrosGastosExplotacion?.resultAmount,
      percent: analysis?.otrosGastosExplotacion?.resultPercent,
      sector: analysis?.otrosGastosExplotacion?.sectorPercent,
      diff_amount: analysis?.otrosGastosExplotacion?.diffAmount,
      diff_percent: analysis?.otrosGastosExplotacion?.diffPercent,
    },
    {
      name: t("reports.amortizacionInmovilizado"),
      amount: analysis?.amortizacionInmovilizado?.resultAmount,
      percent: analysis?.amortizacionInmovilizado?.resultPercent,
      sector: analysis?.amortizacionInmovilizado?.sectorPercent,
      diff_amount: analysis?.amortizacionInmovilizado?.diffAmount,
      diff_percent: analysis?.amortizacionInmovilizado?.diffPercent,
    },
    {
      name: t("reports.resultadoFinanciero"),
      amount: analysis?.resultadoFinanciero?.resultAmount,
      percent: analysis?.resultadoFinanciero?.resultPercent,
      sector: analysis?.resultadoFinanciero?.sectorPercent,
      diff_amount: analysis?.resultadoFinanciero?.diffAmount,
      diff_percent: analysis?.resultadoFinanciero?.diffPercent,
    },
    {
      name: t("reports.ebitda"),
      amount: analysis?.ebitda?.resultAmount,
      percent: analysis?.ebitda?.resultPercent,
      sector: analysis?.ebitda?.sectorPercent,
      diff_amount: analysis?.ebitda?.diffAmount,
      diff_percent: analysis?.ebitda?.diffPercent,
    },
  ];

  const analysisResult = Number(analysis?.ebitda.diffAmount) || 0;

  return (
    <ResponsiveContainer>
      {({ small }) => (
        <Box direction="row" style={styles.container}>
          <Box style={styles.gradient1} />
          <Box style={styles.gradient2} />

          <Box style={styles.contain}>
            <Box direction="row" style={styles.blocks}>
              <Box direction="row" style={styles.result}>
                <Box style={styles.line} />

                <Box style={common.w100}>
                  <Text style={styles.title}>{t("reports.analysisTitle")}</Text>

                  <Text style={styles.textResult}>
                    {analysisResult <= 0 && t("reports.analysisOK")}

                    {analysisResult > 0 &&
                      t("reports.analysisKO", { amount: formatCurrency(analysisResult, "EUR") })}
                  </Text>
                </Box>
              </Box>

              <Box
                direction="row"
                alignItems="center"
                justifyContent="end"
                style={[styles.advise, small && common.hidden]}
              >
                <AutoWidthImage sourceUri={analysisImage} height={142} />

                <View style={styles.adviseContain}>
                  <Text style={styles.adviseText}>{t("reports.adviseText")}</Text>
                  <Space height={12} />
                  <Button style="group">{t("reports.adviseButton")}</Button>
                </View>
              </Box>
            </Box>

            <Box style={styles.table}>
              <PlainListView
                withoutScroll={true}
                data={data}
                keyExtractor={item => item.name}
                headerHeight={40}
                rowHeight={40}
                headerStyle={styles.header}
                headerBackgroundColor="#8F8E91"
                groupHeaderHeight={40}
                extraInfo={undefined}
                rowStyle={() => styles.row}
                columns={[
                  {
                    id: "name",
                    title: t("reports.concepts"),
                    width: "grow",
                    renderTitle: ({ title }) => <ColumnHeader text={title} style={styles.header} />,
                    renderCell: ({ item }) => <DefaultCell data={item.name} />,
                  },
                  {
                    id: "amount",
                    title: t("onboarding.company"),
                    width: 200,
                    renderTitle: ({ title }) => (
                      <ColumnHeader text={title} align="center" style={styles.header} />
                    ),
                    renderCell: ({ item }) => (
                      <>
                        <DefaultCell
                          data={formatCurrency(item.amount as number, "EUR")}
                          align="right"
                          style={common.weight600 as ViewStyle}
                        />

                        <DefaultCell data={`${item.percent as string}%`} align="right" />
                      </>
                    ),
                  },
                  {
                    id: "sector",
                    title: t("reports.sector"),
                    width: 120,
                    renderTitle: ({ title }) => (
                      <ColumnHeader text={title} align="center" style={styles.header} />
                    ),
                    renderCell: ({ item }) => (
                      <DefaultCell data={`${item.sector as string}%`} align="center" />
                    ),
                  },
                  {
                    id: "diff_percent",
                    title: t("reports.differenceWithTheSector"),
                    width: 200,
                    renderTitle: ({ title }) => (
                      <ColumnHeader text={title} align="center" style={styles.header} />
                    ),
                    renderCell: ({ item }) =>
                      item.diff_percent != null ? (
                        <>
                          <DefaultCell
                            data={formatCurrency(item.diff_amount as number, "EUR")}
                            align="right"
                            style={
                              (Number(item.diff_amount) < 0
                                ? styles.green
                                : styles.red) as ViewStyle
                            }
                          />

                          <DefaultCell
                            data={`${item.diff_percent as string}%`}
                            align="right"
                            style={
                              (Number(item.diff_percent) < 0
                                ? styles.green
                                : styles.red) as ViewStyle
                            }
                          />
                        </>
                      ) : (
                        <>
                          <DefaultCell data={"-"} align="center" />
                          <DefaultCell data={"-"} align="center" />
                        </>
                      ),
                  },
                ]}
              />
            </Box>
          </Box>
        </Box>
      )}
    </ResponsiveContainer>
  );
};
