import { MainSidebar } from "@components/MainSidebar";
import { SubscriptionModal } from "@components/SubscriptionModal";
import { Unpaid } from "@components/Unpaid";
import { Box } from "@swan-io/lake/src/components/Box";
import { ErrorBoundary } from "@swan-io/lake/src/components/ErrorBoundary";
import { LoadingView } from "@swan-io/lake/src/components/LoadingView";
import { ToastStack } from "@swan-io/lake/src/components/ToastStack";
import { colors } from "@swan-io/lake/src/constants/design";
import { isNotNullishOrEmpty } from "@swan-io/lake/src/utils/nullish";
import { Suspense } from "react";
import { StyleSheet } from "react-native";
import { P, match } from "ts-pattern";
import { Provider } from "urql";
import { CrmArea } from "./components/CrmArea";
import { ErrorView } from "./components/ErrorView";
import { ProjectRootRedirect } from "./components/ProjectRootRedirect";
import { Loader } from "./features/components/Loader";
import { LoadingProvider } from "./features/context/LoadingContext";
import { UserProvider } from "./features/context/UserContext";
import { Area as HrArea } from "./features/hr/screens/Area";
import { EmployeesArea } from "./features/hr/screens/Employees";
import { Payroll } from "./features/hr/screens/Payroll";
import { Payrolls } from "./features/hr/screens/Payrolls";
import { ProductsArea } from "./features/invoices/components/ProductsArea";
import { PurchaseArea } from "./features/invoices/components/PurchaseArea";
import { PurchaseCreate } from "./features/invoices/components/PurchaseCreate";
import { RectificationCreate } from "./features/invoices/components/RectificationCreate";
import { SalesArea } from "./features/invoices/components/SalesArea";
import { SalesCreate } from "./features/invoices/components/SalesCreate";
import { TicketCreate } from "./features/invoices/components/TicketCreate";
import { ProformaCreate } from "./features/invoices/components/proforma/ProformaCreate";
import { Area as InvoicesArea } from "./features/invoices/screens/Area";
import { Area as TicketsArea } from "./features/invoices/screens/Tickets";
import { Projects } from "./features/projects/screens/Projects";
import { Area as ReportsArea } from "./features/reports/screens/Area";
import { ProfitAndLossArea } from "./features/reports/screens/ProfitAndLoss";
import { ActivatePage } from "./pages/ActivatePage";
import { Company } from "./pages/CompanyPage";
import { DesignSystemPage } from "./pages/DesignSystemPage";
import { NotFoundPage } from "./pages/NotFoundPage";
import { OnboardingPage } from "./pages/OnboardingPage";
import { PlansPage } from "./pages/PlansPage";
import { SigninPage } from "./pages/SigninPage";
import { SignupPage } from "./pages/SignupPage";
import { Summary } from "./pages/Summary";
import { User } from "./pages/UserPage";
import { common } from "./styles/common";
import { Router } from "./utils/routes";
import { crmClient, isUnauthorizedError } from "./utils/urql";

const styles = StyleSheet.create({
  base: {
    backgroundColor: colors.gray[50],
    flexGrow: 1,
  },
});

export const App = () => {
  const route = Router.useRoute([
    "DesignSystemPage",
    "SigninPage",
    "SignupPage",
    "ActivateRoot",
    "ActivateEmail",
    "PlansPage",
    "OnboardingPage",
    "ProjectRootRedirect",
    "SummaryArea",
    "UserArea",
    "CompanyArea",
    "CrmArea",
    "InvoicesArea",
    "InvoicesSalesArea",
    "InvoicesSalesCreate",
    "InvoicesSalesProformaCreate",
    "InvoicesSalesProformaDetail",
    "InvoicesSalesQuoteCreate",
    "InvoicesSalesQuoteDetail",
    "InvoicesSalesDeliveryCreate",
    "InvoicesSalesDeliveryDetail",
    "InvoicesSalesRectify",
    "InvoicesSalesRectifyDetail",
    "InvoicesPurchaseArea",
    "InvoicesPurchaseCreate",
    "InvoicesProductsArea",
    "InvoicesTicketCreate",
    "InvoicesTicketList",
    "ReportsArea",
    "ReportsProfitAndLossArea",
    "HrArea",
    "HrEmployeesArea",
    "HrPayrollsList",
    "HrPayrollsCreate",
    "HrPayrollsUpdate",
    "ProjectsRoot",
  ]);

  return (
    <LoadingProvider>
      <ErrorBoundary
        fallback={({ error }) =>
          isUnauthorizedError(error) ? <></> : <ErrorView error={error} style={styles.base} />
        }
      >
        <Loader />

        <Provider value={crmClient}>
          <Suspense fallback={<LoadingView color={colors.gray[400]} style={styles.base} />}>
            <UserProvider>
              <Unpaid />

              <Box direction="row" style={common.h100}>
                <MainSidebar />

                {match(route)
                  .with({ name: "DesignSystemPage" }, () => <DesignSystemPage />)
                  .with({ name: "SigninPage" }, ({ params: { sessionExpired } }) => (
                    <SigninPage sessionExpired={isNotNullishOrEmpty(sessionExpired)} />
                  ))
                  .with({ name: "SignupPage" }, () => <SignupPage />)
                  .with({ name: "ActivateRoot" }, () => <ActivatePage />)
                  .with({ name: "ActivateEmail" }, ({ params: { uid, token } }) => (
                    <ActivatePage uid={uid} token={token} />
                  ))
                  .with({ name: "PlansPage" }, () => <PlansPage />)
                  .with({ name: "OnboardingPage" }, () => <OnboardingPage />)
                  .with({ name: "CrmArea" }, { name: "ProjectRootRedirect" }, route =>
                    match(route)
                      .with({ name: "CrmArea" }, () => <CrmArea />)
                      .with({ name: "ProjectRootRedirect" }, () => <ProjectRootRedirect />)
                      .exhaustive(),
                  )
                  .with({ name: "SummaryArea" }, () => <Summary />)
                  .with({ name: "UserArea" }, () => <User />)
                  .with({ name: "CompanyArea" }, () => <Company />)
                  .with({ name: "InvoicesArea" }, () => <InvoicesArea />)
                  .with({ name: "InvoicesSalesArea" }, () => <SalesArea />)
                  .with({ name: "InvoicesSalesCreate" }, () => (
                    <SalesCreate
                      visible={true}
                      onRefreshRequest={() => null}
                      setInvoice={() => null}
                    />
                  ))
                  .with({ name: "InvoicesSalesProformaCreate" }, () => (
                    <ProformaCreate onRefreshRequest={() => null} />
                  ))
                  .with({ name: "InvoicesSalesProformaDetail" }, () => (
                    <ProformaCreate onRefreshRequest={() => null} />
                  ))
                  .with({ name: "InvoicesSalesQuoteCreate" }, () => (
                    <ProformaCreate onRefreshRequest={() => null} />
                  ))
                  .with({ name: "InvoicesSalesQuoteDetail" }, () => (
                    <ProformaCreate onRefreshRequest={() => null} />
                  ))
                  .with({ name: "InvoicesSalesDeliveryCreate" }, () => (
                    <ProformaCreate onRefreshRequest={() => null} />
                  ))
                  .with({ name: "InvoicesSalesDeliveryDetail" }, () => (
                    <ProformaCreate onRefreshRequest={() => null} />
                  ))
                  .with({ name: "InvoicesSalesRectify" }, () => (
                    <RectificationCreate visible={true} onRefreshRequest={() => null} />
                  ))
                  .with({ name: "InvoicesSalesRectifyDetail" }, () => (
                    <RectificationCreate visible={true} onRefreshRequest={() => null} />
                  ))
                  .with({ name: "InvoicesPurchaseArea" }, () => <PurchaseArea />)
                  .with({ name: "InvoicesPurchaseCreate" }, () => (
                    <PurchaseCreate visible={true} onRefreshRequest={() => null} />
                  ))
                  .with({ name: "InvoicesTicketCreate" }, () => (
                    <TicketCreate visible={true} onRefreshRequest={() => null} />
                  ))
                  .with({ name: "InvoicesTicketList" }, () => <TicketsArea />)
                  .with({ name: "InvoicesProductsArea" }, () => <ProductsArea />)
                  .with({ name: "ReportsArea" }, () => <ReportsArea />)
                  .with({ name: "ReportsProfitAndLossArea" }, () => <ProfitAndLossArea />)
                  .with({ name: "HrArea" }, () => <HrArea />)
                  .with({ name: "HrEmployeesArea" }, () => <EmployeesArea />)
                  .with({ name: "HrPayrollsList" }, () => <Payrolls />)
                  .with({ name: "HrPayrollsCreate" }, ({ params: { employeeId } }) => (
                    <Payroll employeeId={employeeId} />
                  ))
                  .with({ name: "HrPayrollsUpdate" }, ({ params: { payrollId } }) => (
                    <Payroll payrollId={payrollId} />
                  ))
                  .with({ name: "ProjectsRoot" }, () => <Projects />)
                  .with(P.nullish, () => <NotFoundPage style={styles.base} />)
                  .exhaustive()}
              </Box>
            </UserProvider>
          </Suspense>
        </Provider>

        <ToastStack />
        <SubscriptionModal />
      </ErrorBoundary>
    </LoadingProvider>
  );
};
