import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { Employee } from "../../../graphql/crm";
import { t } from "../../../utils/i18n";
import { ProjectForm } from "./ProjectForm";

type Props = {
  visible?: boolean;
  data?: Employee;
  onPressClose?: () => void;
};

export const ProjectCreate = ({ visible = false, data, onPressClose }: Props) => {
  return (
    <LakeModal
      visible={visible}
      title={data?.id !== undefined ? t("hr.editEmployee") : t("hr.newEmployee")}
      onPressClose={onPressClose}
      maxWidth={660}
    >
      <ProjectForm onPressClose={onPressClose} showCancel={true} />
    </LakeModal>
  );
};
