import { Box } from "@swan-io/lake/src/components/Box";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { ListRightPanelContent } from "@swan-io/lake/src/components/ListRightPanel";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { colors } from "@swan-io/lake/src/constants/design";
import { ScrollView, StyleSheet, View } from "react-native";
import { EmployeeRelay } from "../../../graphql/crm";
import { NestedFormProvider } from "../../components/NestedForm";
import { EmployeeForm } from "./EmployeeForm";

type Props = {
  data: EmployeeRelay;
  large: boolean;
  onClose?: () => void;
};

const styles = StyleSheet.create({
  scrollContainerMobile: {
    // used only for sticky tabs
    minHeight: "100%",
  },
  scrollContainerDesktop: {
    ...commonStyles.fill,
  },
  container: {
    ...commonStyles.fill,
  },
  tile: {
    alignItems: "center",
  },
  wrapText: {
    wordBreak: "break-all",
  },
});

export const EmployeeDetail = ({ data, large, onClose }: Props) => {
  return (
    <ScrollView
      contentContainerStyle={large ? styles.scrollContainerDesktop : styles.scrollContainerMobile}
    >
      <View style={styles.container}>
        <NestedFormProvider>
          <ListRightPanelContent large={large}>
            <Tile style={styles.tile}>
              <Box alignItems="center">
                <Space height={12} />

                <LakeHeading
                  level={1}
                  variant={large ? "h1" : "h3"}
                  align="center"
                  style={styles.wrapText}
                >
                  {data.name}
                </LakeHeading>

                <Space height={8} />

                <LakeHeading level={3} variant="h4" align="center" color={colors.gray[700]}>
                  {data.nif}
                </LakeHeading>
              </Box>
            </Tile>
          </ListRightPanelContent>

          <Space height={24} />

          <ListRightPanelContent large={large}>
            <EmployeeForm
              data={data}
              onPressClose={() => {
                onClose?.();
              }}
              showCancel={true}
            />
          </ListRightPanelContent>
        </NestedFormProvider>
      </View>
    </ScrollView>
  );
};
