import { Icon } from "@swan-io/lake/src/components/Icon";
import { create } from "zustand";
import { OperationInfo, SubscriptionInfo } from "../graphql/crm";
import { t } from "./i18n";
import { BoolRules } from "./types";

export const CheckBoolRuleLock = (subscription: SubscriptionInfo | null, rule: keyof BoolRules) => {
  return !checkRule(subscription, rule) && <LockIcon />;
};

export const LockIcon = () => {
  return <Icon name="lock-closed-regular" size={16} />;
};

type Store = {
  title: string;
  description: string;
  visible: boolean;
  showUpgrade: ({ title, description }: { title?: string; description?: string }) => void;
  closeModal: () => void;
};

export const useSubscription = create<Store>()(set => ({
  title: "",
  description: "",
  visible: false,
  showUpgrade: ({ title, description }) =>
    set({
      title: title ?? t("plan.upgradeTitle"),
      description: description ?? t("plan.upgradeDescription"),
      visible: true,
    }),
  closeModal: () => set({ visible: false }),
}));

export const subscriptionError = (data: OperationInfo) => {
  let errorMessage = "";
  for (const error of data.messages) {
    errorMessage += `${error.message}\n`;
  }
  return errorMessage;
};

type Rules = {
  bool_rules: BoolRules;
};

export const checkRule = (
  subscription: SubscriptionInfo | null,
  rule: keyof BoolRules,
): boolean => {
  const rules = subscription?.plan?.rules as Rules;
  return rules === undefined ? false : rules.bool_rules[rule] !== false;
};
