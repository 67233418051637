import "@swan-io/lake/src/assets/fonts/Inter.css";
import "@swan-io/lake/src/assets/main.css";
import "./main.css";

import { ResizeObserver } from "@juggle/resize-observer";
import { isNullish } from "@swan-io/lake/src/utils/nullish";
import { AppRegistry } from "react-native";
import { App } from "./App";

if (isNullish(window.ResizeObserver)) {
  window.ResizeObserver = ResizeObserver;
}
const rootTag = document.getElementById("app-root");

AppRegistry.registerComponent("App", () => App);
AppRegistry.runApplication("App", { rootTag });
