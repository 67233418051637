import { Box } from "@swan-io/lake/src/components/Box";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { Space } from "@swan-io/lake/src/components/Space";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { useEffect, useState } from "react";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import { match } from "ts-pattern";
import { useUser } from "../features/context/UserContext";
import {
  CreateCustomerPortalDocument,
  GetNextInvoiceDocument,
  Plan,
  PlansDocument,
} from "../graphql/crm";
import { fontColorVariants } from "../styles/constants";
import { formatCurrency, t } from "../utils/i18n";
import { COLORS } from "../values/colors";
import { PlanCard } from "./PlanCard";

const styles = StyleSheet.create({
  right: {
    padding: 45,
  },
  container: {
    alignItems: "center",
    gap: 24,
    paddingBottom: 32,
  },
  title: {
    fontSize: 23,
    fontWeight: "800",
  },
  plans: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: 20,
  },
  currentPlan: {
    backgroundColor: COLORS.WHITE,
    borderRadius: 8,
  },
  manageContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  infoContainer: {
    width: "100%",
  },
  infoInnerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  infoTitle: {
    paddingBottom: 16,
    paddingTop: 16,
  },
  titleText: {
    fontSize: 20,
    fontWeight: "bold",
  },
  infoText: {
    fontSize: 15,
    color: fontColorVariants.gray500,
    paddingBottom: 4,
    paddingTop: 4,
  },
  infoPrice: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  invoice: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 16,
  },
  invoiceText: {
    textDecorationLine: "underline",
    color: fontColorVariants.primary500,
  },
  nextInvoice: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
});

type NextInvoice = {
  nextPaymentdate: string;
  amount: number;
};

export const UserSubscription = () => {
  const { company, subscription, reload } = useUser();
  const { data } = useUrqlQuery({ query: PlansDocument }, []);
  const [, customerPortal] = useUrqlMutation(CreateCustomerPortalDocument);
  const [, getNextInvoice] = useUrqlMutation(GetNextInvoiceDocument);
  const [nextInvoice, setNextInvoice] = useState<NextInvoice | null>(null);
  const [customerPortalUrl, setCustomerPortalUrl] = useState<string | null>(null);

  useEffect(() => {
    customerPortal({}).mapOk(data => {
      match(data.createCustomerPortal).with(
        { __typename: "CreateCustomerPortalOutput" },
        portal => {
          setCustomerPortalUrl(portal.redirectUrl);
        },
      );
    });
    getNextInvoice({}).mapOk(data => {
      match(data.getNextInvoice).with({ __typename: "NextInvoiceOutput" }, invoice => {
        setNextInvoice({
          nextPaymentdate: (invoice as { nextPaymentDate: string }).nextPaymentDate,
          amount: Number((invoice as { amount: string }).amount),
        });
      });
    });
  }, []);

  let plans = data
    .toOption()
    .flatMap(result => result.toOption())
    .map(result => result.plans)
    .getWithDefault([])
    .filter(row => row.constitution === company?.constitution);

  if (subscription?.plan?.code !== undefined && subscription?.plan?.code !== "trial") {
    plans = plans.filter(row => row.code !== "trial");
  }

  return (
    <>
      <ScrollView centerContent={true} style={styles.right}>
        <Box direction="column" style={styles.container}>
          <View style={styles.manageContainer}>
            <LakeHeading level={1} style={styles.title}>
              {t("plan.manageTitle")}
            </LakeHeading>

            <View style={styles.infoContainer}>
              <View style={styles.infoInnerContainer}>
                <View style={styles.infoTitle}>
                  <Text style={styles.titleText}>
                    {t("plan.have")} {subscription?.plan?.title}
                  </Text>
                </View>

                <View style={styles.infoPrice}>
                  <View style={styles.nextInvoice}>
                    <Text style={styles.infoText}>{t("plan.nextInvoice")}</Text>

                    <Text style={styles.infoText}>
                      {formatCurrency(nextInvoice?.amount as number, "EUR")} -{" "}
                      {nextInvoice?.nextPaymentdate}
                    </Text>
                  </View>

                  <View style={styles.invoice}>
                    <Text style={styles.invoiceText} href={customerPortalUrl as string}>
                      {t("plan.listInvoices")}
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            <Space height={8} />
          </View>

          <Box style={styles.plans}>
            {plans.map(plan => (
              <View style={subscription?.plan?.id !== undefined && styles.currentPlan}>
                <PlanCard
                  key={plan.id}
                  plan={plan as Plan}
                  currentPlan={subscription?.plan?.id === plan.id}
                  cancelUrl="user/subscription"
                  updating={true}
                  isPendingCancel={subscription?.isPendingCancel ?? false}
                  reload={reload}
                />
              </View>
            ))}
          </Box>
        </Box>
      </ScrollView>
    </>
  );
};
