import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { showToast } from "@swan-io/lake/src/state/toasts";
import {
  RectifiedInvoiceDocument,
  RectifySalesInvoiceDocument,
  SalesRectificationInvoiceInitialData,
} from "../../../graphql/crm";
import { Router } from "../../../utils/routes";
import { extractQueryData } from "../../../utils/validations";

type Params = {
  invoiceId?: string;
  rectifiedId?: string;
};

const useGetInvoiceData = (params?: Params) => {
  const { data: rectify } = useUrqlQuery({
    query: RectifySalesInvoiceDocument,
    variables: {
      input: {
        id: params?.invoiceId as string,
      },
    },
    pause: params?.invoiceId == null,
  });

  const { data: rectified } = useUrqlQuery({
    query: RectifiedInvoiceDocument as unknown as string,
    variables: {
      id: params?.rectifiedId as string,
    },
    pause: params?.rectifiedId == null,
  });

  if (rectify.isDone()) {
    rectify.mapError(data => {
      showToast({
        variant: "error",
        title: data.message.replace("[GraphQL]", ""),
        autoClose: true,
      });
      Router.push("InvoicesSalesList");
    });
    return extractQueryData(
      rectify,
      "value.value.rectifySalesInvoice",
    ) as SalesRectificationInvoiceInitialData;
  }

  if (rectified.isDone()) {
    rectified.mapError(data => {
      showToast({
        variant: "error",
        title: data.message.replace("[GraphQL]", ""),
        autoClose: true,
      });
      Router.push("InvoicesSalesList");
    });
    return extractQueryData(
      rectified,
      "value.value.rectifiedInvoice",
    ) as SalesRectificationInvoiceInitialData;
  }

  return null;
};

export default useGetInvoiceData;
