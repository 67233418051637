import { DecimalInput } from "@components/DecimalInput";
import { Box } from "@swan-io/lake/src/components/Box";
import { StyleSheet, Text, View } from "react-native";
import { Form as FormType } from "react-ux-form";
import { PayrollState } from "../../types";

const styles = StyleSheet.create({
  row: {
    display: "flex",
    marginTop: 2,
    marginBottom: 2,
    minHeight: 32,
    alignItems: "center",
  },
  input: {
    width: 120,
  },
  pl1: {
    paddingLeft: 16,
  },
});

type PayrollStateNoEarnExtra = Omit<
  PayrollState,
  "earnExtraSupplements" | "earnExtraOtherNonSalaryCompensations" | "earnExtraInKindSalary"
>;

export const RowDecimalInput = ({
  label,
  Field,
  name,
  disabled,
}: {
  label: string;
  Field: FormType<PayrollStateNoEarnExtra>["Field"];
  name: keyof PayrollStateNoEarnExtra;
  disabled?: boolean;
}) => {
  return (
    <Box
      direction="row"
      alignItems="center"
      justifyContent="spaceBetween"
      style={[styles.pl1, styles.row]}
    >
      <Text>{label}</Text>

      <View style={styles.input}>
        <Field name={name}>
          {({ value, onChange, error }) => (
            <DecimalInput
              value={value}
              error={error}
              onChangeDecimal={onChange}
              disabled={disabled}
            />
          )}
        </Field>
      </View>
    </Box>
  );
};
