import { LakeTextInput, LakeTextInputProps } from "@swan-io/lake/src/components/LakeTextInput";
import { useDebounce } from "@swan-io/lake/src/hooks/useDebounce";
import { useEffect, useState } from "react";
import { toDecimalPlaces, toNumber } from "../utils/decimal";

type Props = {
  onChangeDecimal?: (value: string) => void;
};

export const DecimalInput = (props: LakeTextInputProps & Props) => {
  const [currentValue, setCurrentValue] = useState(props.value);

  useEffect(() => {
    setCurrentValue(props.value);
  }, [props.value]);

  const onChange = useDebounce((value: string) => {
    if (!value) {
      props.onChangeDecimal?.("");
      return;
    }
    const numberValue = toNumber(valueCleanup(value));
    const res = toDecimalPlaces(numberValue);
    props.onChangeDecimal?.(res);
  }, 500);

  const valueCleanup = (value: string) => {
    const valueTrimmed = value.trim();
    const valueWithoutMinus = valueTrimmed.replace(/-/g, (match, offset) =>
      offset === 0 ? match : "",
    );
    const valueWithoutComma = valueWithoutMinus.replace(/,/g, ".");
    const valueWithoutMultipleDots = valueWithoutComma.replace(/(\.)(?=.*\.)/g, "");
    return valueWithoutMultipleDots;
  };

  return (
    <LakeTextInput
      {...props}
      value={currentValue}
      inputMode="decimal"
      onKeyPress={e => {
        const key = e.nativeEvent.key;
        // Allow keys: Backspace, Delete, Arrow, Tab, Enter
        if (key.match(/Backspace|Delete|Arrow|Tab|Enter/)) {
          return;
        }
        // Allow only numbers, dot, comma and minus
        if (!key.match(/[0-9,.-]/)) {
          e.preventDefault();
        }
      }}
      onChangeText={value => {
        setCurrentValue(valueCleanup(value));
        onChange(value);
      }}
      hideErrors={props.error === undefined}
    />
  );
};
