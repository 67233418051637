import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { StyleSheet, Text } from "react-native";
import { fontColorVariants } from "../../styles/constants";

const styles = StyleSheet.create({
  helpText: {
    fontSize: 10,
  },
  red: {
    color: fontColorVariants.destructive500,
  },
});

type Props = {
  label: string;
  items?: { value: string; name: string }[];
  customOnChange?: (value: string) => void;
  help?: string | undefined;
  error?: string | undefined;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  hasEmptyRow?: boolean;
};

type Props2 = {
  value: string;
  onChange: (value: string) => void;
  error: string | undefined;
};

type SelectFieldProps = {
  label: string;
  value?: string;
  onChange?: (value: string) => void;
  help?: string | undefined;
  error?: string | undefined;
  items?: { value: string; name: string }[];
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
};

export default function Select({
  label,
  items,
  help,
  customOnChange,
  required = false,
  disabled = false,
  readOnly = false,
  hasEmptyRow = false,
}: Props) {
  const itemsParsed = hasEmptyRow && items ? [{ name: "-----", value: "" }].concat(items) : items;

  return ({ value, onChange, error }: Props2) => (
    <SelectField
      label={label}
      value={value}
      onChange={customOnChange ? customOnChange : onChange}
      error={error}
      items={itemsParsed}
      help={help}
      required={required}
      disabled={disabled}
      readOnly={readOnly}
    />
  );
}

export const SelectField = ({
  label,
  value,
  onChange = () => {},
  help,
  error,
  items = [],
  required = false,
  disabled = false,
  readOnly = false,
}: SelectFieldProps) => (
  <LakeLabel
    label={label}
    extra={() => (required ? <Text style={styles.red}>*</Text> : <></>)}
    render={id => (
      <>
        <LakeSelect
          id={id}
          value={value}
          hideErrors={!(error != undefined)}
          error={error}
          items={items}
          onValueChange={onChange}
          disabled={disabled}
          readOnly={readOnly}
        />

        {help != null && (
          <LakeText variant="smallRegular" color={"gray"} style={styles.helpText}>
            {help}
          </LakeText>
        )}
      </>
    )}
  />
);
