import { Box } from "@swan-io/lake/src/components/Box";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { Space } from "@swan-io/lake/src/components/Space";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { StyleSheet, Text, View } from "react-native";
import { useForm } from "react-ux-form";
import { match } from "ts-pattern";
import {
  Company,
  CompanyStaticDocument,
  OperationInfo,
  Static,
  UpdateCompanyDocument,
} from "../graphql/crm";
import { fontColorVariants } from "../styles/constants";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { FormCompanyState, UpdateCompanyState } from "../utils/types";
import {
  extractQueryData,
  handlerFieldErrors,
  tapError,
  validateRequired,
} from "../utils/validations";
import { CompanyAddresses } from "./CompanyAddresses";
import { Heading } from "./Heading";
import { UploadShowLogoCompany } from "./UploadShowLogoCompany";
import { Button } from "./forms/Button";
import Input from "./forms/Input";
import Select from "./forms/Select";

const styles = StyleSheet.create({
  form: {
    maxWidth: 800,
    paddingVertical: 16,
    paddingHorizontal: 24,
  },
  row: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: 24,
    marginBottom: 16,
  },
  note: {
    color: fontColorVariants.neutral500,
  },
});

const CONSTITUTION = {
  AUTONOMO: "autonomo",
  EMPRESA: "empresa",
};

type Props = {
  company: Company;
};

export const CompanyInfo = ({ company }: Props) => {
  const route = Router.useRoute(["CompanyRoot", "CompanyEdit", "CompanyAddresses"]);

  const { data: staticData } = useUrqlQuery({ query: CompanyStaticDocument }, []);

  const status = extractQueryData(staticData, "value.value.companyStatic.status") as Static[];
  const types = extractQueryData(staticData, "value.value.companyStatic.types") as Static[];
  const employees = extractQueryData(staticData, "value.value.companyStatic.employees") as Static[];
  const sectors = extractQueryData(staticData, "value.value.companyStatic.sectors") as Static[];

  const { Field, setFieldError, submitForm } = useForm<UpdateCompanyState>({
    name: {
      initialValue: company?.name ?? "",
      sanitize: value => value.trim(),
      validate: validateRequired,
    },
    nif: { initialValue: company?.nif ?? "", validate: validateRequired },
    phone: {
      initialValue: company?.phone ?? "",
      validate: validateRequired,
    },
    email: { initialValue: company?.email ?? "" },
    constitution: { initialValue: company?.constitution ?? "", validate: validateRequired },
    companyType: { initialValue: company?.companyType ?? "", validate: validateRequired },
    numEmployees: { initialValue: company?.numEmployees ?? "", validate: validateRequired },
    status: { initialValue: company?.status ?? "", validate: validateRequired },
    ccc: { initialValue: company?.ccc ?? "" },
    sector: { initialValue: company?.sector ?? "" },
  });

  const [, updateCompany] = useUrqlMutation(UpdateCompanyDocument);

  const onSave = () => {
    submitForm(values => {
      updateCompany({
        input: {
          id: company.id,
          name: values.name ?? "",
          nif: values.nif ?? "",
          phone: values.phone ?? "",
          email: values.email ?? "",
          constitution: company.constitution,
          companyType: values.companyType ?? "",
          numEmployees: values.numEmployees ?? "",
          status: values.status ?? "",
          ccc: values.ccc ?? "",
          sector: values.sector ?? "",
        },
      })
        .tapError(tapError)
        .mapOk(data => {
          match(data.updateCompany)
            .with({ __typename: "OperationInfo" }, () =>
              handlerFieldErrors<FormCompanyState>(
                data.updateCompany as OperationInfo,
                setFieldError,
              ),
            )
            .otherwise(() => {
              showToast({
                variant: "success",
                title: t("common.savedSuccessfully"),
                autoClose: true,
              });
            });
        });
    });
  };

  return match(route)
    .with({ name: "CompanyRoot" }, () => null)
    .with({ name: "CompanyEdit" }, () => (
      <>
        <Heading title={t("common.company")} />

        <View style={styles.form}>
          <Box direction="row" alignItems="start" style={styles.row}>
            <View>
              <LakeLabel
                label={t("user.loadImage")}
                render={() => <UploadShowLogoCompany logoUrl={company?.logoUrl ?? ""} />}
              />
            </View>
          </Box>

          <Space height={24} />

          <Box direction="row" alignItems="start" style={styles.row}>
            <Field name="name">
              {Input({
                label:
                  company?.constitution == CONSTITUTION.AUTONOMO
                    ? t("common.nameComplete")
                    : t("common.companyName"),
                disabled: true,
              })}
            </Field>

            <Field name="nif">
              {Input({
                label: t("contact.nif"),
                disabled: true,
              })}
            </Field>
          </Box>

          <Text style={styles.note}>{t("user.infoNotEditable")}</Text>
          <Space height={16} />

          <Field name="ccc">
            {Input({
              label: t("user.ccc"),
            })}
          </Field>

          <Space height={16} />

          <Box direction="row" alignItems="start" style={styles.row}>
            <Field name="email">
              {Input({
                label: t("common.email"),
              })}
            </Field>

            <Field name="phone">
              {Input({
                label: t("common.phone"),
              })}
            </Field>
          </Box>

          <Field name="sector">
            {Select({
              label: t("onboarding.companySector"),
              items: sectors?.map(item => ({
                value: item.id,
                name: item.name,
              })),
            })}
          </Field>

          <Space height={16} />

          {company?.constitution == CONSTITUTION.EMPRESA && (
            <Box direction="row" alignItems="start" style={styles.row}>
              <Field name="companyType">
                {Select({
                  label: t("onboarding.companyType"),
                  items: types?.map(item => ({
                    value: item.id,
                    name: item.name,
                  })),
                })}
              </Field>

              <Field name="numEmployees">
                {Select({
                  label: t("onboarding.numEmployees"),
                  items: employees?.map(item => ({
                    value: item.id,
                    name: item.name,
                  })),
                })}
              </Field>
            </Box>
          )}

          {company?.constitution == CONSTITUTION.AUTONOMO && (
            <>
              <Field name="status">
                {Select({
                  label: t("onboarding.status"),
                  items: status?.map(item => ({
                    value: item.id,
                    name: item.name,
                  })),
                })}
              </Field>

              <Space height={16} />
            </>
          )}

          <Box direction="row" justifyContent="end">
            <Button onPress={onSave}>{t("common.save")}</Button>
          </Box>
        </View>
      </>
    ))
    .with({ name: "CompanyAddresses" }, () => <CompanyAddresses />)
    .run();
};
