import { RightPanel } from "@components/RightPanel";
import { TabView } from "@swan-io/lake/src/components/TabView";
import { useState } from "react";
import { StyleSheet, View } from "react-native";
import { InvoiceHistoryOutput, Static } from "../../../graphql/crm";
import { t } from "../../../utils/i18n";
import { InvoiceHistory } from "./InvoiceHistory";

const styles = StyleSheet.create({
  rightpanel: {
    paddingTop: 56,
    paddingLeft: 56,
    paddingRight: 27,
  },
  opacity: {
    opacity: 0.7,
  },
});

type Props = {
  history?: InvoiceHistoryOutput[];
  status?: Static[];
  onPress?: () => void;
};

export const Tabs = ({ history, status, onPress }: Props) => {
  const [activeTabId, setActiveTabId] = useState("invoice");

  return (
    <>
      <View style={history === undefined && styles.opacity}>
        <TabView
          tabs={[
            {
              id: "invoice",
              label: t("common.invoice"),
            },
            {
              id: "history",
              label: t("common.history"),
            },
          ]}
          otherLabel="Invoices"
          activeTabId={activeTabId}
          onChange={id => {
            if (history === undefined) {
              return;
            }
            setActiveTabId(id);
            onPress?.();
          }}
        />
      </View>

      <RightPanel
        visible={activeTabId === "history"}
        overlay={true}
        onPressClose={() => setActiveTabId("invoice")}
      >
        <View style={styles.rightpanel}>
          <InvoiceHistory data={history} status={status} />
        </View>
      </RightPanel>
    </>
  );
};
