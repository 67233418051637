import { AsyncData, Option, Result } from "@swan-io/boxed";
import { Data } from "@urql/exchange-graphcache";
import { CombinedError } from "urql";
import { PurchaseCreateStatic, SalesCreateStatic, Static } from "../../graphql/crm";

export const extractSalesStatic = (
  data: AsyncData<Result<Data, CombinedError>>,
  staticType: keyof SalesCreateStatic,
) =>
  data
    .toOption()
    .flatMap(result => result.toOption())
    .flatMap(row => Option.fromNullable(row.salesInvoiceCreateStatic))
    .map(
      salesInvoiceCreateStatic =>
        salesInvoiceCreateStatic[staticType as keyof typeof salesInvoiceCreateStatic] as Static[],
    )
    .getWithDefault([]);

export const extractPurchaseStatic = (
  data: AsyncData<Result<Data, CombinedError>>,
  staticType: keyof PurchaseCreateStatic,
) =>
  data
    .toOption()
    .flatMap(result => result.toOption())
    .flatMap(row => Option.fromNullable(row.purchaseInvoiceCreateStatic))
    .map(
      purchaseInvoiceCreateStatic =>
        purchaseInvoiceCreateStatic[
          staticType as keyof typeof purchaseInvoiceCreateStatic
        ] as Static[],
    )
    .getWithDefault([]);

export const base64ToFile = async (base64: string, filename: string) => {
  const response = await fetch(base64);
  const blob = await response.blob();
  return new File([blob], filename, { type: blob.type });
};
