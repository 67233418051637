import { Lazy } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { Space } from "@swan-io/lake/src/components/Space";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { CountryPicker } from "@swan-io/shared-business/src/components/CountryPicker";
import { PlacekitAddressSearchInput } from "@swan-io/shared-business/src/components/PlacekitAddressSearchInput";
import { allCountries } from "@swan-io/shared-business/src/constants/countries";
import { printIbanFormat } from "@swan-io/shared-business/src/utils/validation";
import { useState } from "react";
import { StyleSheet, View } from "react-native";
import { Form } from "react-ux-form";
import { match } from "ts-pattern";
import { ContactStatic, ContactsStaticDocument, Static } from "../../../graphql/crm";
import { t } from "../../../utils/i18n";
import { SaveIcon } from "../../../utils/icons";
import { FormContactState } from "../types";
import { Button } from "./../../../components/forms/Button";
import { FormEnter } from "./../../../components/forms/FormEnter";
import Input from "./../../../components/forms/Input";

const styles = StyleSheet.create({
  field: {
    flexBasis: "50%",
    flexShrink: 1,
    alignSelf: "stretch",
  },
  gap: {
    gap: 32,
  },
});

type Props = {
  contactType: string;
  Field: Form<FormContactState>["Field"];
  setFieldValue: Form<FormContactState>["setFieldValue"];
  getFieldState: Form<FormContactState>["getFieldState"];
  FieldsListener: Form<FormContactState>["FieldsListener"];
  large: boolean;
  onSave: () => void;
  showAddress?: boolean;
  children?: React.ReactNode;
};

const countryItems = Lazy(() => allCountries.filter(cca3 => cca3 !== "USA"));

export const Editor = ({
  contactType,
  Field,
  setFieldValue,
  getFieldState,
  FieldsListener,
  large,
  onSave,
  showAddress = true,
  children,
}: Props) => {
  const { data } = useUrqlQuery({ query: ContactsStaticDocument }, []);

  const extractStatic = (staticType: keyof ContactStatic) =>
    data
      .toOption()
      .flatMap(result => result.toOption())
      .map(types => types.contactsStatic[staticType] as Static[])
      .getWithDefault([]);

  const paymentMethodItems = extractStatic("paymentMethods");
  const [paymentMethodType, setPaymentMethodType] = useState(
    getFieldState?.("paymentMethod").value ?? "",
  );

  return (
    <FormEnter onSubmit={onSave}>
      <Box direction={large ? "row" : "column"} style={large && styles.gap}>
        <View style={styles.field}>
          <Field name="name">
            {Input({
              label: t("contact.name"),
            })}
          </Field>
        </View>

        <View style={styles.field}>
          <Field name="nif">
            {Input({
              label: t("contact.nif"),
            })}
          </Field>
        </View>
      </Box>

      {large && <Space height={16} />}

      {showAddress && (
        <>
          <FieldsListener names={["country"]}>
            {({ country }) => (
              <Field name="address">
                {({ value, onChange, error }) => (
                  <LakeLabel
                    label={t("contact.address")}
                    render={id => (
                      <PlacekitAddressSearchInput
                        shouldDisplaySuggestions={true}
                        id={id}
                        apiKey={__env.CLIENT_PLACEKIT_API_KEY}
                        country={country.value}
                        value={value}
                        onValueChange={onChange}
                        onSuggestion={suggestion => {
                          setFieldValue("address", suggestion.completeAddress);
                        }}
                        language="es"
                        placeholder={t("contact.address.placeHolder")}
                        emptyResultText={t("common.empty")}
                        error={error}
                      />
                    )}
                  />
                )}
              </Field>
            )}
          </FieldsListener>

          <Box direction={large ? "row" : "column"} style={large && styles.gap}>
            <View style={styles.field}>
              <Field name="postalCode">
                {Input({
                  label: t("contact.postalCode"),
                })}
              </Field>
            </View>

            <View style={styles.field}>
              <Field name="country">
                {({ value, onChange, ref, error }) => (
                  <LakeLabel
                    label={t("common.country")}
                    render={id => (
                      <CountryPicker
                        id={id}
                        ref={ref}
                        value={value}
                        error={error}
                        countries={countryItems.get()}
                        onValueChange={onChange}
                      />
                    )}
                  />
                )}
              </Field>
            </View>
          </Box>
        </>
      )}

      <Box direction={large ? "row" : "column"} style={large && styles.gap}>
        <View style={styles.field}>
          <Field name="phone">
            {Input({
              label: t("common.phone"),
            })}
          </Field>
        </View>

        <View style={styles.field}>
          <Field name="email">
            {Input({
              label: t("common.email"),
            })}
          </Field>
        </View>
      </Box>

      {large && <Space height={16} />}

      {match(contactType.toLowerCase())
        .with("supplier", () => (
          <Box direction={large ? "row" : "column"} style={large && styles.gap}>
            <View style={styles.field}>
              <Field name="paymentMethod">
                {({ value, onChange, error, ref }) => (
                  <LakeLabel
                    label={t("contact.paymentMethod")}
                    render={id => (
                      <LakeSelect
                        id={id}
                        ref={ref}
                        value={value}
                        error={error}
                        items={paymentMethodItems}
                        onValueChange={value => {
                          onChange(value);
                          setPaymentMethodType(value);
                        }}
                      />
                    )}
                  />
                )}
              </Field>
            </View>

            <View style={styles.field}>
              <Field name="iban">
                {({ value, onChange, error, ref }) => (
                  <LakeLabel
                    label={t("contact.iban")}
                    render={id => (
                      <LakeTextInput
                        id={id}
                        ref={ref}
                        value={printIbanFormat(value ?? "")}
                        error={error}
                        hideErrors={error === undefined}
                        disabled={!["transferencia", "domiciliacion"].includes(paymentMethodType)}
                        onChangeText={onChange}
                      />
                    )}
                  />
                )}
              </Field>
            </View>
          </Box>
        ))
        .otherwise(() => null)}

      {children}

      <Box alignItems="end">
        <Button onPress={onSave} icon={<SaveIcon />} reverse={true}>
          {t("common.save")}
        </Button>
      </Box>
    </FormEnter>
  );
};
