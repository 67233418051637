import { AutoWidthImage } from "@swan-io/lake/src/components/AutoWidthImage";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import Popover from "react-native-popover-view";
// import trash from "../../../assets/icons/delete.svg";
import arrow from "../../../assets/icons/arrow.svg";
import verticalDots from "../../../assets/icons/dots-vertical.svg";
import download from "../../../assets/icons/download.svg";
import rectify from "../../../assets/icons/rectify.svg";
// import edit from "../../../assets/icons/edit.svg";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { useState } from "react";
import { match } from "ts-pattern";
import edit from "../../../assets/icons/edit.svg";
import mail from "../../../assets/icons/mail.svg";
import { FacturaeDocument, SalesInvoiceRelayQuery } from "../../../graphql/crm";
import { borderColorVariants } from "../../../styles/constants";
import { downloadFile, downloadPDF } from "../../../utils/blob";
import { t } from "../../../utils/i18n";
import { Router } from "../../../utils/routes";
import { CheckBoolRuleLock, checkRule, useSubscription } from "../../../utils/subscription";
import { handlerErrors, tapError } from "../../../utils/validations";
import { useLoading } from "../../context/LoadingContext";
import { useUser } from "../../context/UserContext";
import { get_pdf } from "../services";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 16,
    padding: 4,
    width: "100%",
  },
  menu: {
    padding: 8,
    borderRadius: 8,
  },
  menuItem: {
    padding: 8,
    width: 160,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
    borderBottomColor: borderColorVariants.primary,
    borderBottomWidth: 1,
  },
  menuText: {
    marginLeft: 8,
  },
});

type KebabMenuProps = {
  invoice: SalesInvoiceRelayQuery;
  setShowSentModal: (show: boolean) => void;
  setInvoice: (invoice: SalesInvoiceRelayQuery) => void;
  setActiveInvoice: (invoice: SalesInvoiceRelayQuery) => void;
  setShowAssignmentModal: (show: boolean) => void;
};

const CONVERTSTATES = ["PROFORMA", "QUOTE", "DELIVERY"];
const RECTIFYSTATES = CONVERTSTATES.concat(["DRAFT", "PENDING"]);

const KebabMenu = ({
  invoice,
  setShowSentModal,
  setInvoice,
  setActiveInvoice,
  setShowAssignmentModal,
}: KebabMenuProps) => {
  const { subscription } = useUser();
  const { setLoading } = useLoading();
  const invoiceId = invoice.id as string;
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const [, facturae] = useUrqlMutation(FacturaeDocument);
  const handleSend = () => {
    setShowPopover(false);
    setInvoice(invoice);
    setShowSentModal(true);
  };

  const handleDownload = () => {
    setShowPopover(false);
    setLoading(true);
    get_pdf(invoiceId)
      .then(async response => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }

        const name = match(invoice.status)
          .with("QUOTE", () => t("budget"))
          .with("DELIVERY", () => t("deliveryNote"))
          .with("PROFORMA", () => t("invoice.proforma"))
          .otherwise(() => t("common.invoice"));

        const blob = await response.blob();
        downloadPDF(blob, `${name.toLocaleLowerCase()}_${invoice.seriesCurrent ?? invoiceId}`);
      })
      .catch((error: Error) => {
        showToast({
          variant: "error",
          title: error.message,
          autoClose: true,
        });
      })
      .finally(() => {
        setLoading(false);
        setShowPopover(false);
      });
  };

  const handleFacturae = () => {
    setShowPopover(false);
    facturae({ invoiceId: Number(invoiceId) })
      .mapOk(data => {
        match(data.facturae)
          .with({ __typename: "OperationInfo" }, handlerErrors)
          .with({ __typename: "Facturae" }, facturae => {
            const blob = new Blob([facturae.xml], { type: "application/xml" });
            downloadFile(blob, `facturae_${invoiceId}.xml`);
          });
      })
      .tapError(tapError)
      .mapError(tapError);
  };

  const handleSignFacturae = () => {
    window.open("https://valide.redsara.es/valide/firmar/ejecutar.html", "_blank");
  };

  const handleRectify = () => {
    setInvoice(invoice);
    setShowPopover(false);
    Router.push("InvoicesSalesRectify", { invoiceId });
  };

  const { showUpgrade } = useSubscription();

  const handleConvertToInvoice = () => {
    if (checkRule(subscription, "has_convert_proforma_to_invoice")) {
      const newInvoice = { ...invoice, seriesCurrent: null };
      setInvoice(newInvoice);
      setActiveInvoice(newInvoice);
      setShowPopover(false);
      Router.push("InvoicesSalesList", { visible: "1" });
    } else {
      setShowPopover(false);
      showUpgrade({});
    }
  };

  const handleConvertToDelivery = () => {
    if (checkRule(subscription, "has_convert_proforma_to_invoice")) {
      const newInvoice = { ...invoice, seriesCurrent: null };
      setInvoice(newInvoice);
      setActiveInvoice(newInvoice);
      setShowPopover(false);
      Router.push("InvoicesSalesDeliveryCreate", { quoteId: newInvoice.id as string });
    } else {
      setShowPopover(false);
      showUpgrade({});
    }
  };

  const handleAssignProject = () => {
    setInvoice(invoice);
    setShowAssignmentModal(true);
    setShowPopover(false);
  };

  //   const handleEdit = () => {
  //   };

  //   const handleDelete = () => {
  //   };

  return (
    <View
      style={styles.container}
      onTouchEnd={e => {
        e.stopPropagation();
      }}
    >
      <TouchableOpacity onPress={handleSend}>
        <AutoWidthImage sourceUri={mail} height={23} />
      </TouchableOpacity>

      <Popover
        isVisible={showPopover}
        popoverStyle={styles.menu}
        onRequestClose={() => setShowPopover(false)}
        from={
          <TouchableOpacity onPress={() => setShowPopover(true)}>
            <AutoWidthImage sourceUri={verticalDots} height={23} />
          </TouchableOpacity>
        }
      >
        <View
          style={styles.menu}
          onTouchEnd={e => {
            e.stopPropagation();
          }}
        >
          {/* <TouchableOpacity onPress={handleEdit} style={styles.menuItem}>
            <AutoWidthImage sourceUri={edit} height={23} width={23}/>
            <Text style={styles.menuText}>Edit</Text>
          </TouchableOpacity> */}

          <TouchableOpacity onPress={handleDownload} style={styles.menuItem}>
            <AutoWidthImage sourceUri={download} height={23} width={23} />
            <Text style={styles.menuText}>{t("common.download")}</Text>
          </TouchableOpacity>

          {!Boolean(invoice.rectify) && !RECTIFYSTATES.includes(invoice.status) && (
            <>
              <TouchableOpacity onPress={handleFacturae} style={styles.menuItem}>
                <AutoWidthImage sourceUri={download} height={23} width={23} />
                <Text style={styles.menuText}>{t("invoice.electronic")}</Text>
              </TouchableOpacity>

              <TouchableOpacity onPress={handleSignFacturae} style={styles.menuItem}>
                <AutoWidthImage sourceUri={arrow} height={23} width={23} />
                <Text style={styles.menuText}>{t("invoice.electronicSign")}</Text>
              </TouchableOpacity>

              <TouchableOpacity onPress={handleRectify} style={styles.menuItem}>
                <AutoWidthImage sourceUri={rectify} height={23} width={23} />
                <Text style={styles.menuText}>{t("common.rectify")}</Text>
              </TouchableOpacity>
            </>
          )}

          {["QUOTE"].includes(invoice.status) && (
            <TouchableOpacity onPress={handleConvertToDelivery} style={styles.menuItem}>
              <AutoWidthImage sourceUri={rectify} height={23} width={23} />
              <Text style={styles.menuText}>{t("common.convertToDelivery")}</Text>

              {CheckBoolRuleLock(subscription, "has_convert_quote_to_invoice")}
            </TouchableOpacity>
          )}

          {CONVERTSTATES.includes(invoice.status) && (
            <TouchableOpacity onPress={handleConvertToInvoice} style={styles.menuItem}>
              <AutoWidthImage sourceUri={rectify} height={23} width={23} />
              <Text style={styles.menuText}>{t("common.convert")}</Text>

              {CheckBoolRuleLock(subscription, "has_convert_proforma_to_invoice")}
            </TouchableOpacity>
          )}

          {invoice.status === "OVERDUED" && (
            <TouchableOpacity onPress={handleSend} style={styles.menuItem}>
              <AutoWidthImage sourceUri={mail} height={23} width={23} />
              <Text style={styles.menuText}>{t("common.claim")}</Text>
            </TouchableOpacity>
          )}

          <TouchableOpacity onPress={handleAssignProject} style={styles.menuItem}>
            <AutoWidthImage sourceUri={edit} height={23} width={23} />
            <Text style={styles.menuText}>{"Asignar proyecto"}</Text>
          </TouchableOpacity>

          {/* <TouchableOpacity onPress={handleDelete} style={styles.menuItem}>
            <AutoWidthImage sourceUri={trash} height={23} width={23}/>
            <Text style={styles.menuText}>Delete</Text>
          </TouchableOpacity> */}
        </View>
      </Popover>
    </View>
  );
};

export default KebabMenu;
