import Chart, { ChartDataset, ScriptableContext } from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useEffect, useRef, useState } from "react";
import { formatCurrency } from "../../utils/i18n";

type Props = {
  labels: string[];
  datasets: ChartDataset[];
};

export const HorizontalBar = ({ labels = [], datasets = [] }: Props) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);

  const [chart, setChart] = useState<Chart | null>(null);

  useEffect(() => {
    if (chartRef.current == null) {
      return;
    }

    const chart = new Chart(chartRef.current, {
      type: "bar",
      plugins: [ChartDataLabels],
      data: {
        labels,
        datasets: [],
      },
      options: {
        indexAxis: "y",
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
          datalabels: {
            color: "white",
            anchor: "end",
            align: "start",
            textAlign: "right",
            font: {
              size: 18,
              weight: 600,
            },
            formatter: (value, context) => {
              return `${formatCurrency(value as number, "EUR")}\n${context.dataset.label}`;
            },
          },
        },
        scales: {
          x: {
            display: false,
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      },
    });
    setChart(chart);
  }, []);

  useEffect(() => {
    if (chart != null && labels.length > 0) {
      chart.data.labels = labels;
      chart.data.datasets = [
        {
          label: datasets?.[0]?.label ?? "",
          data: datasets?.[0]?.data.filter(i => i != 0) ?? [null],
          backgroundColor: (context: ScriptableContext<"bar">) => {
            const { chart, parsed } = context;
            const { ctx, chartArea, scales } = chart;

            if (chartArea == null) {
              return "blue";
            }

            const value = parsed?.x ?? 0;
            const max = scales.x != null ? scales.x.max : 0;
            const barWidth = (value / max) * chartArea.width;

            const gradient = ctx.createLinearGradient(0, 0, barWidth, 0);
            gradient.addColorStop(0, "rgba(65, 185, 230, 0.70)");
            gradient.addColorStop(1, "rgba(5, 85, 250, 0.70)");

            return gradient;
          },
        },
        {
          label: datasets?.[1]?.label ?? "",
          data: datasets?.[1]?.data.filter(i => i != 0) ?? [null],
          backgroundColor: (context: ScriptableContext<"bar">) => {
            const { chart, parsed } = context;
            const { ctx, chartArea, scales } = chart;

            if (chartArea == null) {
              return "blue";
            }

            const value = parsed?.x ?? 0;
            const max = scales.x != null ? scales.x.max : 0;
            const barWidth = (value / max) * chartArea.width;

            const gradient = ctx.createLinearGradient(0, 0, barWidth, 0);
            gradient.addColorStop(0, "#FF5A0F");
            gradient.addColorStop(1, "#FF0F64");

            return gradient;
          },
        },
      ];
      chart.update();
    }
  }, [labels, datasets]);

  return <canvas ref={chartRef} height={120}></canvas>;
};
