import { StyleSheet } from "react-native";
import { COLORS } from "../values/colors";

const GRADIENT_LEFT = "linear-gradient(180deg, #042063 0%, #052974 100%)";

export const styles = StyleSheet.create({
  h4: {
    color: COLORS.WHITE,
    fontSize: 28,
    fontWeight: "700",
    letterSpacing: -0.8,
  },
  welcome: {
    marginVertical: "1rem",
    flexWrap: "wrap",
  },
  left: {
    display: "flex",
    flexBasis: "50%",
    justifyContent: "space-between",
    paddingTop: 54,
    gap: 24,
  },
  bgGradient: {
    backgroundImage: GRADIENT_LEFT,
  },
  leftContainer: {
    display: "flex",
    gap: 37,
    paddingHorizontal: 82,
  },
  background: {
    height: 298,
  },
  box: {
    display: "flex",
    gap: 12,
    backgroundColor: COLORS.WHITE,
    boxShadow: "0 2px 6px rgba(16, 24, 40, 0.06)",
    padding: "2rem",
    borderRadius: 12,
    maxWidth: 512,
  },
  form: {
    display: "flex",
    alignItems: "center",
    backgroundColor: COLORS.PRIMARY50,
    paddingVertical: "2rem",
    paddingHorizontal: "1rem",
  },
  h5: {
    color: COLORS.NEUTRAL900,
    fontSize: 20,
    fontWeight: "800",
    letterSpacing: -0.8,
  },
  link: {
    color: COLORS.PRIMARY500,
    textAlign: "center",
  },
  small: {
    color: COLORS.NEUTRAL500,
    fontSize: 12,
    lineHeight: 20,
  },
});
