/* eslint-disable react-native/no-unused-styles */
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { Pressable } from "@swan-io/lake/src/components/Pressable";
import { Fragment, ReactNode, useState } from "react";
import { StyleSheet, ViewStyle } from "react-native";
import { COLORS } from "../../values/colors";

const TRANSPARENT = "transparent";

const styles = StyleSheet.create({
  default: {
    "--text-semibold-font-weight": "500",
    fontWeight: 500,
    backgroundColor: COLORS.WHITE,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: COLORS.PRIMARY300,
    height: "auto",
    paddingVertical: 8,
    paddingLeft: 16,
    paddingRight: 16,
    minHeight: 40,
  },
  small: {
    fontSize: 12,
    paddingVertical: 8,
    paddingLeft: 12,
    paddingRight: 12,
    minHeight: "auto",
  },
  large: {
    fontSize: 16,
    lineHeight: 22,
    paddingVertical: 12,
    paddingLeft: 20,
    paddingRight: 20,
    minWidth: 184,
  },
  solid: {},
  solid_hover: {},
  outlined: {},
  outlined_hover: {},
  group: {
    color: COLORS.NEUTRAL200,
    backgroundColor: COLORS.GRAY700,
    borderColor: COLORS.GRAY700,
    borderRadius: 25,
  },
  group_hover: {
    color: COLORS.WHITE,
  },

  /* PRIMARY */
  primary: {
    color: COLORS.WHITE,
    backgroundColor: COLORS.PRIMARY500,
    borderColor: COLORS.PRIMARY500,
  },
  primary_solid: {},
  primary_solid_hover: {
    color: COLORS.PRIMARY500,
    backgroundColor: TRANSPARENT,
  },
  primary_outlined: {
    color: COLORS.PRIMARY500,
    backgroundColor: TRANSPARENT,
  },
  primary_outlined_hover: {
    color: COLORS.WHITE,
    backgroundColor: COLORS.PRIMARY500,
    borderColor: COLORS.PRIMARY500,
  },
  primary_group: {},
  primary_group_hover: {},

  /* SECONDARY */
  secondary: {
    color: COLORS.PRIMARY500,
  },
  secondary_solid: {
    "--color-gray-600": COLORS.PRIMARY500,
    backgroundColor: COLORS.PRIMARY50,
    borderColor: COLORS.PRIMARY50,
  },
  secondary_solid_hover: {
    borderColor: COLORS.PRIMARY500,
  },
  secondary_outlined: {
    "--color-gray-600": COLORS.PRIMARY300,
    backgroundColor: TRANSPARENT,
  },
  secondary_outlined_hover: {
    borderColor: COLORS.PRIMARY500,
  },
  secondary_group: {},
  secondary_group_hover: {},

  /* TERTIARY */
  tertiary: {
    color: COLORS.NEUTRAL700,
    backgroundColor: TRANSPARENT,
    borderWidth: 0,
  },
  tertiary_solid: {},
  tertiary_solid_hover: {
    color: COLORS.PRIMARY500,
  },
  tertiary_outlined: {
    "--color-gray-600": COLORS.NEUTRAL500,
    borderWidth: 1,
    borderColor: COLORS.NEUTRAL500,
  },
  tertiary_outlined_hover: {
    backgroundColor: COLORS.NEUTRAL100,
  },
  tertiary_group: {},
  tertiary_group_hover: {},

  icon: {
    gap: 8,
  },
  reverse: {
    flexDirection: "row-reverse",
  },
  button_group: {
    flexDirection: "row",
    justifyContent: "center",
    gap: 1,
    backgroundColor: COLORS.GRAY700,
    borderRadius: 25,
  },
  button_group_transparent: {
    backgroundColor: TRANSPARENT,
  },
  buttons_separator: {
    width: 1,
    backgroundColor: COLORS.NEUTRAL200,
  },

  /* DISABLED */
  solid_disabled: {},
  outlined_disabled: {},
  group_disabled: {
    color: COLORS.GRAY700,
    backgroundColor: TRANSPARENT,
  },
});

type Props = {
  size?: "small" | "large";
  mode?: "primary" | "secondary" | "tertiary";
  disabled?: boolean;
  style?: "solid" | "outlined" | "group";
  onPress?: () => void;
  children?: ReactNode;
  icon?: ReactNode;
  reverse?: boolean;
  custom?: ViewStyle;
};

export const Button = ({
  size,
  mode = "primary",
  disabled = false,
  style = "solid",
  onPress,
  children,
  icon,
  reverse,
  custom,
}: Props) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <Pressable onHoverIn={() => setIsHover(true)} onHoverOut={() => setIsHover(false)}>
      <LakeButton
        size={size}
        mode={mode}
        disabled={disabled}
        style={[
          styles.default,
          size && styles[size],
          mode && styles[mode],
          style && styles[style],
          style && isHover && styles[`${style}_hover`],
          style && disabled && styles[`${style}_disabled`],
          style && styles[`${mode}_${style}`],
          icon != null && styles.icon,
          reverse === true && styles.reverse,
          isHover && styles[`${mode}_${style}_hover`],
          custom,
        ]}
        onPress={onPress}
      >
        {children}
        {icon}
      </LakeButton>
    </Pressable>
  );
};

type GroupProps = { children: ReactNode | ReactNode[]; transparent?: boolean };

export const ButtonGroup = ({ children, transparent = false }: GroupProps) => {
  const buttons = Array.isArray(children) ? children : [children];

  return (
    <Box style={[styles.button_group, transparent && styles.button_group_transparent]}>
      {buttons.map((child, index) => (
        <Fragment key={index}>
          {index > 0 && index < buttons.length && <Box style={styles.buttons_separator} />}
          {child}
        </Fragment>
      ))}
    </Box>
  );
};
