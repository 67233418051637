import { Tile } from "@components/Tile";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { ListRightPanelContent } from "@swan-io/lake/src/components/ListRightPanel";
import { Space } from "@swan-io/lake/src/components/Space";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { ScrollView, StyleSheet, View } from "react-native";
import { Product, ProductsRelay } from "../../../graphql/crm";
import { ProductEditor } from "./ProductEditor";

type Props = {
  product: ProductsRelay | undefined;
  large: boolean;
  onClose?: () => void;
};

const styles = StyleSheet.create({
  scrollContainerMobile: {
    minHeight: "100%",
  },
  scrollContainerDesktop: {
    ...commonStyles.fill,
  },
  container: {
    ...commonStyles.fill,
  },
  tile: {
    alignItems: "center",
  },
  wrapText: {
    wordBreak: "break-all",
  },
});

export const ProductRightPanel = ({ product, large, onClose }: Props) => {
  return (
    <ScrollView
      contentContainerStyle={large ? styles.scrollContainerDesktop : styles.scrollContainerMobile}
    >
      <View style={styles.container}>
        <ListRightPanelContent large={large}>
          <Tile style={styles.tile}>
            <Box alignItems="center">
              <LakeHeading
                level={1}
                variant={large ? "h1" : "h3"}
                align="center"
                style={styles.wrapText}
              >
                {product?.description}
              </LakeHeading>
            </Box>
          </Tile>
        </ListRightPanelContent>

        <Space height={24} />

        <ListRightPanelContent large={large}>
          <ProductEditor
            product={product as unknown as Product}
            onPressClose={onClose}
            showCancel={false}
          />
        </ListRightPanelContent>
      </View>
    </ScrollView>
  );
};
