import { DecimalInput } from "@components/DecimalInput";
import { Button } from "@components/forms/Button";
import { Box } from "@swan-io/lake/src/components/Box";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeLabelledCheckbox } from "@swan-io/lake/src/components/LakeCheckbox";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { useForm } from "react-ux-form";
import { EarnExtraEmployee, EarnExtraPayroll } from "../../../graphql/crm";
import { t } from "../../../utils/i18n";
import { validateNumericNullableRequired, validateRequired } from "../../../utils/validations";
import { useNestedForm } from "../../components/NestedForm";
import { EarnExtraState } from "../types";

type Props = {
  earnExtra: EarnExtraEmployee | EarnExtraPayroll;
  onChange: (row: EarnExtraEmployee | EarnExtraPayroll) => void;
  onDelete: () => void;
  disabled?: boolean;
};
const styles = StyleSheet.create({
  line: {
    display: "grid",
    gridTemplateColumns: "4fr 2fr 3fr 1fr",
    gap: 12,
  },
});

export const EarnExtra = ({ earnExtra, onChange, onDelete, disabled }: Props) => {
  const { Field, listenFields, submitForm } = useForm<EarnExtraState>({
    id: { initialValue: earnExtra.id },
    label: { initialValue: earnExtra.label, validate: validateRequired },
    amount: {
      initialValue: earnExtra.amount as number,
      validate: validateNumericNullableRequired,
    },
    type: { initialValue: earnExtra.type, validate: validateRequired },
    impactEarning: { initialValue: earnExtra.impactEarning ?? false },
    impactIrpf: { initialValue: earnExtra.impactIrpf ?? false },
  });

  const { addForm, addError } = useNestedForm();

  useEffect(() => {
    const removeListener = listenFields(["label", "amount", "impactIrpf", "impactEarning"], () =>
      submitForm(values => onChange(values as EarnExtraEmployee | EarnExtraPayroll)),
    );

    return () => removeListener();
  }, [listenFields, onChange, submitForm]);

  useEffect(() => {
    addForm(() => {
      submitForm(
        values => onChange(values as EarnExtraEmployee | EarnExtraPayroll),
        () => {
          addError("error");
        },
      );
    });
  }, []);

  return (
    <>
      <Field name="id">{() => null}</Field>
      <Field name="type">{() => null}</Field>

      <Box direction="row" alignItems="start" justifyContent="spaceBetween" style={styles.line}>
        <Field name="label">
          {({ value, onChange, error }) => (
            <LakeLabel
              label=""
              render={id => (
                <LakeTextInput
                  id={id}
                  value={value}
                  error={error}
                  onChangeText={onChange}
                  placeholder={t("common.label")}
                  disabled={disabled}
                />
              )}
            />
          )}
        </Field>

        <Field name="amount">
          {({ value, onChange, error }) => (
            <LakeLabel
              label={""}
              render={id => (
                <DecimalInput
                  id={id}
                  value={String(value)}
                  error={error}
                  onChangeDecimal={nextValue => {
                    onChange(Number(nextValue));
                  }}
                  placeholder={t("common.amount")}
                  disabled={disabled}
                />
              )}
            />
          )}
        </Field>

        <View>
          <Field name="impactEarning">
            {({ value, onChange }) => (
              <LakeLabelledCheckbox
                label={t("hr.impactEarning")}
                value={value}
                onValueChange={onChange}
                disabled={disabled}
              />
            )}
          </Field>

          <Field name="impactIrpf">
            {({ value, onChange }) => (
              <LakeLabelledCheckbox
                label={t("hr.impactIrpf")}
                value={value}
                onValueChange={onChange}
                disabled={disabled}
              />
            )}
          </Field>
        </View>

        <Button
          onPress={onDelete}
          mode="tertiary"
          icon={<Icon name="delete-regular" size={16} />}
          reverse={true}
          disabled={disabled}
        />
      </Box>
    </>
  );
};
