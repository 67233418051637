import { Box } from "@swan-io/lake/src/components/Box";
import { StyleSheet, Text, View, ViewStyle } from "react-native";
import { borderColorVariants, fontColorVariants } from "../styles/constants";
import { formatCurrency, t } from "../utils/i18n";
import { Button } from "./forms/Button";

const styles = StyleSheet.create({
  item: {
    paddingRight: 16,
    paddingVertical: 16,
    borderRightWidth: 1,
    borderStyle: "dotted",
    borderRightColor: borderColorVariants.gray500,
  },
  row: {
    display: "grid",
    gridTemplateColumns: "1.6fr .4fr",
    minHeight: 58,
  },
  text: {
    fontSize: 16,
    fontWeight: "500",
    color: fontColorVariants.white,
    marginBottom: 4,
  },
  model: {
    fontSize: 14,
    fontWeight: "500",
    color: fontColorVariants.white,
  },
  subtext: {
    fontSize: 12,
    color: fontColorVariants.gray400,
    marginVertical: 16,
  },
  total: {
    fontSize: 16,
    fontWeight: "500",
    color: fontColorVariants.primary200,
    textAlign: "right",
  },
  button: {
    borderColor: borderColorVariants.white,
    color: fontColorVariants.white,
  },
});

type Props = {
  title: string;
  subtitle: string;
  total: number;
  text: string;
  onPress: () => void;
  style?: ViewStyle;
};

export const SummaryModel = ({ title, subtitle, total = 0, text, onPress, style }: Props) => {
  return (
    <View style={[styles.item, style]}>
      <Box direction="row" justifyContent="spaceBetween" style={styles.row}>
        <View>
          <Text style={styles.text}>{title}</Text>
          <Text style={styles.model}>{subtitle}</Text>
        </View>

        <Text style={styles.total}>{formatCurrency(total, "EUR")}</Text>
      </Box>

      <Text style={styles.subtext}>{text}</Text>

      <Box alignItems="start">
        <Button
          mode="secondary"
          style="outlined"
          size="small"
          custom={styles.button}
          onPress={onPress}
        >
          {t("common.viewDetail")}
        </Button>
      </Box>
    </View>
  );
};
