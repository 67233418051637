import { AutoWidthImage } from "@swan-io/lake/src/components/AutoWidthImage";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { StyleSheet, View, ViewStyle } from "react-native";
import { fontColorVariants } from "../../../styles/constants";
import { getAlign } from "../../../utils/utils";

type ColumnHeaderProps = {
  text: string;
  align?: "center" | "left" | "right";
  icon?: string;
  style?: ViewStyle;
};

const styles = StyleSheet.create({
  cell: {
    display: "flex",
    paddingHorizontal: 8,
    flexGrow: 1,
    flexDirection: "row",
    alignItems: "center",
    width: 1,
  },
  icon: {
    marginRight: 8,
  },
  text: {
    fontWeight: "600",
    fontSize: 12,
    color: fontColorVariants.gray500,
  },
});

export const ColumnHeader = ({ text, align, icon, style }: ColumnHeaderProps) => {
  return (
    <View
      style={{
        ...styles.cell,
        ...style,
        justifyContent: getAlign(align) as
          | "center"
          | "flex-start"
          | "flex-end"
          | "space-between"
          | "space-around"
          | "space-evenly",
      }}
    >
      {icon != null && <AutoWidthImage sourceUri={icon} height={20} style={styles.icon} />}

      <LakeText align="center" variant="smallMedium" style={[styles.text, style]}>
        {text}
      </LakeText>
    </View>
  );
};
