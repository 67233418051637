import { Heading } from "@components/Heading";
import { Sidebar } from "@components/Sidebar";
import { InputRounded } from "@components/forms/Input";
import { Option } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeSearchField } from "@swan-io/lake/src/components/LakeSearchField";
import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { Space } from "@swan-io/lake/src/components/Space";
import { WithPartnerAccentColor } from "@swan-io/lake/src/components/WithPartnerAccentColor";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { useResponsive } from "@swan-io/lake/src/hooks/useResponsive";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { DatePicker } from "@swan-io/shared-business/src/components/DatePicker";
import { SkipToContent } from "@swan-io/shared-business/src/components/SkipToContent";
import { useEffect, useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { match } from "ts-pattern";
import { ProfitAndLossDocument, ProfitAndLossOutput } from "../../../graphql/crm";
import { encodeDate, encodeDateISO, getToday } from "../../../utils/date";
import { locale, t } from "../../../utils/i18n";
import { Router } from "../../../utils/routes";
import { COLORS } from "../../../values/colors";
import { ProfitAndLossAnalysis } from "../components/ProfitAndLossAnalysis";
import { ProfitAndLossReport } from "../components/ProfitAndLossReport";

const styles = StyleSheet.create({
  background: {
    flexShrink: 1,
    flexGrow: 1,
    backgroundColor: COLORS.BACKGROUND,
  },
  container: {
    flexShrink: 1,
    flexGrow: 1,
  },
  desktopContainer: {
    flexDirection: "row",
    width: "100%",
    marginHorizontal: "auto",
  },
  root: {
    ...commonStyles.fill,
    paddingTop: 24,
  },
  header: {
    paddingHorizontal: 24,
    paddingBottom: 12,
    borderBottomWidth: 1,
    borderBottomColor: COLORS.WHITE,
  },
  heading: {
    zIndex: 1,
  },
  filters: {
    gap: 16,
  },
  margin: {
    marginRight: 24,
  },
  date: {
    width: 175,
  },
});

export const ProfitAndLossArea = () => {
  const { desktop } = useResponsive();

  const firstDayOfYear = `${new Date().getFullYear()}-01-01`;

  const [dates, setDates] = useState({
    dateFrom: encodeDateISO(firstDayOfYear),
    dateTo: getToday(),
  });

  useEffect(() => {
    Router.push("ReportsProfitAndLossRoot", {
      dateFrom: encodeDate(dates.dateFrom),
      dateTo: encodeDate(dates.dateTo),
    });
  }, [dates]);

  const route = Router.useRoute(["ReportsProfitAndLossRoot"]);
  const params = match(route)
    .with({ name: "ReportsProfitAndLossRoot" }, ({ params }) => params)
    .otherwise(() => {});

  const { data: summaryData } = useUrqlQuery({
    query: ProfitAndLossDocument,
    variables: {
      filters: {
        dateRange: { gte: params?.dateFrom, lte: params?.dateTo },
      },
    },
  });

  const summary = summaryData
    .toOption()
    .flatMap(result => result.toOption())
    .flatMap(row => Option.fromNullable(row.profitAndLoss))
    .getWithDefault({} as ProfitAndLossOutput);

  return (
    <WithPartnerAccentColor color="#498AF2">
      <SkipToContent />

      <View style={styles.background}>
        <View style={[styles.container, desktop && styles.desktopContainer]}>
          {desktop && <Sidebar />}

          <ScrollView>
            <View style={styles.root}>
              <View style={commonStyles.fill} role="main">
                <Box
                  direction="row"
                  alignItems="center"
                  justifyContent="spaceBetween"
                  style={styles.header}
                >
                  <InputRounded>
                    <LakeSearchField
                      placeholder={t("common.search")}
                      initialValue={""}
                      onChangeText={() => {}}
                    />
                  </InputRounded>
                </Box>

                <ResponsiveContainer breakpoint={breakpoints.medium}>
                  {({ large }) => (
                    <Box
                      direction={large ? "row" : "column"}
                      alignItems="center"
                      justifyContent="spaceBetween"
                      style={styles.heading}
                    >
                      <Heading title={t("reports.profitAndLoss")} />

                      <View>
                        <Space height={12} />

                        <Box direction="row" style={[styles.filters, large && styles.margin]}>
                          <View style={styles.date}>
                            <DatePicker
                              format={locale.dateFormat}
                              firstWeekDay={locale.firstWeekday}
                              value={dates.dateFrom}
                              onChange={dateFrom => {
                                setDates({ ...dates, dateFrom });
                              }}
                              label={t("common.from")}
                            />
                          </View>

                          <View style={styles.date}>
                            <DatePicker
                              format={locale.dateFormat}
                              firstWeekDay={locale.firstWeekday}
                              value={dates.dateTo}
                              onChange={dateTo => {
                                setDates({ ...dates, dateTo });
                              }}
                              label={t("common.to")}
                            />
                          </View>
                        </Box>
                      </View>
                    </Box>
                  )}
                </ResponsiveContainer>

                <ProfitAndLossAnalysis summary={summary} />
                <ProfitAndLossReport summary={summary} />
              </View>
            </View>
          </ScrollView>
        </View>
      </View>
    </WithPartnerAccentColor>
  );
};
