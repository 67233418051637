import { Box } from "@swan-io/lake/src/components/Box";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { InputModeOptions, StyleSheet, Text } from "react-native";
import { COLORS } from "../../values/colors";

const styles = StyleSheet.create({
  input: {
    borderRadius: 8,
    borderColor: COLORS.NEUTRAL200,
  },
  multiLineHeight: {
    height: 200,
    marginBottom: 16,
  },
  rounded: {
    "--color-current-primary": COLORS.NEUTRAL700,
    "--color-gray-100": COLORS.WHITE,
    "--radius-6": 25,
    borderRadius: 25,
  },
  helpText: {
    fontSize: 10,
  },
  red: {
    color: COLORS.DESTRUCTIVE500,
  },
});

type Props = {
  label: string;
  placeholder?: string;
  help?: string | undefined;
  inputMode?: InputModeOptions;
  secureTextEntry?: boolean;
  multiline?: boolean;
  customOnChange?: (value: string) => void;
  maxLength?: number | undefined;
  required?: boolean;
  customValue?: string;
  disabled?: boolean;
};

type Props2 = {
  value: string;
  valid: boolean;
  error: string | undefined;
  help?: string | undefined;
  onChange: (value: string) => void;
};

type LabelInputProps = {
  label: string;
  value?: string;
  valid?: boolean;
  error?: string | undefined;
  help?: string | undefined;
  multiline?: boolean;
  onChange?: (value: string) => void;
  placeholder?: string;
  inputMode?: InputModeOptions;
  secureTextEntry?: boolean;
  maxLength?: number | undefined;
  required?: boolean;
  disabled?: boolean;
};

export default function Input({
  label,
  placeholder,
  inputMode,
  secureTextEntry,
  help,
  multiline,
  customValue,
  customOnChange,
  maxLength,
  required = false,
  disabled = false,
}: Props) {
  return ({ value, error, onChange }: Props2) => (
    <LabelInput
      label={label}
      value={customValue ?? value}
      error={error}
      multiline={multiline}
      help={help}
      disabled={disabled}
      onChange={customOnChange ? customOnChange : onChange}
      placeholder={placeholder}
      inputMode={inputMode}
      secureTextEntry={secureTextEntry}
      maxLength={maxLength as number}
      required={required}
    />
  );
}

export const LabelInput = ({
  label,
  value,
  error,
  help,
  multiline = false,
  onChange,
  placeholder,
  inputMode,
  secureTextEntry,
  maxLength,
  required = false,
  disabled,
}: LabelInputProps) => (
  <LakeLabel
    label={label}
    extra={() => (required ? <Text style={styles.red}>*</Text> : <></>)}
    render={id => (
      <>
        <LakeTextInput
          id={id}
          value={value}
          help={help}
          error={error}
          placeholder={placeholder}
          multiline={multiline}
          onChangeText={onChange}
          inputMode={inputMode}
          maxLength={maxLength}
          secureTextEntry={secureTextEntry}
          hideErrors={error == undefined}
          style={[styles.input, multiline && styles.multiLineHeight]}
          disabled={disabled}
        />

        {help != null && (
          <LakeText variant="smallRegular" color={"gray"} style={styles.helpText}>
            {help}
          </LakeText>
        )}
      </>
    )}
  />
);

export const InputRounded = ({ children }: { children: React.ReactNode }) => (
  <Box style={styles.rounded}>{children}</Box>
);
