import { Box } from "@swan-io/lake/src/components/Box";
import { StyleSheet, Text } from "react-native";
import { common } from "../../../styles/common";
import { borderColorVariants, fontColorVariants } from "../../../styles/constants";
import { formatCurrency, t } from "../../../utils/i18n";
import { Concept } from "../types";

const styles = StyleSheet.create({
  column: {
    flexBasis: "calc(50% - 12px)",
    gap: 6,
  },
  gap: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    width: "80%",
    gap: 12,
  },
  resume: {
    fontSize: 14,
    fontWeight: "400",
    color: fontColorVariants.neutral500,
  },
  total: {
    borderTopWidth: 1,
    borderTopColor: borderColorVariants.gray400,
    marginTop: 6,
    paddingTop: 6,
  },
  totalText: {
    fontSize: 18,
    fontWeight: "500",
    color: fontColorVariants.neutral600,
  },
});

type Props = {
  concept: Concept;
};

export const ConceptTotal = ({ concept }: Props) => {
  const hasDiscount = concept?.discountAmount > 0;
  const vatAmount = concept?.vatAmount ?? 0;
  const subtotal = concept?.subtotal ?? 0;
  const total = concept?.total ?? 0;
  const recargo = concept?.recargoAmount ?? 0;

  const discountAmount = concept?.discountAmount ?? 0;
  const totalIrpf = concept?.irpfAmount != null ? Number(concept?.irpfAmount) : 0;

  return (
    <Box alignItems="end" style={styles.column}>
      <Line label={t("invoices.subtotal")} value={subtotal} />

      {hasDiscount && <Line label={t("invoices.totalDiscount")} value={-discountAmount} />}

      <Line label={t("invoices.taxBase")} value={concept?.taxBase} />
      <Line label={t("invoices.iva")} value={vatAmount} />

      {concept?.recargoPercentage !== undefined ? (
        <Line label={t("invoices.recargo")} value={recargo} />
      ) : null}

      <Line label={t("invoices.totalIrpf")} value={-totalIrpf} />

      <Box direction="row" style={[styles.gap, styles.total]}>
        <Text style={styles.totalText}>{t("invoices.total")}</Text>

        <Text style={[styles.totalText, common.textRight]}>
          {formatCurrency(Number(total), "EUR")}
        </Text>
      </Box>
    </Box>
  );
};

const Line = ({ label, value }: { label: string; value: unknown }) => (
  <Box direction="row" style={styles.gap}>
    <Text style={styles.resume}>{label}</Text>

    <Text style={[styles.resume, common.textRight]}>
      {formatCurrency(Number(value ?? 0), "EUR")}
    </Text>
  </Box>
);
