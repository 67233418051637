import { Calendar } from "@components/Calendar";
import { Heading } from "@components/Heading";
import { Option } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import {
  FixedListViewEmpty,
  PlainListViewPlaceholder,
} from "@swan-io/lake/src/components/FixedListView";
import { LakeSearchField } from "@swan-io/lake/src/components/LakeSearchField";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { spacings } from "@swan-io/lake/src/constants/design";
import { useUrqlPaginatedQuery, useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { useCallback, useMemo, useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { toSnake } from "ts-case-convert";
import { match } from "ts-pattern";
import { ErrorView } from "../../../components/ErrorView";
import { Button, ButtonGroup } from "../../../components/forms/Button";
import { InputRounded } from "../../../components/forms/Input";
import {
  InputMaybe,
  PurchaseCategory,
  PurchaseInvoiceFilterStaticDocument,
  PurchaseInvoiceRelay,
  PurchaseInvoicesDocument,
  PurchaseStatus,
} from "../../../graphql/crm";
import { ManageParamsOauthSwan } from "../../../utils/auth";
import { encodeDate } from "../../../utils/date";
import { t } from "../../../utils/i18n";
import { Router } from "../../../utils/routes";
import { COLORS } from "../../../values/colors";
import { useExport } from "../hooks/useExport";
import { extractPurchaseStatic } from "../utils";
import { PurchaseCreate } from "./PurchaseCreate";
import { PurchaseDetailsList } from "./PurchaseDetailsList";
import { InvoiceFiltersState, PurchaseListFilter } from "./PurchaseListFilter";
import { PurchaseStats } from "./PurchaseStats";

const styles = StyleSheet.create({
  root: {
    ...commonStyles.fill,
    paddingTop: spacings[24],
  },
  header: {
    paddingHorizontal: spacings[24],
    paddingBottom: spacings[12],
    borderBottomWidth: 1,
    borderBottomColor: COLORS.WHITE,
  },
  container: {
    backgroundColor: COLORS.WHITE,
    padding: 8,
  },
  filters: {
    position: "relative",
    zIndex: 1,
    gap: 12,
    paddingBottom: 8,
  },
  calendar: {
    right: 0,
  },
});

const NUM_TO_RENDER = 20;

export const PurchaseList = () => {
  ManageParamsOauthSwan("InvoicesPurchaseList");
  const route = Router.useRoute(["InvoicesPurchaseList"]);
  const params = match(route)
    .with({ name: "InvoicesPurchaseList" }, ({ params }) => params)
    .otherwise(() => {});

  const filters: InvoiceFiltersState = useMemo(() => {
    return {
      search: params?.search,
      isAfterEmitedAt: params?.isAfterEmitedAt,
      isBeforeEmitedAt: params?.isBeforeEmitedAt,
      status: params?.status,
      category: params?.category,
    } as InvoiceFiltersState;
  }, [
    params?.search,
    params?.isAfterEmitedAt,
    params?.isBeforeEmitedAt,
    params?.status,
    params?.category,
  ]);

  const sort = useMemo(() => {
    return {
      field: params?.sortBy ?? "issue_date",
      direction: params?.sortOrder ?? "-",
    };
  }, [params?.sortBy, params?.sortOrder]);

  const { data: staticData } = useUrqlQuery({ query: PurchaseInvoiceFilterStaticDocument }, []);

  const categoryItems = extractPurchaseStatic(
    staticData,
    "category",
  ) as unknown as PurchaseCategory[];
  const statusItems = extractPurchaseStatic(staticData, "statusType");

  const { data, nextData, reload, isForceReloading, setAfter } = useUrqlPaginatedQuery(
    {
      query: PurchaseInvoicesDocument,
      variables: {
        first: NUM_TO_RENDER,
        filters: {
          status: { inList: params?.status as InputMaybe<PurchaseStatus[]> },
          category: { inList: params?.category },
          search: params?.search,
          issueDate: { gte: params?.isAfterEmitedAt, lte: params?.isBeforeEmitedAt },
        },
        orderBy: `${sort.direction}${sort.field}`,
      },
    },
    [filters, sort],
  );

  const [activeInvoiceId, setActiveInvoiceId] = useState<string | null>(null);
  const [invoice, setInvoice] = useState<PurchaseInvoiceRelay | undefined>(undefined);

  const onActiveRowChange = useCallback(() => {}, []);

  const invoices = data
    .toOption()
    .flatMap(data => data.toOption())
    .flatMap(({ purchaseInvoices }) => Option.fromNullable(purchaseInvoices))
    .map(({ edges }) => edges.map(({ node }) => node))
    .getWithDefault([]);

  const onViewDetails = useCallback(
    (currentId: string) => {
      setInvoice(invoices.filter(({ id }) => id === currentId)?.[0]);
      Router.push("InvoicesPurchaseList", { ...filters, visible: "1" });
    },
    [filters, invoices],
  );

  const { handleExportInvoices } = useExport();

  const dateFrom = params?.isAfterEmitedAt != null ? new Date(params?.isAfterEmitedAt) : undefined;
  const dateTo = params?.isBeforeEmitedAt != null ? new Date(params?.isBeforeEmitedAt) : undefined;

  const onChangeDates = ({ dateFrom, dateTo }: { dateFrom: string; dateTo: string }) =>
    Router.push("InvoicesPurchaseList", {
      ...filters,
      isAfterEmitedAt: encodeDate(dateFrom),
      isBeforeEmitedAt: encodeDate(dateTo),
    });

  return (
    <View style={styles.root}>
      <View style={commonStyles.fill} role="main">
        <Box
          direction="row"
          alignItems="center"
          justifyContent="spaceBetween"
          style={styles.header}
        >
          <InputRounded>
            <LakeSearchField
              placeholder={t("common.search")}
              initialValue={""}
              onChangeText={search =>
                Router.push("InvoicesPurchaseList", {
                  ...filters,
                  search,
                })
              }
            />
          </InputRounded>

          <ButtonGroup>
            <Button
              style="group"
              reverse={true}
              onPress={() => Router.push("InvoicesPurchaseCreate")}
            >
              {t("invoices.createPurchase")}{" "}
            </Button>
          </ButtonGroup>
        </Box>

        <Heading title={t("invoices.purchases")}>
          <PurchaseStats
            forceReload={isForceReloading}
            search={params?.search}
            gte={params?.isAfterEmitedAt}
            lte={params?.isBeforeEmitedAt}
          />
        </Heading>

        <Box style={styles.container}>
          <Box direction="row" justifyContent="end" alignItems="center" style={styles.filters}>
            <Calendar
              dateFrom={dateFrom}
              dateTo={dateTo}
              setDates={onChangeDates}
              style={styles.calendar}
            />

            <PurchaseListFilter
              filters={filters}
              categoryItems={categoryItems}
              statusItems={statusItems}
              onChange={filters => Router.push("InvoicesPurchaseList", filters)}
            />

            <Button
              mode="tertiary"
              style="outlined"
              onPress={() => {
                handleExportInvoices({
                  dateFrom: params?.isAfterEmitedAt?.split("T")[0],
                  dateTo: params?.isBeforeEmitedAt?.split("T")[0],
                });
              }}
            >
              {t("common.export")}
            </Button>
          </Box>

          {data.match({
            NotAsked: () => null,
            Loading: () => (
              <PlainListViewPlaceholder
                count={NUM_TO_RENDER}
                rowVerticalSpacing={0}
                headerHeight={48}
                rowHeight={48}
              />
            ),
            Done: result =>
              result.match({
                Error: error => <ErrorView error={error} />,
                Ok: data => (
                  <PurchaseDetailsList
                    invoices={invoices}
                    setActiveInvoice={setInvoice}
                    onActiveRowChange={onActiveRowChange}
                    activeRowId={activeInvoiceId ?? undefined}
                    onRefreshRequest={reload}
                    onEndReached={() => {
                      if (data?.purchaseInvoices.pageInfo.hasNextPage === true) {
                        setAfter(data?.purchaseInvoices?.pageInfo.endCursor ?? undefined);
                      }
                    }}
                    loading={{ isLoading: nextData.isLoading(), count: NUM_TO_RENDER }}
                    getRowLink={({ item }) => (
                      <Pressable
                        onPress={() => {
                          setActiveInvoiceId(item.id as string);
                          onViewDetails(item.id as string);
                        }}
                      />
                    )}
                    renderEmptyList={() => (
                      <FixedListViewEmpty
                        icon="lake-transfer"
                        borderedIcon={true}
                        title={t("common.empty")}
                      />
                    )}
                    sortBy={`${sort.direction}${sort.field}`}
                    setSort={columnId => {
                      const sortBy = toSnake(columnId);
                      const sortOrder = sort.field === sortBy && sort.direction === "-" ? "" : "-";
                      Router.push("InvoicesPurchaseList", {
                        ...filters,
                        sortBy,
                        sortOrder,
                      });
                    }}
                  />
                ),
              }),
          })}
        </Box>
      </View>

      <PurchaseCreate
        visible={params?.visible !== undefined}
        invoice={invoice}
        onRefreshRequest={reload}
      />
    </View>
  );
};
