import { HorizonalValues } from "@components/HorizontalValues";
import { Bar } from "@components/chart/Bar";
import { Option } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { useUrqlPaginatedQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { StyleSheet } from "react-native";
import statsImage from "../../../assets/img/payroll-stats.webp";
import { PayrollStatsDocument, PayrollStatus } from "../../../graphql/crm";
import { backgroundColorVariants, borderColorVariants } from "../../../styles/constants";
import { toNumber } from "../../../utils/decimal";
import { formatCurrency, t } from "../../../utils/i18n";
import { PayrollFiltersState } from "./PayrollListFilter";

const styles = StyleSheet.create({
  container: {
    marginTop: 20,
  },
  desktop: {
    display: "grid",
    gridTemplateColumns: "1.1fr .9fr",
    gap: 12,
  },
  mobile: {
    gap: 12,
  },
  box: {
    flexWrap: "wrap",
    borderRadius: 25,
    borderWidth: 2,
    borderColor: borderColorVariants.white,
    boxShadow:
      "0 67.609px 54.087px 0 rgba(201, 201, 201, 0.07), 0 28.246px 22.596px 0 rgba(108, 73, 172, 0.05), 0 15.101px 12.081px 0 rgba(108, 73, 172, 0.04), 0 8.466px 6.773px 0 rgba(108, 73, 172, 0.04), 0 4.496px 3.597px 0 rgba(108, 73, 172, 0.03), 0 1.871px 1.497px 0 rgba(108, 73, 172, 0.02)",
    paddingVertical: 16,
    paddingHorizontal: 32,
    backgroundImage: "linear-gradient(194deg, #4B4B4B, #262626)",
  },
});

type Props = {
  forceReload?: boolean;
  filters?: PayrollFiltersState;
};

export const PayrollStats = ({ forceReload = false, filters }: Props) => {
  const { data: stats } = useUrqlPaginatedQuery(
    {
      query: PayrollStatsDocument,
      variables: {
        filters: {
          search: filters?.search,
          status: { inList: filters?.status as PayrollStatus[] },
          issueDate: { gte: filters?.isAfterEmitedAt, lte: filters?.isBeforeEmitedAt },
        },
      },
    },
    [forceReload, filters],
  );

  const { labels, label1, dataset1, label2, dataset2, count, total1, total2 } = stats
    .toOption()
    .flatMap(result => result.toOption())
    .flatMap(row => Option.fromNullable(row.payrollStats))
    .map(({ labels, label1, dataset1, label2, dataset2, count, total1, total2 }) => {
      return {
        labels,
        label1,
        dataset1,
        label2,
        dataset2,
        count,
        total1,
        total2,
      };
    })
    .getWithDefault({
      labels: [],
      label1: "",
      dataset1: [],
      label2: "",
      dataset2: [],
      count: 0,
      total1: 0,
      total2: 0,
    });

  return (
    <ResponsiveContainer>
      {({ large }) => (
        <Box style={styles.container}>
          <HorizonalValues
            data={[
              {
                title: t("hr.total"),
                value: count.toString(),
              },
              {
                title: t("hr.totalPaid"),
                value: formatCurrency(total1 as number, "EUR"),
              },
              {
                title: t("hr.totalPending"),
                value: formatCurrency(total2 as number, "EUR"),
              },
            ]}
            image={statsImage}
            imageHeight={150}
            style={{ backgroundColor: backgroundColorVariants.dark2 }}
          />

          <Box style={[!large && styles.mobile, large && styles.desktop]}>
            <Box style={styles.box}>
              <Bar
                labels={labels}
                datasets={[
                  {
                    label: label1,
                    data: dataset1.map(value => toNumber(value as string)),
                    borderColor: "#0A84FF",
                    backgroundColor: "#0A84FF",
                  },
                  {
                    label: label2,
                    data: dataset2.map(value => toNumber(value as string)),
                    borderColor: "#64D2FF",
                    backgroundColor: "#64D2FF",
                  },
                ]}
              />
            </Box>

            <Box
              direction="row"
              alignItems="center"
              justifyContent="spaceBetween"
              style={styles.box}
            >
              <></>
            </Box>
          </Box>
        </Box>
      )}
    </ResponsiveContainer>
  );
};
