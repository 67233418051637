import { Box } from "@swan-io/lake/src/components/Box";
import { StyleSheet, Text } from "react-native";
import { Employee } from "../../../graphql/crm";
import { backgroundColorVariants, fontColorVariants } from "../../../styles/constants";

const styles = StyleSheet.create({
  avatar: {
    width: 68,
    height: 68,
    backgroundColor: backgroundColorVariants.neutral200,
    borderRadius: 50,
  },
  text: {
    fontSize: 26,
    color: fontColorVariants.neutral500,
  },
});

type Props = {
  employee?: Employee;
};

export const EmployeeAvatar = ({ employee }: Props) => {
  return (
    <Box alignItems="center" justifyContent="center" style={styles.avatar}>
      <Text style={styles.text}>{employee?.name.split("").at(0)}</Text>
    </Box>
  );
};
