import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { colors, spacings } from "@swan-io/lake/src/constants/design";
import { StyleSheet, View } from "react-native";
import { encodeDateISO } from "../../utils/date";
import { getAlign } from "../../utils/utils";

const styles = StyleSheet.create({
  cell: {
    display: "flex",
    paddingHorizontal: spacings[8],
    flexGrow: 1,
    flexDirection: "row",
    alignItems: "center",
    width: 1,
  },
  paddedCell: {
    paddingVertical: spacings[12],
    minHeight: 72,
  },
});

export const DateCell = ({
  date,
  align,
}: {
  date: string;
  align?: "left" | "right" | "center";
}) => {
  return (
    <View
      style={{
        ...styles.cell,
        ...styles.paddedCell,
        justifyContent: getAlign(align as "left" | "right" | "center") as
          | "center"
          | "flex-start"
          | "flex-end"
          | "space-between"
          | "space-around"
          | "space-evenly",
      }}
    >
      <LakeText align="right" variant="smallMedium" color={colors.gray[600]}>
        {encodeDateISO(date)}
      </LakeText>
    </View>
  );
};
