type Colors = { [key: string]: string };

export const COLORS: Colors = {
  BACKGROUND: "#EBEBEB",
  WHITE: "#FFFFFF",
  PRIMARY50: "var(--color-current-50)",
  PRIMARY100: "var(--color-current-100)",
  PRIMARY300: "var(--color-current-300)",
  PRIMARY500: "var(--color-current-500)",
  NEUTRAL50: "#F9FAFB",
  NEUTRAL100: "#F3F4F6",
  NEUTRAL200: "#E5E7EB",
  NEUTRAL300: "#D1D5DB",
  NEUTRAL400: "#9CA3AF",
  NEUTRAL500: "#6B7280",
  NEUTRAL700: "#374151",
  NEUTRAL800: "#1F2937",
  NEUTRAL900: "#111827",
  GRAY600: "#5C5A5F",
  GRAY700: "#262626",
  GRAY900: "#383A3B",
  SIDEBAR: "#F4F4F4",
  SUCCESS100: "#DCFCE7",
  SUCCESS800: "#166534",
  DESTRUCTIVE100: "#FEE2E2",
  DESTRUCTIVE500: "#EF4444",
  DESTRUCTIVE800: "#991B1B",
};
