import { DecimalInput } from "@components/DecimalInput";
import { Button } from "@components/forms/Button";
import Select from "@components/forms/Select";
import { Option } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { Space } from "@swan-io/lake/src/components/Space";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { useEffect, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { useForm } from "react-ux-form";
import { Product, ProductsDocument, SalesInvoiceCreateStaticDocument } from "../../../graphql/crm";
import {
  backgroundColorVariants,
  borderColorVariants,
  fontColorVariants,
} from "../../../styles/constants";
import { t } from "../../../utils/i18n";
import { validateNumericNullableRequired, validateRequired } from "../../../utils/validations";
import { Concept, CreateSalesConceptState } from "../types";
import { extractSalesStatic } from "../utils";
import { ConceptTotal } from "./ConceptTotal";
import { ProductCreate } from "./ProductCreate";

const styles = StyleSheet.create({
  line: {
    backgroundColor: backgroundColorVariants.neutral50,
    borderWidth: 1,
    borderColor: borderColorVariants.neutral200,
    borderRadius: 8,
    padding: 12,
  },
  red: {
    color: fontColorVariants.destructive500,
  },
  column: {
    flexBasis: "calc(50% - 12px)",
  },
  actions: {
    flexDirection: "column",
    alignItems: "stretch",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
    gap: 12,
  },
  gap: {
    gap: 8,
  },
  options: {
    height: 0,
    overflow: "hidden",
    transitionDuration: "0.3s",
    transitionProperty: "height",
  },
  optionsOpened: {
    height: "180px",
  },
  moreOptions: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },
});

type Props = {
  large?: boolean;
  concept: Concept;
  concepts: Concept[];
  onChange: (concept: Concept) => void;
  onDelete: () => void;
};

export const RectificationConceptItem = ({
  large = true,
  concept,
  concepts,
  onChange,
  onDelete,
}: Props) => {
  const [showProductCreate, setShowProductCreate] = useState(false);

  const [moreOptions, setMoreOptions] = useState(false);

  const isSuplido = concept.expenseType === "suplido";

  const validateNegativePrice = (value: string, originalValue: string) => {
    const inputValue = parseFloat(value);
    const originalValueNegative = parseFloat(originalValue);
    let errorText = "";
    if (isNaN(inputValue)) {
      errorText = t("invoices.rectify.error.number");
    }

    if (inputValue > 0) {
      errorText = t("invoces.rectify.error.negative");
    }

    if (inputValue < originalValueNegative) {
      errorText = t("invoices.rectify.error.lessThan");
    }

    if (inputValue === 0) {
      errorText = t("invoices.rectify.error.zero");
    }

    return errorText;
  };

  const validateQuantity = (value: string, originalValue: string) => {
    const inputValue = parseFloat(value);
    const originalValueNegative = parseFloat(originalValue);
    let errorText = "";
    if (isNaN(inputValue)) {
      errorText = t("invoices.rectify.error.number");
    }

    if (inputValue < 0) {
      errorText = t("invoices.rectify.error.positive");
    }

    if (inputValue > originalValueNegative) {
      errorText = t("invoices.rectify.error.lessThan");
    }

    if (inputValue === 0) {
      errorText = t("invoices.rectify.error.zero");
    }

    return errorText;
  };

  const { Field, setFieldValue, getFieldState, listenFields, submitForm } =
    useForm<CreateSalesConceptState>({
      id: { initialValue: concept.id },
      productId: {
        initialValue: () => {
          if (concept.productId === -1) {
            concept.productId = undefined;
            return -1;
          } else {
            return concept.productId;
          }
        },
        validate: validateNumericNullableRequired,
      },
      description: { initialValue: concept.description, validate: validateRequired },
      unitType: { initialValue: concept.unitType ?? "" },
      unitPrice: { initialValue: concept.unitPrice ?? "" },
      price: { initialValue: concept.price },
      vat: {
        initialValue: isSuplido ? "exento" : concept.vat ?? "",
        validate: !isSuplido ? validateRequired : undefined,
      },
      quantity: { initialValue: concept.quantity ?? 1, validate: validateNumericNullableRequired },
      irpf: { initialValue: concept.irpf ?? "" },
      discountAmount: { initialValue: concept.discountAmount || 0 },
      discountPercentage: { initialValue: concept.discountPercentage || 0 },
      expenseType: { initialValue: concept.expenseType ?? "corriente" },
      type: { initialValue: concept.type ?? false },
      subtotal: { initialValue: concept.subtotal ?? "" },
      category: { initialValue: concept.category },
      subcategory: { initialValue: concept.subcategory },
    });

  useEffect(() => {
    setFieldValue("subtotal", concept.subtotal ?? "");
    setSubtotalAux(concept.subtotal ?? "");
  }, [concept.subtotal]);

  useEffect(() => {
    setFieldValue("unitPrice", concept.unitPrice ?? "");
    setUnitPriceAux(concept.unitPrice ?? "");
  }, [concept.unitPrice]);

  const { data } = useUrqlQuery({ query: SalesInvoiceCreateStaticDocument }, []);

  const { data: productsQuery, reload } = useUrqlQuery(
    {
      query: ProductsDocument,
      variables: {
        first: 100 as never,
        filters: {} as never,
      },
    },
    [],
  );

  const products = productsQuery
    .toOption()
    .flatMap(data => data.toOption())
    .flatMap(({ products }) => Option.fromNullable(products))
    .map(products => {
      if ("edges" in products && Array.isArray(products.edges)) {
        return products.edges.map(({ node }) => node as unknown as Product);
      } else if (Array.isArray(products)) {
        return products;
      }
      return [];
    })
    .getWithDefault([]) as Product[] | { id: number; description: string }[];

  const productsItems = products?.map(product => ({
    name: product.description,
    value: Number(product.id),
  }));
  productsItems.unshift({ name: "Manual", value: -1 });

  const irpfType = extractSalesStatic(data, "irpfType");
  const unitType = extractSalesStatic(data, "unitType");
  const vatType = extractSalesStatic(data, "vatType");

  useEffect(() => {
    const removeListenerProduct = listenFields(["productId"], ({ productId }) => {
      if (!products.length || concept.productId === productId?.value) {
        return;
      }
      const prod = products.filter(p => p.id === productId?.value?.toString())?.[0] as Product;
      if (prod !== undefined) {
        setFieldValue("description", prod.description ?? "");
        setFieldValue("unitType", prod.unitType ?? "");
        setFieldValue("unitPrice", prod.unitPrice as string);
        setFieldValue("vat", prod.vat);
        setFieldValue("irpf", prod.irpf ?? "");
        setFieldValue("subtotal", "");
      }
    });

    return () => {
      removeListenerProduct();
    };
  }, [products, concept.productId, listenFields, setFieldValue]);

  useEffect(() => {
    const removeListener = listenFields(
      ["productId", "description", "quantity", "unitType", "vat", "irpf"],
      () => {
        submitForm(values => {
          onChange(values as Concept);
        });
      },
    );

    return () => {
      removeListener();
    };
  }, [listenFields, onChange, submitForm]);

  const [unitPriceAux, setUnitPriceAux] = useState<string>(String(concept.unitPrice ?? ""));
  const [subtotalAux, setSubtotalAux] = useState<string>(String(concept.subtotal ?? ""));
  const [originalPrice] = useState<string>(String(concept.subtotal ?? ""));
  const [originalQuantity] = useState<string>(String(concept.quantity ?? 1));
  const [originalUnitPrice] = useState<string>(String(concept.unitPrice ?? ""));

  useEffect(() => {
    if (!unitPriceAux && !subtotalAux) {
      return;
    }

    setFieldValue("unitPrice", unitPriceAux);
    setFieldValue("subtotal", subtotalAux);

    submitForm(values => {
      onChange(values as Concept);
    });
  }, [unitPriceAux, subtotalAux]);

  return (
    <View style={styles.line}>
      <Field name="id">{() => null}</Field>
      <Field name="type">{() => null}</Field>
      <Field name="category">{() => null}</Field>
      <Field name="subcategory">{() => null}</Field>

      <Box
        direction={large ? "row" : "column"}
        alignItems={large ? "start" : "stretch"}
        justifyContent="spaceBetween"
        style={styles.grid}
      >
        <View>
          <Field name="description">
            {({ value, onChange, error }) => {
              const { value: productId } = getFieldState("productId");
              if (productId === -1) {
                return (
                  <LakeLabel
                    label={t("invoices.description")}
                    style={styles.column}
                    render={id => (
                      <LakeTextInput id={id} value={value} error={error} onChangeText={onChange} />
                    )}
                  />
                );
              }
              return <></>;
            }}
          </Field>

          <Field name="productId">
            {({ value, onChange, error }) => {
              if (value === -1) {
                return <></>;
              }
              return (
                <LakeLabel
                  label={t("invoices.product")}
                  style={[styles.column, styles.actions]}
                  render={() => (
                    <LakeSelect
                      disabled={true}
                      items={productsItems}
                      value={value}
                      hideErrors={error === undefined}
                      error={error}
                      onValueChange={onChange}
                    />
                  )}
                />
              );
            }}
          </Field>
        </View>

        <ProductCreate
          visible={showProductCreate}
          onPressClose={() => {
            setShowProductCreate(false);
            reload();
          }}
        />

        <Field name="quantity">
          {({ value, onChange, error }) => (
            <LakeLabel
              label={t("invoices.quantity")}
              style={styles.column}
              extra={() => <Text style={styles.red}>*</Text>}
              render={id => (
                <DecimalInput
                  id={id}
                  value={String(value)}
                  error={error}
                  onChangeDecimal={nextValue => {
                    const validationError = validateQuantity(nextValue, originalQuantity);
                    if (validationError) {
                      showToast({ title: validationError, variant: "error", autoClose: true });
                    } else {
                      setFieldValue("subtotal", "");
                      onChange(Number(nextValue));
                    }
                  }}
                />
              )}
            />
          )}
        </Field>

        <Field name="unitType">
          {Select({
            label: t("invoices.unitType"),
            items: unitType,
            disabled: true,
          })}
        </Field>

        <Field name="unitPrice">
          {({ error }) => (
            <LakeLabel
              label={t("invoices.unitPrice")}
              style={styles.column}
              extra={() => <Text style={styles.red}>*</Text>}
              render={id => (
                <DecimalInput
                  id={id}
                  value={unitPriceAux}
                  error={error}
                  onChangeDecimal={nextValue => {
                    const validationError = validateNegativePrice(nextValue, originalUnitPrice);
                    if (validationError) {
                      showToast({ title: validationError, variant: "error", autoClose: true });
                    } else {
                      setSubtotalAux("");
                      setUnitPriceAux(nextValue);
                    }
                  }}
                />
              )}
            />
          )}
        </Field>

        <Field name="subtotal">
          {({ error }) => (
            <LakeLabel
              label={t("invoices.price")}
              style={styles.column}
              extra={() => <Text style={styles.red}>*</Text>}
              render={id => (
                <DecimalInput
                  id={id}
                  value={subtotalAux}
                  error={error}
                  onChangeDecimal={nextValue => {
                    const validationError = validateNegativePrice(nextValue, originalPrice);
                    if (validationError) {
                      showToast({ title: validationError, variant: "error", autoClose: true });
                    } else {
                      setUnitPriceAux("");
                      setSubtotalAux(nextValue);
                    }
                  }}
                />
              )}
            />
          )}
        </Field>
      </Box>

      <Box
        direction={large ? "row" : "column"}
        alignItems={large ? "start" : "stretch"}
        justifyContent="spaceBetween"
        style={styles.grid}
      >
        <Field name="vat">
          {Select({
            label: t("invoices.iva"),
            items: vatType,
            required: !isSuplido,
            disabled: true,
          })}
        </Field>

        <Field name="irpf">
          {Select({
            label: t("invoices.irpf"),
            items: irpfType,
            disabled: true,
          })}
        </Field>
      </Box>

      <Box style={[styles.options, moreOptions && styles.optionsOpened]}>
        <Space height={24} />

        <Box style={styles.moreOptions}>
          <View></View>

          <View>
            <ConceptTotal concept={concept} />
          </View>
        </Box>
      </Box>

      <Space height={24} />

      <Box direction="row" justifyContent="end" style={styles.gap}>
        <Button
          onPress={() => setMoreOptions(!moreOptions)}
          mode="tertiary"
          icon={
            moreOptions ? (
              <Icon name="arrow-up-regular" size={16} />
            ) : (
              <Icon name="arrow-down-regular" size={16} />
            )
          }
          reverse={true}
        >
          {t("invoices.viewTotal")}
        </Button>

        <Button
          onPress={onDelete}
          mode="tertiary"
          disabled={concepts.length === 1}
          icon={<Icon name="delete-regular" size={16} />}
          reverse={true}
        >
          {t("invoices.removeItem")}
        </Button>
      </Box>
    </View>
  );
};
