import { FullViewportLayer } from "@swan-io/lake/src/components/FullViewportLayer";
import { LoadingView } from "@swan-io/lake/src/components/LoadingView";
import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { ScrollView, StyleSheet, View } from "react-native";
import { COLORS } from "../../../values/colors";
import { NestedFormProvider } from "../../components/NestedForm";
import { PayrollForm } from "../components/PayrollForm";
import { useGetEmployee } from "../hooks/useGetEmployee";
import { useGetPayroll } from "../hooks/useGetPayroll";

const styles = StyleSheet.create({
  gradient1: {
    position: "absolute",
    top: "50%",
    left: "0",
    transform: "translate(-60%, -80%)",
    opacity: 0.9,
    width: "50%",
    height: "70%",
    backgroundImage:
      "radial-gradient(33.64% 25.02% at 50% 50%, rgba(89, 226, 255, 0.22) 0%, rgba(108, 168, 243, 0.00) 100%)",
  },
  gradient2: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(0, -30%)",
    width: "50%",
    height: "70%",
    backgroundImage:
      "radial-gradient(67.22% 50% at 50% 50%, rgba(255, 100, 51, 0.15) 0%, rgba(245, 228, 255, 0.00) 100%)",
  },
  background: {
    position: "absolute",
    bottom: 0,
    backgroundColor: COLORS.WHITE,
    width: "100dvw",
    height: "50dvh",
  },
  container: {
    backgroundImage: COLORS.BACKGROUND,
  },
  grid: {
    display: "grid",
    placeContent: "center",
    width: "100dvw",
    minHeight: "100dvh",
    paddingVertical: 16,
    transitionProperty: "transform",
    transitionDuration: "500ms",
    transitionTimingFunction: "ease-in-out",
  },
});

type Props = {
  payrollId?: string;
  employeeId?: string;
};

export type FormValues = {
  speechToTextPrompt: string;
};

export const Payroll = ({ payrollId, employeeId }: Props) => {
  const payroll = useGetPayroll(payrollId);
  const employee = useGetEmployee(payroll?.employee?.id ?? employeeId);

  return (
    <ResponsiveContainer breakpoint={780}>
      {() => (
        <FullViewportLayer visible={true}>
          <ScrollView style={styles.container}>
            <View style={styles.gradient1} />
            <View style={styles.gradient2} />
            <View style={styles.background} />

            <View style={styles.grid}>
              <NestedFormProvider>
                {employee == null ? (
                  <LoadingView />
                ) : (
                  <PayrollForm payroll={payroll} employee={employee} />
                )}
              </NestedFormProvider>
            </View>
          </ScrollView>
        </FullViewportLayer>
      )}
    </ResponsiveContainer>
  );
};
