import { ColumnConfig, PlainListView } from "@components/PlainListView";
import { DefaultCell } from "@components/forms/DefaultCell";
import { LinkConfig } from "@swan-io/lake/src/components/FixedListView";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { ReactElement, ReactNode } from "react";
import { StyleSheet, ViewStyle } from "react-native";
import { CustomerAddress } from "../../../graphql/crm";
import { t } from "../../../utils/i18n";
import { COLORS } from "../../../values/colors";
import { ColumnHeader } from "../../invoices/components/ColumnHeader";
import KebabMenu from "./KebabMenu";

const styles = StyleSheet.create({
  header: {
    fontWeight: "600",
    "--color-gray-900": COLORS.WHITE,
    "--text-medium-font-size": 12,
  },
});

type Props = {
  data: CustomerAddress[];
  reload: () => void;
  loading?: { isLoading: boolean; count: number };
  onEndReached: () => void;
  onActiveRowChange: (element: HTMLElement) => void;
  onRefreshRequest: () => void;
  activeRowId?: string;
  renderEmptyList: () => ReactNode;
  getRowLink: (item: LinkConfig<CustomerAddress, ExtraInfo>) => ReactElement;
};
type ExtraInfo = undefined;

export const AddressesList = ({
  data,
  reload,
  loading,
  onEndReached,
  activeRowId,
  getRowLink,
  onActiveRowChange,
  renderEmptyList,
}: Props) => {
  const columns: ColumnConfig<CustomerAddress, ExtraInfo>[] = [
    {
      id: "address",
      width: "grow",
      title: t("contact.address"),
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => <DefaultCell data={item.address ?? ""} />,
    },
    {
      id: "locality",
      width: "grow",
      title: t("company.locality"),
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => <DefaultCell data={item.locality ?? ""} />,
    },
    {
      id: "postalCode",
      width: 100,
      title: t("contact.postalCode"),
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => <DefaultCell data={item.postalCode ?? ""} />,
    },
    {
      id: "default",
      width: 80,
      title: t("common.main"),
      renderTitle: ({ title }) => <ColumnHeader text={title} align="center" />,
      renderCell: ({ item }) => <DefaultCell data={item.default ? "✓" : ""} align="center" />,
    },
    {
      id: "options",
      width: 31,
      title: "",
      renderTitle: ({ title }) => <ColumnHeader text={title} align="center" />,
      renderCell: ({ item }) => <KebabMenu addressId={item.id} onRefreshRequest={reload} />,
    },
  ];

  const smallColumns: ColumnConfig<CustomerAddress, ExtraInfo>[] = columns.filter(c =>
    ["address", "postalCode", "default", "options"].includes(c.id),
  );

  return (
    <PlainListView
      withoutScroll={true}
      data={data}
      keyExtractor={item => item.id.toString()}
      headerHeight={36}
      rowHeight={63}
      headerStyle={styles.header as ViewStyle}
      headerBackgroundColor="#EDEDED"
      groupHeaderHeight={36}
      extraInfo={undefined}
      columns={columns}
      smallColumns={smallColumns}
      activeRowId={activeRowId}
      onActiveRowChange={onActiveRowChange}
      loading={loading}
      onEndReached={onEndReached}
      getRowLink={getRowLink}
      renderEmptyList={renderEmptyList}
      breakpoint={breakpoints.tiny}
    />
  );
};
