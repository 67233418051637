import { LakeLabelledCheckbox } from "@swan-io/lake/src/components/LakeCheckbox";
import { Pressable } from "@swan-io/lake/src/components/Pressable";
import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  checkbox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    gap: 6,
  },
});

type Props = {
  value: boolean;
  error: string | undefined;
  onChange: (value: boolean) => void;
};

export default function Checkbox({ children }: { children: React.ReactNode }) {
  return ({ value, error, onChange }: Props) => (
    <Pressable style={styles.checkbox}>
      <LakeLabelledCheckbox
        value={value}
        onValueChange={onChange}
        label=""
        isError={error != undefined}
      />

      {children}
    </Pressable>
  );
}
