import Chart, { ChartDataset } from "chart.js/auto";
import { useEffect, useRef, useState } from "react";

type Props = {
  labels: string[];
  datasets: ChartDataset[];
};

export const Bar = ({ labels = [], datasets = [] }: Props) => {
  const chartRef = useRef(null);

  const [chart, setChart] = useState<Chart | null>(null);

  const style = {
    fill: true,
    borderWidth: 2,
    pointBorderWidth: 0,
    tension: 0.4,
  };

  useEffect(() => {
    if (chartRef.current == null) {
      return;
    }

    const chart = new Chart(chartRef.current, {
      type: "bar",
      data: {
        labels,
        datasets: [],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            stacked: true,
            grid: {
              color: "#48484A",
            },
          },
          y: {
            stacked: true,
            grid: {
              color: "#48484A",
            },
          },
        },
      },
    });
    setChart(chart);
  }, []);

  useEffect(() => {
    if (chart != null && labels.length > 0) {
      chart.data = {
        labels,
        datasets: datasets.map(dataset => ({
          ...style,
          ...dataset,
        })),
      };
      chart.update();
    }
  }, [labels]);

  return <canvas ref={chartRef} height={230}></canvas>;
};
