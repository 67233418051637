import { AsyncData, Result } from "@swan-io/boxed";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { ActivityIndicator } from "react-native";
import { P, match } from "ts-pattern";
import { ErrorView } from "../components/ErrorView";
import { OnboardingCompany } from "../components/OnboardingCompany";
import { useUser } from "../features/context/UserContext";
import { CompanyDocument } from "../graphql/crm";

export const OnboardingPage = () => {
  const { company, subscription } = useUser();
  const { data } = useUrqlQuery(
    { query: CompanyDocument, pause: company === null && subscription === null },
    [],
  );

  return match(data)
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => <ActivityIndicator />)
    .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => {
      return <OnboardingCompany data={data} />;
    })
    .otherwise(() => <ErrorView />);
};
