import { StyleSheet, TextInput, View } from "react-native";
import { borderColorVariants, fontColorVariants } from "../../../styles/constants";

type FormatPreviewProps = {
  preview: string;
};

const styles = StyleSheet.create({
  root: {
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    flex: 0.8,
    flexShrink: 1,
    flexDirection: "row",
    alignItems: "stretch",
  },
  contents: {
    flexGrow: 1,
    flexShrink: 1,
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    borderColor: borderColorVariants.primary,
    borderWidth: 1,
    paddingHorizontal: 8,
  },
  input: {
    flexGrow: 1,
    outlineStyle: "none",
    color: fontColorVariants.dark,
    paddingHorizontal: 8,
    backgroundColor: borderColorVariants.white,
    height: 38,
    minWidth: 0,
    textAlign: "center",
    fontSize: 24,
  },
});

export const FormatPreview = ({ preview }: FormatPreviewProps) => {
  return (
    <View>
      <View style={styles.root}>
        <View style={styles.container}>
          <View style={styles.contents}>
            <TextInput value={preview} readOnly={true} style={styles.input} />
          </View>
        </View>
      </View>
    </View>
  );
};
