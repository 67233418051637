import { View } from "react-native";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { NavigationItem } from "./NavigationItem";

export const CrmNavigation = () => {
  return (
    <View role="navigation">
      <NavigationItem to={Router.CrmContactsList()} label={t("common.summary")} />

      <NavigationItem
        to={Router.CrmContactsList({ type: ["customer"] })}
        label={t("common.customers")}
      />

      <NavigationItem
        to={Router.CrmContactsList({ type: ["supplier"] })}
        label={t("common.suppliers")}
      />
    </View>
  );
};
