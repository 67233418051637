import { DecimalInput } from "@components/DecimalInput";
import { FormEnter } from "@components/forms/FormEnter";
import Input from "@components/forms/Input";
import { Option } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { Space } from "@swan-io/lake/src/components/Space";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { printIbanFormat, validateIban } from "@swan-io/shared-business/src/utils/validation";
import { useEffect } from "react";
import { StyleSheet, Text } from "react-native";
import { useForm } from "react-ux-form";
import { match } from "ts-pattern";
import { Button } from "../../../components/forms/Button";
import {
  OperationInfo,
  PaidPayrollDocument,
  PaidPayrollOutput,
  PayrollRelay,
} from "../../../graphql/crm";
import { fontColorVariants } from "../../../styles/constants";
import { t } from "../../../utils/i18n";
import { SaveIcon } from "../../../utils/icons";
import { validateOptional, validateRequired } from "../../../utils/validations";
import { useLoading } from "../../context/LoadingContext";

const styles = StyleSheet.create({
  red: {
    color: fontColorVariants.destructive500,
  },
  column: {
    flexBasis: "calc(50% - 12px)",
  },
});

type Props = {
  visible: boolean;
  onPressClose: () => void;
  payroll: PayrollRelay | undefined;
};

type paidForm = {
  amount: string;
  name: string;
  iban: string;
};

export const PayrollUpdateStatusModal = ({ visible, onPressClose, payroll }: Props) => {
  const [, paidPayroll] = useUrqlMutation(PaidPayrollDocument);
  const { Field, setFieldValue, submitForm } = useForm<paidForm>({
    amount: { initialValue: "", validate: validateRequired },
    name: { initialValue: "", validate: validateRequired },
    iban: { initialValue: "", validate: validateOptional(validateIban) },
  });

  useEffect(() => {
    setFieldValue("amount", String(payroll?.netPay) ?? "");
    setFieldValue("name", payroll?.employee?.name ?? "");
    setFieldValue("iban", payroll?.employee?.iban ?? "");
  }, [payroll]);

  const { setLoading } = useLoading();

  const handleSubmit = () => {
    submitForm(values => {
      setLoading(true);

      paidPayroll({
        input: {
          payrollId: payroll?.id as string,
          amount: values.amount ?? "",
          name: values.name ?? "",
          iban: values.iban ?? "",
        },
      }).mapOk(data => {
        match(data.paidPayroll)
          .with({ __typename: "OperationInfo" }, () =>
            showToast({
              variant: "error",
              title: (data?.paidPayroll as OperationInfo)?.messages[0]?.message ?? "",
              autoClose: true,
            }),
          )
          .otherwise(() => {
            const oauthUrl = Option.fromNullable((data?.paidPayroll as PaidPayrollOutput).oauthUrl);
            if (oauthUrl.isSome()) {
              window.location.replace(oauthUrl.value);
            }
            const consentUrl = Option.fromNullable(
              (data?.paidPayroll as PaidPayrollOutput).consentUrl,
            );
            consentUrl.match({
              Some: consentUrl => {
                setLoading(false);
                if (consentUrl !== "") {
                  window.location.replace(consentUrl);
                  return consentUrl;
                } else {
                  onPressClose();
                }
              },
              None: () =>
                showToast({
                  variant: "error",
                  title: (data?.paidPayroll as PaidPayrollOutput).message ?? "",
                  autoClose: true,
                }),
            });
          });
        setLoading(false);
      });
    });
  };

  return (
    <LakeModal visible={visible} onPressClose={onPressClose} title={t("hr.payroll.paidTitle")}>
      <ResponsiveContainer breakpoint={breakpoints.tiny}>
        {() => (
          <FormEnter onSubmit={handleSubmit}>
            <Box
              onTouchEnd={e => {
                e.stopPropagation();
              }}
            >
              <Field name="amount">
                {({ value, onChange, error }) => (
                  <LakeLabel
                    label={t("common.amount")}
                    style={styles.column}
                    extra={() => <Text style={styles.red}>*</Text>}
                    render={id => (
                      <DecimalInput
                        id={id}
                        value={String(value)}
                        onChangeDecimal={nextValue => onChange(nextValue)}
                        error={error}
                      />
                    )}
                  />
                )}
              </Field>

              <Field name="name">
                {Input({
                  label: t("contact.employee"),
                })}
              </Field>

              <Space height={24} />

              <LakeLabel
                label={t("contact.iban")}
                render={id => (
                  <Field name="iban">
                    {({ value, onChange, onBlur, error, validating, ref }) => (
                      <LakeTextInput
                        id={id}
                        ref={ref}
                        value={printIbanFormat(value)}
                        validating={validating}
                        hideErrors={error === undefined}
                        error={error}
                        onChangeText={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  </Field>
                )}
              />
            </Box>

            <Space height={24} />

            <Box alignItems="end">
              <Button onPress={handleSubmit} icon={<SaveIcon />} reverse={true}>
                {t("common.save")}
              </Button>
            </Box>
          </FormEnter>
        )}
      </ResponsiveContainer>
    </LakeModal>
  );
};
