import { ReactNode, createContext, useContext, useEffect, useState } from "react";

type NestedFormType = {
  forms: (() => void)[];
  addForm: (form: () => void) => void;
  onSubmit: () => void;
  addError: (error: string) => void;
  setMainForm: (handleSubmit: () => void) => void;
};

const NestedFormContext = createContext<NestedFormType | undefined>(undefined);

export const NestedFormProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [forms, setForms] = useState<(() => void)[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [submit, setSubmit] = useState(false);
  const [mainForm, setMainForm] = useState(() => () => {});

  const addForm = (form: () => void) => {
    setForms(prevForms => [...prevForms, form]);
  };

  const addError = (error: string) => {
    setErrors(prev => [...prev, error]);
  };

  const onSubmit = () => {
    setSubmit(false);
    setErrors([]);
    forms.forEach(form => {
      form();
    });
    setSubmit(true);
  };

  useEffect(() => {
    if (submit && errors.length === 0) {
      mainForm();
      setSubmit(false);
    }
  }, [submit, errors, mainForm]);

  return (
    <NestedFormContext.Provider value={{ onSubmit, forms, addForm, addError, setMainForm }}>
      {children}
    </NestedFormContext.Provider>
  );
};

export const useNestedForm = () => {
  const context = useContext(NestedFormContext);
  if (!context) {
    throw new Error("useNestedForm must be used within a NestedFormProvider");
  }
  return context;
};
