import { Option } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import { StyleSheet, Text } from "react-native";
import { InvoiceTotals } from "../../../graphql/crm";
import { common } from "../../../styles/common";
import { borderColorVariants, fontColorVariants } from "../../../styles/constants";
import { formatCurrency, t } from "../../../utils/i18n";

const styles = StyleSheet.create({
  column: {
    flexBasis: "calc(50% - 12px)",
    gap: 6,
  },
  gap: {
    display: "grid",
    gridTemplateColumns: "2fr 1fr",
    width: "100%",
    gap: 12,
  },
  resume: {
    fontSize: 14,
    fontWeight: "400",
    color: fontColorVariants.neutral500,
  },
  total: {
    borderTopWidth: 1,
    borderTopColor: borderColorVariants.gray400,
    marginTop: 6,
    paddingTop: 6,
  },
  totalText: {
    fontSize: 24,
    fontWeight: "700",
    color: fontColorVariants.neutral600,
  },
});

type Props = {
  totals?: InvoiceTotals;
};

export const InvoiceTotal = ({ totals }: Props) => {
  const hasDiscount = totals?.hasDiscount ?? false;
  const subtotal = totals?.subtotal ?? 0;
  const total = totals?.total ?? 0;
  const totalSuplido = Option.fromNullable(totals?.totalSuplido);

  const totalDiscount = totals?.totalDiscount != null ? Number(totals?.totalDiscount) : 0;

  return (
    <Box alignItems="end" style={styles.column}>
      <Line label={t("invoices.subtotal")} value={subtotal} />

      {hasDiscount && <Line label={t("invoices.totalDiscount")} value={-totalDiscount} />}

      <Line label={t("invoices.taxBase")} value={totals?.totalTaxBase} />

      {totals?.vats?.length !== 0 &&
        totals?.vats?.map(vat => (
          <Line
            key={vat.label}
            label={t("invoices.ivaDetail", { label: vat.label, taxBase: vat.taxBase as string })}
            value={vat.amount}
          />
        ))}

      {totals?.vats?.length === 0 && <Line label={t("invoices.iva")} value={totals?.totalVat} />}

      {totals?.recargos?.length !== 0 &&
        totals?.recargos?.map(vat => (
          <Line
            key={vat.label}
            label={t("invoices.recargoDetail", {
              label: vat.label,
              taxBase: vat.taxBase as string,
            })}
            value={vat.amount}
          />
        ))}

      {totals?.irpfs?.length !== 0 &&
        totals?.irpfs?.map(irpf => (
          <Line
            key={irpf.label}
            label={t("invoices.irpfDetail", {
              label: irpf.label,
              taxBase: irpf.taxBase as string,
            })}
            value={-Number(irpf.amount)}
          />
        ))}

      {totalSuplido.isSome() ? (
        <Line label={t("invoices.totalSuplido")} value={totalSuplido.get()} />
      ) : null}

      <Box direction="row" style={[styles.gap, styles.total]}>
        <Text style={styles.totalText}>{t("invoices.total")}</Text>

        <Text style={[styles.totalText, common.textRight]}>
          {formatCurrency(Number(total), "EUR")}
        </Text>
      </Box>
    </Box>
  );
};

const Line = ({ label, value }: { label: string; value: unknown }) =>
  value !== undefined && (
    <Box direction="row" style={styles.gap}>
      <Text style={styles.resume}>{label}</Text>

      <Text style={[styles.resume, common.textRight]}>
        {formatCurrency(Number(value ?? 0), "EUR")}
      </Text>
    </Box>
  );
