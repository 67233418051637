import { AutoWidthImage } from "@swan-io/lake/src/components/AutoWidthImage";
import { useEffect, useState } from "react";
import { Pressable } from "react-native";
import { UploadLogoCompany } from "../features/invoices/components/UploadLogoCompany";

type Props = {
  logoUrl: string;
};

export const UploadShowLogoCompany = ({ logoUrl }: Props) => {
  const [logoCompany, setLogoCompany] = useState(logoUrl);

  useEffect(() => {
    setLogoCompany(logoUrl);
  }, [logoUrl]);

  return !logoCompany ? (
    <UploadLogoCompany onUpload={setLogoCompany} />
  ) : (
    <Pressable onPress={() => setLogoCompany("")}>
      <AutoWidthImage sourceUri={logoCompany} maxWidth={300} height={60} />
    </Pressable>
  );
};
