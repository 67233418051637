import { Path, Svg } from "@swan-io/lake/src/components/Svg";

export const SaveIcon = () => (
  <Svg width={21} height={20} viewBox="0 0 21 20">
    <Path
      d="M7.38889 6.1111H5.05556C4.19645 6.1111 3.5 6.80755 3.5 7.66666V14.6667C3.5 15.5258 4.19645 16.2222 5.05556 16.2222H15.9444C16.8036 16.2222 17.5 15.5258 17.5 14.6667V7.66666C17.5 6.80755 16.8036 6.1111 15.9444 6.1111H13.6111M12.8333 9.22222L10.5 11.5555M10.5 11.5555L8.16667 9.22222M10.5 11.5555L10.5 3.77777"
      stroke="currentColor"
      strokeWidth={1.67}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </Svg>
);

export const CloseIcon = ({ size = 24 }) => (
  <Svg width={size} height={size} viewBox="0 0 24 24">
    <Path
      d="M4.2097 4.3871L4.29289 4.29289C4.65338 3.93241 5.22061 3.90468 5.6129 4.2097L5.70711 4.29289L12 10.585L18.2929 4.29289C18.6834 3.90237 19.3166 3.90237 19.7071 4.29289C20.0976 4.68342 20.0976 5.31658 19.7071 5.70711L13.415 12L19.7071 18.2929C20.0676 18.6534 20.0953 19.2206 19.7903 19.6129L19.7071 19.7071C19.3466 20.0676 18.7794 20.0953 18.3871 19.7903L18.2929 19.7071L12 13.415L5.70711 19.7071C5.31658 20.0976 4.68342 20.0976 4.29289 19.7071C3.90237 19.3166 3.90237 18.6834 4.29289 18.2929L10.585 12L4.29289 5.70711C3.93241 5.34662 3.90468 4.77939 4.2097 4.3871L4.29289 4.29289L4.2097 4.3871Z"
      fill="currentColor"
    />
  </Svg>
);

export const ArrowLeft = (
  <Svg width={10} height={14} viewBox="0 0 10 14">
    <Path
      fillRule="evenodd"
      d="M8.78975 0.410051C9.33649 0.956784 9.33649 1.84322 8.78975 2.38995L4.1797 7L8.78975 11.6101C9.33649 12.1568 9.33649 13.0432 8.78975 13.5899C8.24302 14.1367 7.35659 14.1367 6.80985 13.5899L1.20986 7.98995C0.663121 7.44322 0.663121 6.55678 1.20986 6.01005L6.80985 0.410051C7.35659 -0.136683 8.24302 -0.136684 8.78975 0.410051Z"
      fill="currentColor"
    />
  </Svg>
);

export const ArrowRight = (
  <Svg width={20} height={20} viewBox="0 0 20 20">
    <Path
      fillRule="evenodd"
      d="M6.20986 16.5899C5.66312 16.0432 5.66312 15.1568 6.20986 14.6101L10.8199 10L6.20986 5.38995C5.66312 4.84322 5.66312 3.95678 6.20986 3.41005C6.75659 2.86332 7.64302 2.86332 8.18975 3.41005L13.7898 9.01005C14.3365 9.55678 14.3365 10.4432 13.7898 10.9899L8.18975 16.5899C7.64302 17.1367 6.75659 17.1367 6.20986 16.5899Z"
      fill="currentColor"
    />
  </Svg>
);

export const DocumentPlus = (
  <Svg width={14} height={16} viewBox="0 0 14 16">
    <Path
      d="M4.66677 8.77778H9.33344M7.00011 6.44444L7.00011 11.1111M10.889 15H3.11122C2.25211 15 1.55566 14.3036 1.55566 13.4444V2.55556C1.55566 1.69645 2.25211 1 3.11122 1H7.45572C7.662 1 7.85983 1.08194 8.00569 1.22781L12.2167 5.43886C12.3626 5.58472 12.4446 5.78255 12.4446 5.98883V13.4444C12.4446 14.3036 11.7481 15 10.889 15Z"
      stroke="currentColor"
      strokeWidth={1.67}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </Svg>
);
