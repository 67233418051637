import { Button } from "@components/forms/Button";
import Select from "@components/forms/Select";
import { Box } from "@swan-io/lake/src/components/Box";
import { Space } from "@swan-io/lake/src/components/Space";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { DatePicker } from "@swan-io/shared-business/src/components/DatePicker";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { StyleSheet } from "react-native";
import { useForm } from "react-ux-form";
import { match } from "ts-pattern";
import { CombinedError } from "urql";
import { validateRequired } from "../../../../../../banking/src/utils/validations";
import {
  CreateProjectEmployeeDocument,
  OperationInfo,
  ProjectsAssignmetDocument,
} from "../../../../graphql/crm";
import { encodeShortDate, getToday } from "../../../../utils/date";
import { locale, t } from "../../../../utils/i18n";
import { SaveIcon } from "../../../../utils/icons";
import { extractQueryData } from "../../../../utils/validations";
import { ProjectEmployeeState } from "../../../projects/types";

const styles = StyleSheet.create({
  grid: {
    display: "grid",
    alignItems: "flex-start",
    gridTemplateColumns: "1fr 1fr",
    gap: 12,
  },
  gap: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    width: "75%",
    alignSelf: "flex-end",
    gap: 48,
  },
});

type EmployeeAssignmentProps = {
  showAssignment: boolean;
  employeeId: string;
  onPressClose: () => void;
};
type Project = {
  id: string;
  name: string;
};

export const EmployeeAssignment = ({
  showAssignment,
  employeeId,
  onPressClose,
}: EmployeeAssignmentProps) => {
  const { Field, submitForm, setFieldError } = useForm<ProjectEmployeeState>({
    projectId: { initialValue: "", validate: validateRequired },
    employeeId: { initialValue: employeeId, validate: validateRequired },
    startDate: { initialValue: getToday(), validate: validateRequired },
    endDate: { initialValue: "" },
  });

  const { data } = useUrqlQuery({ query: ProjectsAssignmetDocument });
  const [, create] = useUrqlMutation(CreateProjectEmployeeDocument);

  const projects = extractQueryData(data, "value.value.projects") as Project[];

  const tapError = (error: Error | CombinedError) => {
    showToast({ variant: "error", title: error.message, autoClose: true });
  };

  const parsedProjects = projects?.map(project => ({
    value: project.id,
    name: project.name,
  }));

  const onSave = () => {
    submitForm(values => {
      create({
        input: {
          projectId: Number(values.projectId),
          employeeId: Number(employeeId),
          startDate: (values.startDate != null && encodeShortDate(values.startDate)) as string,
          endDate:
            values.endDate != "" && values.endDate != null ? encodeShortDate(values.endDate) : null,
        },
      })
        .mapOk(data => {
          match(data.createProjectEmployee)
            .with({ __typename: "OperationInfo" }, () => {
              for (const error of (data.createProjectEmployee as OperationInfo).messages) {
                if (error.field != null) {
                  setFieldError(error?.field as keyof ProjectEmployeeState, error?.message ?? "");
                } else {
                  showToast({
                    variant: "error",
                    title: error.message,
                    autoClose: true,
                  });
                }
              }
            })
            .otherwise(() => {
              showToast({
                variant: "success",
                title: t("project.assinged"),
                autoClose: true,
              });
              onPressClose();
            });
        })
        .tapError(tapError);
    });
  };

  return (
    <LakeModal visible={showAssignment} onPressClose={onPressClose} maxWidth={660}>
      <>
        <Box style={styles.grid}>
          <Field name="projectId">
            {Select({
              label: "Proyecto",
              items: parsedProjects,
              hasEmptyRow: true,
            })}
          </Field>
        </Box>

        <Space height={8} />

        <Box style={styles.grid}>
          <Field name="startDate">
            {({ value, onChange, error }) => (
              <DatePicker
                label={t("hr.end_date")}
                format={locale.dateFormat}
                firstWeekDay={locale.firstWeekday}
                value={value}
                onChange={onChange}
                error={error}
              />
            )}
          </Field>

          <Field name="endDate">
            {({ value, onChange, error }) => (
              <DatePicker
                label={t("hr.end_date")}
                format={locale.dateFormat}
                firstWeekDay={locale.firstWeekday}
                value={value ?? ""}
                onChange={onChange}
                error={error}
              />
            )}
          </Field>
        </Box>

        <Space height={24} />

        <Box style={styles.gap} alignItems="end">
          <Button onPress={onPressClose} mode="secondary">
            {t("common.cancel")}
          </Button>

          <Button onPress={onSave} icon={<SaveIcon />}>
            {t("common.save")}
          </Button>
        </Box>
      </>
    </LakeModal>
  );
};
