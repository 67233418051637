import { Calendar } from "@components/Calendar";
import { HorizonalValues } from "@components/HorizontalValues";
import { Sidebar } from "@components/Sidebar";
import { Taxes } from "@components/Taxes";
import { Bars } from "@components/chart/Bars";
import { HorizontalBar } from "@components/chart/HorizontalBar";
import { Growth } from "@components/summary/Growth";
import { Box } from "@swan-io/lake/src/components/Box";
import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { Space } from "@swan-io/lake/src/components/Space";
import { WithPartnerAccentColor } from "@swan-io/lake/src/components/WithPartnerAccentColor";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { useResponsive } from "@swan-io/lake/src/hooks/useResponsive";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { SkipToContent } from "@swan-io/shared-business/src/components/SkipToContent";
import { useEffect, useState } from "react";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import { match } from "ts-pattern";
import taxes from "../assets/img/taxes.webp";
import { useUser } from "../features/context/UserContext";
import {
  GroupedOutput,
  GrowthOutput,
  SummaryDocument,
  SummationOutput,
  SummationPendingOutput,
} from "../graphql/crm";
import { backgroundColorVariants, fontColorVariants } from "../styles/constants";
import { encodeDate, encodeDateISO } from "../utils/date";
import { toNumber } from "../utils/decimal";
import { formatCurrency, t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { extractQueryData } from "../utils/validations";
import { COLORS } from "../values/colors";

const styles = StyleSheet.create({
  background: {
    flexShrink: 1,
    flexGrow: 1,
    backgroundColor: COLORS.BACKGROUND,
  },
  container: {
    flexShrink: 1,
    flexGrow: 1,
  },
  desktopContainer: {
    flexDirection: "row",
    width: "100%",
    marginHorizontal: "auto",
  },
  root: {
    ...commonStyles.fill,
    paddingTop: 8,
  },
  headContainer: {
    zIndex: 1,
    padding: 24,
  },
  heading: {
    fontSize: 20,
    fontWeight: "600",
    color: fontColorVariants.gray600,
  },
  charts: {
    paddingHorizontal: 24,
  },
  box: {
    borderRadius: 25,
    boxShadow:
      "0 67.609px 54.087px 0 rgba(201, 201, 201, 0.07), 0 28.246px 22.596px 0 rgba(108, 73, 172, 0.05), 0 15.101px 12.081px 0 rgba(108, 73, 172, 0.04), 0 8.466px 6.773px 0 rgba(108, 73, 172, 0.04), 0 4.496px 3.597px 0 rgba(108, 73, 172, 0.03), 0 1.871px 1.497px 0 rgba(108, 73, 172, 0.02)",
    paddingVertical: 24,
    paddingHorizontal: 18,
    backgroundColor: backgroundColorVariants.dark,
    color: fontColorVariants.white,
    marginBottom: 12,
  },
  row: {
    display: "grid",
    gridTemplateColumns: ".6fr .4fr",
    gap: 12,
  },
  title: {
    fontSize: 24,
    fontWeight: "500",
    color: fontColorVariants.white,
  },
});

export const Summary = () => {
  const { desktop } = useResponsive();

  const { user, company } = useUser();

  const name = user?.firstName != "" ? user?.firstName : company?.name;

  const dateFrom = new Date(new Date().getFullYear(), new Date().getMonth() - 2, 1);
  const dateTo = new Date();

  const [dates, setDates] = useState({
    dateFrom: encodeDateISO(dateFrom.toString()),
    dateTo: encodeDateISO(dateTo.toString()),
  });

  useEffect(() => {
    Router.push("SummaryRoot", {
      dateFrom: encodeDate(dates.dateFrom),
      dateTo: encodeDate(dates.dateTo),
    });
  }, [dates]);

  const route = Router.useRoute(["SummaryRoot"]);
  const params = match(route)
    .with({ name: "SummaryRoot" }, ({ params }) => params)
    .otherwise(() => {});

  const { data } = useUrqlQuery({
    query: SummaryDocument,
    variables: {
      filters: {
        dateRange: { gte: params?.dateFrom, lte: params?.dateTo },
      },
    },
  });
  const summation = extractQueryData(data, "value.value.summarySummation") as SummationOutput;
  const grouped = extractQueryData(data, "value.value.summaryGrouped") as GroupedOutput;
  const summationPending = extractQueryData(
    data,
    "value.value.summarySummationPending",
  ) as SummationPendingOutput;
  const growth = extractQueryData(data, "value.value.summaryGrowth") as GrowthOutput;

  return (
    <WithPartnerAccentColor color="#498AF2">
      <SkipToContent />

      <View style={styles.background}>
        <View style={[styles.container, desktop && styles.desktopContainer]}>
          {desktop && <Sidebar />}

          <ScrollView style={styles.root}>
            <ResponsiveContainer breakpoint={breakpoints.medium}>
              {({ large }) => (
                <View style={commonStyles.fill} role="main">
                  <Box direction="row" alignItems="center" style={styles.headContainer}>
                    <Text style={styles.heading}>
                      {t("greeting", {
                        greeting:
                          new Date().getHours() < 12 ? t("goodMorning") : t("goodAfternoon"),
                        name: name != null ? `, ${name}` : "",
                      })}
                      {", "}
                      {t("common.yourSummary")}
                    </Text>

                    <Space width={24} />
                    <Calendar dateFrom={dateFrom} dateTo={dateTo} setDates={setDates} />
                  </Box>

                  <Space height={large ? 12 : 32} />

                  <View style={styles.charts}>
                    <HorizonalValues
                      data={[
                        {
                          title: t("common.income"),
                          value: formatCurrency((summation?.income as number) ?? 0, "EUR"),
                        },
                        {
                          title: t("common.expenses"),
                          value: formatCurrency((summation?.expenses as number) ?? 0, "EUR"),
                        },
                        {
                          title: t("common.result"),
                          value: formatCurrency((summation?.result as number) ?? 0, "EUR"),
                        },
                      ]}
                      image={taxes}
                      imageHeight={142}
                    />

                    <Box style={styles.box}>
                      <Text style={styles.title}>{t("common.summary")}</Text>

                      <Bars
                        labels={grouped?.labels as []}
                        datasets={[
                          {
                            data: grouped?.dataset1.map(value => toNumber(value as string)),
                          },
                          {
                            data: grouped?.dataset2.map(value => toNumber(value as string)),
                          },
                        ]}
                        showAverage={true}
                      />
                    </Box>

                    <Box justifyContent="spaceBetween" style={large && styles.row}>
                      <Box style={styles.box}>
                        <Text style={styles.title}>{t("common.salesReceivable")}</Text>

                        <HorizontalBar
                          labels={[""]}
                          datasets={[
                            {
                              label: t("common.pending"),
                              data: [summationPending?.pending as number],
                            },
                            {
                              label: t("common.overdued"),
                              data: [summationPending?.overdued as number],
                            },
                          ]}
                        />
                      </Box>

                      <Box style={styles.box}>
                        <Text style={styles.title}>{t("common.growth")}</Text>
                        <Growth data={growth} />
                      </Box>
                    </Box>

                    <Taxes />
                  </View>
                </View>
              )}
            </ResponsiveContainer>
          </ScrollView>
        </View>
      </View>
    </WithPartnerAccentColor>
  );
};
