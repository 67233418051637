import { Button, ButtonGroup } from "@components/forms/Button";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { useCallback, useRef, useState } from "react";
import { FaCamera } from "react-icons/fa6";
import { Pressable, StyleSheet, View } from "react-native";
import Webcam from "react-webcam";
import { t } from "../../../utils/i18n";
import { base64ToFile } from "../utils";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: 16,
  },
  image: {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
    marginBottom: 16,
    width: 640,
    height: 480,
    aspectRatio: "64/48",
  },
});

const videoConstraints = {
  facingMode: "environment",
};

type Props = {
  setCameraImage: (image: File) => void;
  setVisible: (visible: boolean) => void;
};

export const WebCam = ({ setCameraImage, setVisible }: Props) => {
  const webcamRef = useRef<Webcam | null>(null);
  const [image, setImage] = useState<string | null>(null);

  const capture = useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc !== null) {
        setImage(imageSrc);
      }
    }
  }, []);

  const uploadImage = useCallback(() => {
    if (image != null) {
      base64ToFile(image, `ocr_${Date.now()}.jpg`)
        .then(file => {
          setCameraImage(file);
          setVisible(false);
        })
        .catch(error => {
          showToast({ title: String(error), variant: "error" });
        });
    }
  }, [image, setCameraImage, setVisible]);

  return (
    <>
      <View style={styles.container}>
        {!(image != null) ? (
          <Pressable onPress={capture}>
            <Webcam
              audio={false}
              screenshotFormat="image/jpeg"
              style={styles.image}
              videoConstraints={videoConstraints}
              ref={webcamRef}
            />

            <ButtonGroup>
              <Button style="group" onPress={capture}>
                <FaCamera size={24} />
              </Button>
            </ButtonGroup>
          </Pressable>
        ) : (
          <>
            <img style={styles.image} src={image} alt="Invoice" />

            <ButtonGroup>
              <Button style="group" onPress={() => setImage(null)}>
                {t("common.retake")}
              </Button>

              <Button style="group" onPress={() => uploadImage()}>
                {t("common.use")}
              </Button>
            </ButtonGroup>
          </>
        )}
      </View>
    </>
  );
};
