import { Static } from "../../graphql/crm";
import { DefaultCell } from "./DefaultCell";

type Props = {
  data: string;
  items: Static[];
  align?: "center" | "left" | "right";
};

export const StaticCell = ({ data, items, align }: Props) => (
  <DefaultCell data={items.find(item => item.value === data)?.name ?? data} align={align} />
);
