import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { CountryCCA3 } from "@swan-io/shared-business/src/constants/countries";
import { useForm } from "react-ux-form";
import { match } from "ts-pattern";
import {
  CreateCustomerDocument,
  CustomerRelay,
  OperationInfo,
  UpdateCustomerDocument,
} from "../../../graphql/crm";
import { t } from "../../../utils/i18n";
import { subscriptionError, useSubscription } from "../../../utils/subscription";
import {
  handlerFieldErrors,
  tapError,
  validateEmail,
  validateRequired,
} from "../../../utils/validations";
import { Editor } from "../../contacts/components/Editor";
import { FormContactState } from "../../contacts/types";

type Props = {
  visible?: boolean;
  customer?: CustomerRelay;
  onClose?: () => void;
};

export const CustomerCreate = ({ visible = false, customer, onClose }: Props) => {
  const {
    Field,
    submitForm,
    setFieldValue,
    getFieldState,
    FieldsListener,
    resetForm,
    setFieldError,
  } = useForm<FormContactState>({
    type: { initialValue: "customer" },
    name: {
      initialValue: customer?.name ?? "",
      validate: validateRequired,
      sanitize: (value: string) => value.trim(),
    },
    nif: { initialValue: customer?.nif ?? "" },
    country: {
      initialValue: (customer?.country as CountryCCA3) ?? "ESP",
      validate: validateRequired,
    },
    postalCode: { initialValue: customer?.postalCode ?? "" },
    phone: { initialValue: customer?.phone ?? "" },
    email: {
      initialValue: customer?.email ?? "",
      validate: async value => {
        if (value) {
          return await validateEmail(value);
        }
      },
    },
    address: { initialValue: customer?.address ?? "" },
    paymentMethod: { initialValue: "" },
    iban: { initialValue: "" },
  });

  const { showUpgrade } = useSubscription();

  const [, createCustomer] = useUrqlMutation(CreateCustomerDocument);
  const [, updateCustomer] = useUrqlMutation(UpdateCustomerDocument);

  const onPressCreate = () => {
    submitForm(values => {
      const commonInput = {
        name: values.name ?? "",
        nif: values.nif,
        address: values.address,
        postalCode: values.postalCode,
        country: values.country ?? "ESP",
        phone: values.phone,
        email: values.email,
      };

      if (customer?.id != null) {
        updateCustomer({
          input: {
            ...commonInput,
            id: customer.id as string,
          },
        })
          .mapOk(data => {
            match(data.updateCustomer)
              .with({ __typename: "OperationInfo" }, () =>
                handlerFieldErrors<FormContactState>(
                  data.updateCustomer as OperationInfo,
                  setFieldError,
                ),
              )
              .otherwise(() => {
                resetForm();
                onClose?.();
              });
          })
          .tapError(tapError);
        return;
      }

      createCustomer({
        input: commonInput,
      })
        .mapOk(data => {
          match(data.createCustomer)
            .with({ __typename: "OperationInfo" }, row =>
              match(row.messages[0])
                .with({ field: "numClients" }, () => {
                  showUpgrade({
                    title: t("plan.limitTitle"),
                    description: subscriptionError(row),
                  });
                })
                .otherwise(() =>
                  handlerFieldErrors<FormContactState>(
                    data.createCustomer as OperationInfo,
                    setFieldError,
                  ),
                ),
            )
            .otherwise(() => {
              resetForm();
              onClose?.();
            });
        })
        .tapError(tapError);
    });
  };

  return (
    <LakeModal
      visible={visible}
      title={customer?.id !== undefined ? t("invoices.editCustomer") : t("invoices.newCustomer")}
      maxWidth={740}
      onPressClose={onClose}
    >
      <ResponsiveContainer breakpoint={breakpoints.tiny}>
        {({ large }) => (
          <>
            <Editor
              contactType={"customer"}
              large={large}
              Field={Field}
              setFieldValue={setFieldValue}
              getFieldState={getFieldState}
              FieldsListener={FieldsListener}
              onSave={onPressCreate}
            />
          </>
        )}
      </ResponsiveContainer>
    </LakeModal>
  );
};
