import { ErrorView } from "@components/ErrorView";
import { Sidebar } from "@components/Sidebar";
import { ErrorBoundary } from "@swan-io/lake/src/components/ErrorBoundary";
import { WithPartnerAccentColor } from "@swan-io/lake/src/components/WithPartnerAccentColor";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { useResponsive } from "@swan-io/lake/src/hooks/useResponsive";
import { CONTENT_ID, SkipToContent } from "@swan-io/shared-business/src/components/SkipToContent";
import { ScrollView, StyleSheet, View } from "react-native";
import { Router } from "../../../utils/routes";
import { isUnauthorizedError } from "../../../utils/urql";
import { COLORS } from "../../../values/colors";
import { Navigation as InvoicesNavigation } from "./Navigation";
import { List } from "./SalesList";

const styles = StyleSheet.create({
  background: {
    flexShrink: 1,
    flexGrow: 1,
    backgroundColor: COLORS.BACKGROUND,
  },
  container: {
    flexShrink: 1,
    flexGrow: 1,
  },
  content: {
    ...commonStyles.fill,
  },
  desktopContainer: {
    flexDirection: "row",
    width: "100%",
    marginHorizontal: "auto",
  },
  mobileContentContainer: {
    minHeight: "100%",
    paddingBottom: 24,
  },
  desktopContentContainer: {
    ...commonStyles.fill,
  },
});

export const SalesArea = () => {
  const { desktop } = useResponsive();
  const route = Router.useRoute(["InvoicesSalesCreate"]);

  return (
    <WithPartnerAccentColor color="#498AF2">
      <SkipToContent />

      <View style={styles.background}>
        <View style={[styles.container, desktop && styles.desktopContainer]}>
          {desktop && (
            <Sidebar>
              <InvoicesNavigation />
            </Sidebar>
          )}

          <ScrollView
            contentContainerStyle={
              desktop ? styles.desktopContentContainer : styles.mobileContentContainer
            }
          >
            <View style={styles.content} id={CONTENT_ID} tabIndex={0}>
              <ErrorBoundary
                key={route?.name}
                fallback={({ error }) =>
                  isUnauthorizedError(error) ? <></> : <ErrorView error={error} />
                }
              >
                <List />
              </ErrorBoundary>
            </View>
          </ScrollView>
        </View>
      </View>
    </WithPartnerAccentColor>
  );
};
