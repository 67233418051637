import { Dict } from "@swan-io/boxed";
import { FilterChooser } from "@swan-io/lake/src/components/FilterChooser";
import {
  FilterCheckboxDef,
  FiltersStack,
  FiltersState,
} from "@swan-io/lake/src/components/Filters";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { useEffect, useMemo, useState } from "react";
import { Static } from "../../../graphql/crm";
import { t } from "../../../utils/i18n";
import { staticToItemLower } from "../../../utils/utils";

type Props = {
  filters: InvoiceFiltersState;
  onChange: (values: Partial<InvoiceFiltersState>) => void;
  unitType: Static[];
  irpf: Static[];
  vat: Static[];
};

const unitTypeFilter: FilterCheckboxDef<string> = {
  type: "checkbox",
  label: t("invoices.unitType"),
  submitText: t("common.filters.apply"),
  checkAllLabel: t("common.filters.all"),
  items: [],
};

const irpfFilter: FilterCheckboxDef<string> = {
  type: "checkbox",
  label: t("invoices.irpf"),
  submitText: t("common.filters.apply"),
  checkAllLabel: t("common.filters.all"),
  items: [],
};

const vatFilter: FilterCheckboxDef<string> = {
  type: "checkbox",
  label: t("invoices.iva"),
  submitText: t("common.filters.apply"),
  checkAllLabel: t("common.filters.all"),
  items: [],
};

const filtersDefinition = {
  unitType: unitTypeFilter,
  irpf: irpfFilter,
  vat: vatFilter,
};

export type InvoiceFiltersState = FiltersState<typeof filtersDefinition> & {
  search?: string | undefined;
};

export const ListFilter = ({ filters, onChange, unitType, irpf, vat }: Props) => {
  const filtersWithoutSearch = useMemo(() => {
    const { search, ...filtersWithoutSearch } = filters;
    return filtersWithoutSearch;
  }, [filters]);

  unitTypeFilter.items = staticToItemLower<string>(unitType);
  irpfFilter.items = staticToItemLower<string>(irpf);
  vatFilter.items = staticToItemLower<string>(vat);

  const availableFilters: { name: keyof typeof filtersWithoutSearch; label: string }[] = useMemo(
    () => [
      {
        name: "unitType",
        label: t("invoices.unitType"),
      },
      {
        name: "irpf",
        label: t("invoices.irpf"),
      },
      {
        name: "vat",
        label: t("invoices.iva"),
      },
    ],
    [],
  );

  const [openFilters, setOpenFilters] = useState(() =>
    Dict.entries(filtersWithoutSearch)
      .filter(([, value]) => isNotNullish(value))
      .map(([name]) => name),
  );

  useEffect(() => {
    setOpenFilters(openFilters => {
      const currentlyOpenFilters = new Set(openFilters);
      const openFiltersNotYetInState = Dict.entries(filtersWithoutSearch)
        .filter(([name, value]) => isNotNullish(value) && !currentlyOpenFilters.has(name))
        .map(([name]) => name);
      return [...openFilters, ...openFiltersNotYetInState];
    });
  }, [filtersWithoutSearch]);

  return (
    <>
      <FiltersStack
        definition={filtersDefinition}
        filters={filters}
        openedFilters={openFilters}
        onChangeFilters={value => onChange({ ...value })}
        onChangeOpened={setOpenFilters}
      />

      <FilterChooser
        filters={filtersWithoutSearch}
        openFilters={openFilters}
        label={t("common.filters")}
        title={t("common.chooseFilter")}
        onAddFilter={filter => setOpenFilters(openFilters => [...openFilters, filter])}
        availableFilters={availableFilters}
      />
    </>
  );
};
