import { isNullish } from "@swan-io/lake/src/utils/nullish";
import { CombinedError } from "@urql/core";
import { cacheExchange } from "@urql/exchange-graphcache";
import { relayPagination } from "@urql/exchange-graphcache/extras";
import { Client, errorExchange, fetchExchange } from "urql";
import { env } from "./env";
import { Router } from "./routes";

export const isCombinedError = (error: unknown): error is CombinedError =>
  error instanceof CombinedError;

const onError = (error: CombinedError) => {
  if (isUnauthorizedError(error)) {
    if (
      isNullish(Router.getRoute(["SigninPage"])) &&
      isNullish(Router.getRoute(["SignupPage"])) &&
      isNullish(Router.getRoute(["ActivateRoot"])) &&
      isNullish(Router.getRoute(["ActivateEmail"]))
    ) {
      window.location.replace(Router.SigninPage({ sessionExpired: "true" }));
    }
  }
};

const crmCache = cacheExchange({
  resolvers: {
    Query: {
      contacts: relayPagination(),
      products: relayPagination(),
      salesInvoices: relayPagination(),
      purchaseInvoices: relayPagination(),
      employees: relayPagination(),
      payrolls: relayPagination(),
      tickets: relayPagination(),
    },
  },
});

export const crmClient = new Client({
  url: `${env.BACKEND_URL}/graphql/`,
  requestPolicy: "network-only",
  exchanges: [errorExchange({ onError }), crmCache, fetchExchange],
  fetchOptions: {
    credentials: "include",
  },
});

export const isUnauthorizedError = (error: unknown) => {
  if (!isCombinedError(error)) {
    return false;
  }
  const { message } = error;
  return message.toLowerCase().includes("usuario no autenticado");
};
