import { ColumnConfig, PlainListView } from "@components/PlainListView";
import { DefaultCell } from "@components/forms/DefaultCell";
import { PriceCell } from "@components/forms/PriceCell";
import { StaticCell } from "@components/forms/StaticCell";
import { LinkConfig } from "@swan-io/lake/src/components/FixedListView";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { ReactElement, ReactNode } from "react";
import { StyleSheet, ViewStyle } from "react-native";
import { ProductsRelay, SalesInvoiceCreateStaticDocument } from "../../../graphql/crm";
import { t } from "../../../utils/i18n";
import { COLORS } from "../../../values/colors";
import { extractSalesStatic } from "../utils";
import { ColumnHeader } from "./ColumnHeader";

const styles = StyleSheet.create({
  header: {
    fontWeight: "600",
    "--color-gray-900": COLORS.WHITE,
    "--text-medium-font-size": 12,
  },
});

type Props = {
  products: ProductsRelay[];
  loading?: { isLoading: boolean; count: number };
  onEndReached: () => void;
  onActiveRowChange: (element: HTMLElement) => void;
  onRefreshRequest: () => void;
  activeRowId?: string;
  renderEmptyList: () => ReactNode;
  getRowLink: (item: LinkConfig<ProductsRelay, ExtraInfo>) => ReactElement;
};
type ExtraInfo = undefined;

export const DetailsList = ({
  products,
  loading,
  onEndReached,
  activeRowId,
  getRowLink,
  onActiveRowChange,
  renderEmptyList,
}: Props) => {
  const { data } = useUrqlQuery({ query: SalesInvoiceCreateStaticDocument }, []);

  const unitType = extractSalesStatic(data, "unitType");
  const vatType = extractSalesStatic(data, "vatType");
  const irpfType = extractSalesStatic(data, "irpfType");

  const columns: ColumnConfig<ProductsRelay, ExtraInfo>[] = [
    {
      id: "description",
      width: "grow",
      title: t("invoices.description"),
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => <DefaultCell data={item.description} />,
    },
    {
      id: "unitType",
      width: "grow",
      title: t("invoices.unitType"),
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => <StaticCell data={item.unitType as string} items={unitType} />,
    },
    {
      id: "unitPrice",
      width: 150,
      title: t("invoices.unitPrice"),
      renderTitle: ({ title }) => <ColumnHeader text={title} align="right" />,
      renderCell: ({ item }) => <PriceCell price={item.unitPrice as number} align="right" />,
    },
    {
      id: "vat_rate",
      width: "grow",
      title: t("invoices.iva"),
      renderTitle: ({ title }) => <ColumnHeader text={title} align="center" />,
      renderCell: ({ item }) => <StaticCell data={item.vat} items={vatType} align="center" />,
    },
    {
      id: "irpf",
      width: "grow",
      title: t("invoices.irpf"),
      renderTitle: ({ title }) => <ColumnHeader text={title} align="center" />,
      renderCell: ({ item }) => (
        <StaticCell data={item.irpf as string} items={irpfType} align="center" />
      ),
    },
  ];

  const smallColumns: ColumnConfig<ProductsRelay, ExtraInfo>[] = columns.filter(
    c => c.id === "description",
  );

  return (
    <PlainListView
      withoutScroll={true}
      data={products}
      keyExtractor={item => item.id as string}
      headerHeight={36}
      rowHeight={63}
      headerStyle={styles.header as ViewStyle}
      headerBackgroundColor="#EDEDED"
      groupHeaderHeight={36}
      extraInfo={undefined}
      columns={columns}
      smallColumns={smallColumns}
      activeRowId={activeRowId}
      onActiveRowChange={onActiveRowChange}
      loading={loading}
      onEndReached={onEndReached}
      getRowLink={getRowLink}
      renderEmptyList={renderEmptyList}
      breakpoint={breakpoints.tiny}
    />
  );
};
