import { useLocation } from "@swan-io/chicane";
import { Icon, IconName } from "@swan-io/lake/src/components/Icon";
import { Link } from "@swan-io/lake/src/components/Link";
import { Pressable } from "@swan-io/lake/src/components/Pressable";
import { useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { backgroundColorVariants, fontColorVariants } from "../styles/constants";
import { LockIcon, useSubscription } from "../utils/subscription";

const styles = StyleSheet.create({
  link: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
    padding: 6,
    paddingLeft: 24,
    fontSize: 13,
    fontWeight: "500",
    color: fontColorVariants.gray600,
    transitionProperty: "all",
    transitionDuration: "0.2s",
  },
  hover: {
    backgroundColor: backgroundColorVariants.white,
  },
  active: {
    color: fontColorVariants.primary500,
    backgroundColor: backgroundColorVariants.primary50,
  },
});

type Props = {
  to: string;
  onPress?: () => void;
  icon?: IconName;
  renderIcon?: React.ReactNode;
  label: string;
  onlyIcon?: boolean;
  general?: boolean;
  isLocked?: boolean;
};

export const NavigationSubitem = ({
  to,
  onPress,
  icon,
  renderIcon,
  label,
  onlyIcon = false,
  general = false,
  isLocked = false,
}: Props) => {
  const location = useLocation();

  const pathname = location.raw.path + location.raw.search + location.raw.hash;

  const isActive = general ? location.raw.path.startsWith(to) : pathname === to;

  const [isHover, setIsHover] = useState(false);

  const { showUpgrade } = useSubscription();

  return (
    <Pressable onHoverIn={() => setIsHover(true)} onHoverOut={() => setIsHover(false)}>
      <Link
        to={isLocked ? "" : to}
        onPress={() => {
          isLocked ? showUpgrade({}) : onPress?.();
        }}
        numberOfLines={1}
        aria-label={label}
        style={[styles.link, isHover && styles.hover, isActive && styles.active]}
      >
        <View>
          {icon && <Icon name={icon} size={16} color={fontColorVariants.gray600} />}
          {renderIcon}
        </View>

        {!onlyIcon && (
          <>
            <Text>{label}</Text>

            {isLocked && <LockIcon />}
          </>
        )}
      </Link>
    </Pressable>
  );
};
