import { Dict } from "@swan-io/boxed";
import { FilterChooser } from "@swan-io/lake/src/components/FilterChooser";
import {
  FilterCheckboxDef,
  FiltersStack,
  FiltersState,
} from "@swan-io/lake/src/components/Filters";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { useEffect, useMemo, useState } from "react";
import { t } from "../../../utils/i18n";

type Props = {
  filters: ContactFiltersState;
  onChange: (values: Partial<ContactFiltersState>) => void;
};

export type ContactType = "customer" | "supplier";
const typeFilter: FilterCheckboxDef<ContactType> = {
  type: "checkbox",
  label: t("contact.type.description"),
  submitText: t("common.filters.apply"),
  checkAllLabel: t("common.filters.all"),
  items: [
    { value: "customer", label: t("contact.customer") },
    { value: "supplier", label: t("contact.supplier") },
  ],
};

const filtersDefinition = {
  type: typeFilter,
};

export type ContactFiltersState = FiltersState<typeof filtersDefinition> & {
  search: string | undefined;
};

export const ListFilter = ({ filters, onChange }: Props) => {
  const filtersWithoutSearch = useMemo(() => {
    const { search, ...filtersWithoutSearch } = filters;
    return filtersWithoutSearch;
  }, [filters]);

  const availableFilters = useMemo(
    () => [
      {
        name: "type",
        label: t("contact.type"),
      },
    ],
    [],
  );

  const [openFilters, setOpenFilters] = useState(() =>
    Dict.entries(filtersWithoutSearch)
      .filter(([, value]) => isNotNullish(value))
      .map(([name]) => name),
  );

  useEffect(() => {
    setOpenFilters(openFilters => {
      const currentlyOpenFilters = new Set(openFilters);
      const openFiltersNotYetInState = Dict.entries(filtersWithoutSearch)
        .filter(([name, value]) => isNotNullish(value) && !currentlyOpenFilters.has(name))
        .map(([name]) => name);
      return [...openFilters, ...openFiltersNotYetInState];
    });
  }, [filtersWithoutSearch]);

  return (
    <>
      <FiltersStack
        definition={filtersDefinition}
        filters={filters}
        openedFilters={openFilters}
        onChangeFilters={value => onChange({ ...value })}
        onChangeOpened={setOpenFilters}
      />

      <FilterChooser
        filters={filtersWithoutSearch}
        openFilters={openFilters}
        label={t("common.filters")}
        title={t("common.chooseFilter")}
        onAddFilter={filter => setOpenFilters(openFilters => [...openFilters, filter as "type"])}
        availableFilters={availableFilters}
      />
    </>
  );
};
