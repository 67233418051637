import { ReactNode } from "react";
import { Platform, StyleSheet } from "react-native";

const styles = StyleSheet.create({
  submit: {
    display: "none",
  },
});

type Props = {
  onSubmit: () => void;
  children: ReactNode;
};

export const Submit = () => {
  return <button type="submit" style={styles.submit} aria-label="Submit" />;
};

export const FormEnter = ({ onSubmit, children }: Props) => {
  return Platform.OS === "web" ? (
    <form
      onSubmit={event => {
        event.preventDefault();
        onSubmit();
      }}
    >
      {children}

      <Submit />
    </form>
  ) : (
    children
  );
};
