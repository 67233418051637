import { Box } from "@swan-io/lake/src/components/Box";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { StyleSheet, Text, View } from "react-native";
import { match } from "ts-pattern";
import { InvoiceHistoryOutput, Static } from "../../../graphql/crm";
import { backgroundColorVariants, fontColorVariants } from "../../../styles/constants";
import { encodeDateISO } from "../../../utils/date";
import { t } from "../../../utils/i18n";

const styles = StyleSheet.create({
  container: {
    position: "relative",
  },
  line: {
    position: "absolute",
    left: 16,
    width: 2,
    height: "100%",
    backgroundColor: backgroundColorVariants.primary500,
  },
  items: {
    gap: 48,
  },
  item: {
    gap: 16,
  },
  icon: {
    width: 36,
    height: 36,
    backgroundColor: backgroundColorVariants.primary500,
    borderRadius: 50,
  },
  strong: {
    color: fontColorVariants.neutral900,
    fontWeight: "500",
  },
  light: {
    color: fontColorVariants.neutral500,
    fontWeight: "400",
  },
});

type Props = {
  data?: InvoiceHistoryOutput[];
  status?: Static[];
};

export const InvoiceHistory = ({ data, status }: Props) => {
  return (
    <View style={styles.container}>
      <Box style={styles.line}></Box>

      <Box direction="column" style={styles.items}>
        {data?.map((item, index) => (
          <Box key={index} direction="row" style={styles.item}>
            <Box alignItems="center" justifyContent="center" style={styles.icon}>
              <Icon name="lake-check" size={18} color={fontColorVariants.neutral50} />
            </Box>

            <View>
              {match(item.reason)
                .with("rectification", () => (
                  <Text style={styles.strong}>{t("common.rectification")}</Text>
                ))
                .with("rectified", () => <Text style={styles.strong}>{t("common.rectified")}</Text>)
                .otherwise(() => (
                  <Text style={styles.strong}>
                    {status?.find(s => s.id === item.status)?.name ?? item.status}
                  </Text>
                ))}

              <Text style={styles.light}>{encodeDateISO(item.date)}</Text>
            </View>
          </Box>
        ))}
      </Box>
    </View>
  );
};
