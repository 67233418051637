import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { ColorVariants, spacings } from "@swan-io/lake/src/constants/design";
import { StyleSheet, View } from "react-native";
import { match } from "ts-pattern";
import { t } from "../../../utils/i18n";

const styles = StyleSheet.create({
  cell: {
    display: "flex",
    paddingHorizontal: spacings[8],
    flexGrow: 1,
    flexDirection: "row",
    alignItems: "center",
    width: 1,
  },
  overflowingText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  tag: {
    borderRadius: 50,
    paddingHorizontal: 12,
  },
});

export const getTypename = (typename: string): string =>
  match(typename)
    .with("CUSTOMER", () => t("contact.customer"))
    .with("SUPPLIER", () => t("contact.supplier"))
    .otherwise(() => "desconocido" as const);

export const getColor = (typename: string): ColorVariants =>
  match(typename)
    .with("CUSTOMER", () => "positive" as const)
    .with("SUPPLIER", () => "partner" as const)
    .otherwise(() => "positive" as const);

export const TypeCell = ({ typename }: { typename: string }) => {
  return (
    <View style={styles.cell}>
      <Tag color={getColor(typename)} style={styles.tag}>
        {getTypename(typename)}
      </Tag>
    </View>
  );
};

export const NameCell = ({ name }: { name: string }) => {
  return (
    <View style={styles.cell}>
      <LakeHeading variant="h5" level={3} style={styles.overflowingText}>
        {name}
      </LakeHeading>
    </View>
  );
};
