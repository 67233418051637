import { Link } from "@swan-io/chicane";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { StyleSheet } from "react-native";
import { useUser } from "../features/context/UserContext";
import {
  backgroundColorVariants,
  borderColorVariants,
  fontColorVariants,
} from "../styles/constants";
import { t } from "../utils/i18n";
import { Tile } from "./Tile";

const styles = StyleSheet.create({
  tileContainer: {
    paddingVertical: 12,
    paddingHorizontal: 20,
    backgroundColor: backgroundColorVariants.destructive800,
    borderWidth: 1,
    borderColor: borderColorVariants.neutral200,
    borderRadius: 8,
    boxShadow: "none",
    gap: 8,
  },
  red: {
    color: fontColorVariants.neutral200,
  },
});

export const Unpaid = () => {
  const { subscription } = useUser();

  if (!subscription) {
    return null;
  }

  return (
    subscription?.pastDueDaysLeft !== null && (
      <Tile style={styles.tileContainer}>
        <Box alignItems="center">
          <LakeText style={styles.red}>
            {t("plan.unpaid", { days: subscription?.pastDueDaysLeft ?? 0 })}
          </LakeText>

          <Link to={subscription?.paymentUrl ?? ""}>Pagar</Link>
        </Box>
      </Tile>
    )
  );
};
