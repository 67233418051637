import { Box } from "@swan-io/lake/src/components/Box";
import { StyleSheet, Text, ViewStyle } from "react-native";
import { common } from "../../../../styles/common";
import { formatCurrency } from "../../../../utils/i18n";

const styles = StyleSheet.create({
  row: {
    display: "flex",
    marginTop: 2,
    marginBottom: 2,
    minHeight: 32,
    alignItems: "center",
  },
  pl1: {
    paddingLeft: 16,
  },
  values: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    width: 300,
    gap: 12,
  },
});

export const RowMultiType = ({
  label,
  employee,
  rate,
  company,
  style = styles.pl1,
}: {
  label: string;
  employee?: string;
  rate?: string;
  company?: string;
  header?: boolean;
  style?: ViewStyle;
}) => {
  return (
    <Box
      direction="row"
      alignItems="center"
      justifyContent="spaceBetween"
      style={[style, styles.row]}
    >
      <Text>{label}</Text>

      <Box direction="row" justifyContent="spaceBetween" style={styles.values}>
        <Text style={common.textRight}>
          {employee != null && formatCurrency(parseFloat(employee), "EUR")}
        </Text>

        <Text style={common.textCenter}>{rate != null && `${rate}%`}</Text>

        <Text style={common.textRight}>
          {company != null && formatCurrency(parseFloat(company), "EUR")}
        </Text>
      </Box>
    </Box>
  );
};
