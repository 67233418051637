import { IconChoice } from "@components/IconChoice";
import { Button } from "@components/forms/Button";
import { capitalize } from "@swan-io/lake/src/utils/string";
import { useEffect, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import BeatLoader from "react-spinners/BeatLoader";
import { useQuery } from "urql";
import { ClassifySalesConceptDocument, Static } from "../../../graphql/crm";
import { fontColorVariants } from "../../../styles/constants";
import { t } from "../../../utils/i18n";
import { useUser } from "../../context/UserContext";
import { PurchaseCategoryConcept } from "../types";

const styles = StyleSheet.create({
  container: {
    borderRadius: 11,
    border: "1 solid #0B4682",
    backgroundImage: "linear-gradient(180deg, #114C87 0%, #346CA4 51.27%, #568ABF 100%)",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    padding: 16,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  textContainer: {
    width: "100%",
    padding: 12,
  },
  text: {
    color: fontColorVariants.white,
    maxWidth: "inherit",
    fontWeight: "bold",
    fontSize: 16,
  },
  suggestionContainer: {
    display: "flex",
    flexDirection: "row",
    padding: 8,
  },
  icon: {
    padding: 9,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  category_subcategory: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "80%",
    padding: 5,
  },
  category: {
    color: fontColorVariants.white,
    fontWeight: "bold",
    fontSize: 16,
    paddingBottom: 5,
  },
  subcategory: {
    color: fontColorVariants.white,
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  buttonSuggestionContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-end",
  },
  loaderContainer: {
    padding: 36,
  },
});

type CategorySuggestionProps = {
  categories: Static[];
  subcategories: Static[];
  concept: PurchaseCategoryConcept;
};

type StaticWithICons = Static & { icon: string };

export const SalesCategorySuggestion = ({
  categories,
  subcategories,
  concept,
}: CategorySuggestionProps) => {
  const [category, setCategory] = useState<StaticWithICons | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { user } = useUser();
  const [subcategory, setSubcategory] = useState<StaticWithICons | null>(null);
  const [execute, setExecute] = useState<boolean>(true);
  const [conceptName, setConceptName] = useState<string>("");
  const [conceptIVA, setConceptIVA] = useState<string>("");
  const [conceptIRPF, setConceptIRPF] = useState<string>("");

  const [{ data }] = useQuery({
    query: ClassifySalesConceptDocument,
    variables: {
      input: {
        concept: conceptName,
        iva: conceptIVA,
        irpf: conceptIRPF,
      },
    },
    pause: execute,
  });

  useEffect(() => {
    if (!data) {
      setLoading(false);
      return;
    }

    const { classifySalesConcept } = data;

    const { category: categoryId, subcategory: subcategoryId } = classifySalesConcept;

    const category = categories.find(category => category.id === categoryId?.toUpperCase());
    const subcategory = subcategories.find(subcategory => subcategory.id === subcategoryId);

    if (category && subcategory) {
      setCategory(category as StaticWithICons);
      setSubcategory(subcategory as StaticWithICons);
    }
    setLoading(false);
  }, [data, categories, subcategories]);

  return (
    <View style={styles.container}>
      <View style={styles.buttonSuggestionContainer}>
        <Button
          size="small"
          mode="secondary"
          onPress={() => {
            if (conceptName !== concept?.getFieldState("description")?.value) {
              setLoading(true);
              setCategory(null);
              setSubcategory(null);
              setConceptName(concept?.getFieldState("description")?.value);
              setConceptIVA(concept?.getFieldState("vat")?.value);
              setConceptIRPF(concept?.getFieldState("irpf")?.value);
              setExecute(false);
            }
          }}
        >
          {t("invoice.concept.suggestion.ask")}
        </Button>
      </View>

      {loading && (
        <View style={styles.loaderContainer}>
          <BeatLoader color="#ffffff" loading={loading} size={15} />
        </View>
      )}

      {!execute && category && subcategory && (
        <>
          <View style={styles.textContainer}>
            <Text style={styles.text}>
              {capitalize(user?.firstName ?? "") + user?.firstName?.slice(1) ?? ""}
              {t("invoice.concept.suggestion")}
            </Text>
          </View>

          <View style={styles.suggestionContainer}>
            <View style={styles.icon}>
              <IconChoice sourceUri={subcategory?.icon} name={""} isSelected={true} />
            </View>

            <View style={styles.category_subcategory}>
              <Text style={styles.category}>{category?.name}</Text>

              <Text style={styles.subcategory}>
                -{">"} {subcategory?.name}
              </Text>
            </View>
          </View>

          <View style={styles.buttonContainer}>
            <Button
              size="small"
              mode="secondary"
              onPress={() => {
                concept.setFieldValue("category", category.id);
                concept.setFieldValue("subcategory", subcategory.id);
              }}
            >
              {t("invoice.concept.suggestion.accept")}
            </Button>
          </View>
        </>
      )}
    </View>
  );
};
