import { Box } from "@swan-io/lake/src/components/Box";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { Space } from "@swan-io/lake/src/components/Space";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { useEffect, useState } from "react";
import { StyleSheet } from "react-native";
import { combineValidators, useForm } from "react-ux-form";
import { match } from "ts-pattern";
import { Button } from "../../../components/forms/Button";
import { PayrollRelay, SendPayrollDocument } from "../../../graphql/crm";
import { t } from "../../../utils/i18n";
import { sendForm } from "../../../utils/types";
import { handlerErrors, validateEmail, validateRequired } from "../../../utils/validations";

import dayjs from "dayjs";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { decode } from "html-entities";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useLoading } from "../../context/LoadingContext";

type Props = {
  payroll: PayrollRelay | undefined;
  visible: boolean;
  onPressClose?: () => void;
};

const styles = StyleSheet.create({
  container: {
    padding: 8,
  },
});

export const SendModal = ({ payroll, visible, onPressClose }: Props) => {
  const payrollId = payroll?.id ?? "0";
  const { setLoading } = useLoading();
  const [, send] = useUrqlMutation(SendPayrollDocument);
  const { Field, setFieldValue, submitForm, resetForm } = useForm<sendForm>({
    email: {
      initialValue: "",
      validate: combineValidators(validateRequired, validateEmail),
    },
    id: { initialValue: payrollId as string, validate: validateRequired },
    subject: { initialValue: "" },
    body: { initialValue: "" },
  });

  useEffect(() => {
    const subject = t("hr.sendPayroll.subject", { employee: payroll?.employee.name ?? "" });
    const body = t("hr.sendPayroll.quoteBody", {
      employee: payroll?.employee.name ?? "",
      month: dayjs(payroll?.periodStart).locale("es").format("MMMM"),
    });

    setFieldValue("email", payroll?.employee?.email ?? "");
    setFieldValue("subject", subject);
    setEditorState(initialState(body));
  }, [payroll]);

  const handleSubmit = () => {
    setLoading(true);
    submitForm(values => {
      send({
        input: {
          email: values.email as string,
          subject: values.subject as string,
          body: draftToHtml(convertToRaw(editorState.getCurrentContent())),
          payrollId: payrollId as string,
        },
      })
        .mapOk(data => {
          match(data.sendPayroll)
            .with({ __typename: "OperationInfo" }, handlerErrors)
            .otherwise(() => {
              showToast({
                variant: "success",
                title: t("hr.sendPayroll.success"),
                autoClose: true,
              });
              resetForm();
              onPressClose?.();
            });
          setLoading(false);
        })
        .mapError(() => {
          setLoading(false);
          showToast({ variant: "error", title: t("hr.sendPayroll.error"), autoClose: true });
        });
    });
  };

  const initialState = (body?: string) => {
    const text = htmlToDraft(decode(body));
    const contentState = ContentState.createFromBlockArray(text.contentBlocks);
    return EditorState.createWithContent(contentState);
  };

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  return (
    <LakeModal
      visible={visible}
      title={t("hr.sendPayroll.form")}
      maxWidth={740}
      onPressClose={onPressClose}
    >
      <ResponsiveContainer breakpoint={breakpoints.tiny}>
        {({ large }) => (
          <Box
            style={large && styles.container}
            onTouchEnd={e => {
              e.stopPropagation();
            }}
          >
            <Field name="email">
              {({ value, onChange }) => (
                <LakeLabel
                  label={t("common.email")}
                  render={() => (
                    <LakeTextInput
                      value={value}
                      placeholder={t("common.email.help")}
                      onChangeText={onChange}
                    />
                  )}
                />
              )}
            </Field>

            <Field name="subject">
              {({ value, onChange }) => (
                <LakeLabel
                  label={t("common.subject")}
                  render={() => <LakeTextInput value={value} onChangeText={onChange} />}
                />
              )}
            </Field>

            <Field name="body">
              {() => (
                <LakeLabel
                  label={t("invoice.send.bodyLabel")}
                  render={() => (
                    <Editor
                      editorState={editorState}
                      onEditorStateChange={setEditorState}
                      editorClassName="editorClassName"
                    />
                  )}
                />
              )}
            </Field>

            <Space height={32} />
            <Button onPress={handleSubmit}>{t("common.send")}</Button>
          </Box>
        )}
      </ResponsiveContainer>
    </LakeModal>
  );
};
