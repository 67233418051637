import { RightPanel } from "@components/RightPanel";
import { Box } from "@swan-io/lake/src/components/Box";
import { Space } from "@swan-io/lake/src/components/Space";
import { TabView } from "@swan-io/lake/src/components/TabView";
import { useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { Employee } from "../../../graphql/crm";
import { borderColorVariants, fontColorVariants } from "../../../styles/constants";
import { t } from "../../../utils/i18n";
import { EmployeeAvatar } from "./EmployeeAvatar";

const styles = StyleSheet.create({
  rightpanel: {
    paddingTop: 56,
    paddingLeft: 56,
    paddingRight: 27,
  },
  title: {
    fontWeight: "600",
  },
  subtitle: {
    fontSize: 12,
    color: fontColorVariants.gray500,
  },
  columns: {
    gap: 14,
  },
  column: {
    width: 225,
  },
  label: {
    fontSize: 12,
    color: fontColorVariants.neutral400,
    marginBottom: 4,
  },
  border: {
    fontSize: 12,
    color: fontColorVariants.neutral500,
    minHeight: 20,
    borderBottomWidth: 1,
    borderBottomColor: borderColorVariants.neutral500,
  },
});

type Props = {
  employee?: Employee;
  onPress?: () => void;
};

export const Tabs = ({ employee, onPress }: Props) => {
  const [activeTabId, setActiveTabId] = useState("payroll");

  return (
    <>
      <View>
        <TabView
          tabs={[
            {
              id: "generalData",
              label: t("hr.generalData"),
            },
            {
              id: "payroll",
              label: t("hr.payroll"),
            },
          ]}
          otherLabel=""
          activeTabId={activeTabId}
          onChange={id => {
            setActiveTabId(id);
            onPress?.();
          }}
        />
      </View>

      <RightPanel
        visible={activeTabId === "generalData"}
        overlay={true}
        onPressClose={() => setActiveTabId("payroll")}
      >
        <View style={styles.rightpanel}>
          <Text style={styles.title}>{t("hr.employeeProfile")}</Text>
          <Space height={4} />
          <Text style={styles.subtitle}>{t("hr.employeeData")}</Text>
          <Space height={48} />

          <Box direction="row" justifyContent="spaceBetween" style={styles.columns}>
            <EmployeeAvatar employee={employee} />

            <Box style={styles.column}>
              <Text style={styles.label}>{t("hr.name")}</Text>
              <Text style={styles.border}>{employee?.name}</Text>
              <Space height={16} />
              <Text style={styles.label}>{t("common.email")}</Text>
              <Text style={styles.border}>{employee?.email}</Text>
            </Box>
          </Box>

          <Space height={16} />
          <Text style={styles.label}>{t("hr.nif")}</Text>
          <Text style={styles.border}>{employee?.nif}</Text>
          <Space height={16} />
          <Text style={styles.label}>{t("hr.numSs")}</Text>
          <Text style={styles.border}>{employee?.numSs}</Text>
          <Space height={16} />
          <Text style={styles.label}>{t("hr.category")}</Text>
          <Text style={styles.border}>{employee?.categoryId}</Text>
        </View>
      </RightPanel>
    </>
  );
};
