import { Box } from "@swan-io/lake/src/components/Box";
import { StyleSheet, Text } from "react-native";
import { fontColorVariants } from "../styles/constants";

const styles = StyleSheet.create({
  heading: {
    padding: 16,
  },
  title: {
    color: fontColorVariants.gray500,
    fontSize: 34,
    fontWeight: "500",
    paddingHorizontal: 32,
  },
});

type Props = {
  title: string;
  children?: React.ReactNode;
};

export const Heading = ({ title, children }: Props) => (
  <Box style={styles.heading}>
    <Text style={styles.title}>{title}</Text>

    {children}
  </Box>
);
