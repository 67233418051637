import Chart, { ChartDataset } from "chart.js/auto";
import { useEffect, useRef, useState } from "react";
import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  lighting: {
    width: 70,
    filter: "drop-shadow(0 0 8px rgba(43, 63, 242, 1))",
  },
});

type Props = {
  datasets: ChartDataset[];
};

export const Growth = ({ datasets = [] }: Props) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);

  const [chart, setChart] = useState<Chart | null>(null);

  useEffect(() => {
    if (chartRef.current == null) {
      return;
    }

    const chart = new Chart(chartRef.current, {
      type: "doughnut",
      data: {
        labels: ["", ""],
        datasets: [],
      },
      options: {
        cutout: "75%",
        plugins: {
          legend: {
            display: false,
          },
        },
        events: [],
      },
    });
    // @ts-expect-error
    setChart(chart);
  }, []);

  useEffect(() => {
    if (chart != null) {
      chart.data.datasets = [
        {
          data: datasets?.[0]?.data ?? [],
          backgroundColor: ["rgb(28, 28, 30)", getGradient()],
          borderWidth: 0,
        },
      ];
      chart.update();
    }
  }, [datasets]);

  const getGradient = () => {
    if (chartRef.current == null) {
      return;
    }

    const ctx = chartRef.current.getContext("2d");
    if (ctx == null) {
      return;
    }

    const gradient1 = ctx.createRadialGradient(0, 0, 0, 0, 0, 100);
    gradient1.addColorStop(0, "#368AAA");
    gradient1.addColorStop(1, "#0C44B7");

    return gradient1;
  };

  return <canvas ref={chartRef} style={styles.lighting}></canvas>;
};
