import { Heading } from "@components/Heading";
import { Option } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import {
  FixedListViewEmpty,
  PlainListViewPlaceholder,
} from "@swan-io/lake/src/components/FixedListView";
import { FocusTrapRef } from "@swan-io/lake/src/components/FocusTrap";
import { LakeSearchField } from "@swan-io/lake/src/components/LakeSearchField";
import { ListRightPanel } from "@swan-io/lake/src/components/ListRightPanel";
import { Path, Svg } from "@swan-io/lake/src/components/Svg";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { spacings } from "@swan-io/lake/src/constants/design";
import { useUrqlPaginatedQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { useCallback, useMemo, useRef, useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { match } from "ts-pattern";
import { ContactsDocument } from "../../../graphql/crm";
import { t } from "../../../utils/i18n";
import { Router } from "../../../utils/routes";
import { COLORS } from "../../../values/colors";
import { ErrorView } from "./../../..//components/ErrorView";
import { Button, ButtonGroup } from "./../../../components/forms/Button";
import { InputRounded } from "./../../../components/forms/Input";
import { Create } from "./Create";
import { Detail } from "./Detail";
import { DetailsList } from "./DetailsList";
import { ImportWizard } from "./ImportWizard";
import { ContactFiltersState, ContactType, ListFilter } from "./ListFilter";

const styles = StyleSheet.create({
  header: {
    paddingHorizontal: spacings[24],
    paddingBottom: spacings[12],
    borderBottomWidth: 1,
    borderBottomColor: COLORS.WHITE,
  },
  container: {
    backgroundColor: COLORS.WHITE,
    padding: 8,
  },
  filters: {
    paddingBottom: 8,
  },
});

const AddUser = () => (
  <Svg width={14} height={13} viewBox="0 0 14 13">
    <Path
      d="M3.625 3.45312C3.625 2.21911 4.62536 1.21875 5.85938 1.21875C7.09339 1.21875 8.09375 2.21911 8.09375 3.45312C8.09375 4.68714 7.09339 5.6875 5.85938 5.6875C4.62536 5.6875 3.625 4.68714 3.625 3.45312Z"
      fill="currentColor"
    />

    <Path
      d="M2 10.3594C2 8.2279 3.7279 6.5 5.85938 6.5C7.99085 6.5 9.71875 8.2279 9.71875 10.3594V10.3608C9.71874 10.3823 9.71855 10.404 9.71819 10.4254C9.71584 10.5654 9.64153 10.6944 9.52155 10.7666C8.45163 11.4108 7.19814 11.7812 5.85938 11.7812C4.52061 11.7812 3.26712 11.4108 2.1972 10.7666C2.07722 10.6944 2.00291 10.5654 2.00056 10.4254C2.00019 10.4035 2 10.3814 2 10.3594Z"
      fill="currentColor"
    />

    <Path
      d="M10.9375 4.0625C10.9375 3.83813 10.7556 3.65625 10.5312 3.65625C10.3069 3.65625 10.125 3.83813 10.125 4.0625V5.28125H8.90625C8.68188 5.28125 8.5 5.46313 8.5 5.6875C8.5 5.91187 8.68188 6.09375 8.90625 6.09375H10.125V7.3125C10.125 7.53687 10.3069 7.71875 10.5312 7.71875C10.7556 7.71875 10.9375 7.53687 10.9375 7.3125V6.09375H12.1562C12.3806 6.09375 12.5625 5.91187 12.5625 5.6875C12.5625 5.46313 12.3806 5.28125 12.1562 5.28125H10.9375V4.0625Z"
      fill="currentColor"
    />
  </Svg>
);

const Import = () => (
  <Svg width={14} height={14} viewBox="0 0 24 24">
    <Path
      d="M18.25 3.51a.75.75 0 1 0 0-1.5h-13a.75.75 0 1 0 0 1.5h13ZM11.65 22h.1c.38 0 .7-.28.74-.64l.01-.1V7.56l3.72 3.72c.27.27.68.29.98.07l.08-.07a.75.75 0 0 0 .07-.98l-.07-.08-5-5a.75.75 0 0 0-.97-.07l-.09.07-5 5a.75.75 0 0 0 .98 1.13l.08-.07L11 7.58v13.67c0 .38.28.7.65.75Z"
      fill="currentColor"
    ></Path>
  </Svg>
);

const NUM_TO_RENDER = 20;

type Props = {
  create?: string;
  importVisible: boolean;
  search?: string;
  type?: ContactType[];
};

export const List = ({ create, importVisible, search, type }: Props) => {
  const filters: ContactFiltersState = useMemo(() => {
    return { type, search } as const;
  }, [type, search]);

  const { data, nextData, reload, setAfter } = useUrqlPaginatedQuery(
    {
      query: ContactsDocument,
      variables: {
        first: NUM_TO_RENDER,
        filters: {
          type: { inList: type },
          search: search ?? "",
        },
      },
    },
    [filters],
  );

  const contacts = data
    .toOption()
    .flatMap(data => data.toOption())
    .flatMap(({ contacts }) => Option.fromNullable(contacts))
    .map(({ edges }) => edges.map(({ node }) => node))
    .getWithDefault([]);

  const [activeContactId, setActiveContactId] = useState<string | null>(null);

  const panelRef = useRef<FocusTrapRef | null>(null);

  const onActiveRowChange = useCallback((element: HTMLElement) => {
    return panelRef.current?.setInitiallyFocusedElement(element);
  }, []);

  const onClose = () => {
    setActiveContactId(null);
    reload();
  };

  return (
    <>
      <View style={commonStyles.fill} role="main">
        <Box
          direction="row"
          alignItems="center"
          justifyContent="spaceBetween"
          style={styles.header}
        >
          <InputRounded>
            <LakeSearchField
              placeholder={t("common.search")}
              initialValue={filters.search ?? ""}
              onChangeText={search => Router.push("CrmContactsList", { ...filters, search })}
            />
          </InputRounded>

          <ButtonGroup>
            <Button
              style="group"
              icon={<AddUser />}
              reverse={true}
              onPress={() => Router.push("CrmContactsList", { type, new: "" })}
            >
              {t("common.new")}{" "}
              {match(type)
                .with(["customer"], () => t("contact.customer").toLowerCase())
                .with(["supplier"], () => t("contact.supplier").toLowerCase())
                .otherwise(() => t("contact").toLowerCase())}
            </Button>

            <Button
              style="group"
              icon={<Import />}
              reverse={true}
              onPress={() => Router.push("CrmContactsList", { type, import: "" })}
            >
              {t("common.import")}{" "}
              {match(type)
                .with(["customer"], () => t("common.customers").toLowerCase())
                .with(["supplier"], () => t("common.suppliers").toLowerCase())
                .otherwise(() => t("contacts").toLowerCase())}
            </Button>
          </ButtonGroup>
        </Box>

        <Heading
          title={match(type)
            .with(["customer"], () => t("common.customers"))
            .with(["supplier"], () => t("common.suppliers"))
            .otherwise(() => t("contacts"))}
        />

        <Box style={styles.container}>
          <Box direction="row" justifyContent="end" alignItems="end" style={styles.filters}>
            <ListFilter
              filters={filters}
              onChange={filters => Router.push("CrmContactsList", filters)}
            />
          </Box>

          {data.match({
            NotAsked: () => null,
            Loading: () => (
              <PlainListViewPlaceholder
                count={NUM_TO_RENDER}
                rowVerticalSpacing={0}
                headerHeight={48}
                rowHeight={48}
              />
            ),
            Done: result =>
              result.match({
                Error: error => <ErrorView error={error} />,
                Ok: data => (
                  <DetailsList
                    contacts={contacts}
                    onActiveRowChange={onActiveRowChange}
                    activeRowId={activeContactId ?? undefined}
                    onEndReached={() => {
                      if (data?.contacts.pageInfo.hasNextPage === true) {
                        setAfter(data?.contacts?.pageInfo.endCursor ?? undefined);
                      }
                    }}
                    loading={{ isLoading: nextData.isLoading(), count: NUM_TO_RENDER }}
                    getRowLink={({ item }) => (
                      <Pressable onPress={() => setActiveContactId(item.id as string)} />
                    )}
                    renderEmptyList={() => (
                      <FixedListViewEmpty
                        icon="lake-transfer"
                        borderedIcon={true}
                        title={t("common.empty")}
                      />
                    )}
                  />
                ),
              }),
          })}
        </Box>
      </View>

      <Create
        visibleParam={create}
        onRefreshRequest={() => {
          reload();
          onClose();
        }}
      />

      <ListRightPanel
        ref={panelRef}
        keyExtractor={item => item.id as string}
        activeId={activeContactId}
        onActiveIdChange={setActiveContactId}
        onClose={onClose}
        items={contacts}
        render={(contact, large) => <Detail large={large} contact={contact} onClose={onClose} />}
        closeLabel={t("common.closeButton")}
        previousLabel={t("common.next")}
        nextLabel={t("common.back")}
      />

      <LakeModal
        title={t("contact.import.title")}
        visible={importVisible}
        maxWidth={1000}
        onPressClose={() => {
          Router.push("CrmContactsList");
          reload();
        }}
      >
        <ImportWizard
          onPressClose={() => {
            Router.push("CrmContactsList");
            reload();
          }}
        />
      </LakeModal>
    </>
  );
};
