import { NavigationItem } from "@components/NavigationItem";
import { NavigationSubitem } from "@components/NavigationSubitem";
import { StyleSheet, View } from "react-native";
import { match } from "ts-pattern";
import { t } from "../../../utils/i18n";
import { Router } from "../../../utils/routes";
import { checkRule } from "../../../utils/subscription";
import { useUser } from "../../context/UserContext";

const styles = StyleSheet.create({
  submenu: {
    marginBottom: 12,
  },
});

export const Navigation = () => {
  const { subscription } = useUser();
  const route = Router.useRoute([
    "InvoicesSalesList",
    "InvoicesPurchaseList",
    "InvoicesTicketList",
  ]);

  return (
    <View role="navigation">
      <NavigationItem to={Router.InvoicesSalesList()} label={t("invoices.salesInvoices")} />

      {match(route)
        .with({ name: "InvoicesSalesList" }, () => (
          <View style={styles.submenu}>
            <NavigationSubitem
              to={Router.InvoicesSalesCreate()}
              icon="add-circle-regular"
              label={t("invoices.createSales")}
            />

            <NavigationSubitem
              to={Router.InvoicesSalesProformaCreate()}
              icon="add-circle-regular"
              label={t("invoices.createProforma")}
              isLocked={!checkRule(subscription, "has_proforma")}
            />

            <NavigationSubitem
              to={Router.InvoicesSalesQuoteCreate()}
              icon="add-circle-regular"
              label={t("invoices.createQuote")}
              isLocked={!checkRule(subscription, "has_quote")}
            />

            <NavigationSubitem
              to={Router.InvoicesSalesDeliveryCreate()}
              icon="add-circle-regular"
              label={t("invoices.createDelivery")}
              isLocked={!checkRule(subscription, "has_delivery")}
            />
          </View>
        ))
        .otherwise(() => null)}

      <NavigationItem to={Router.InvoicesPurchaseList()} label={t("invoices.purchaseInvoices")} />

      {match(route)
        .with({ name: "InvoicesPurchaseList" }, () => (
          <View style={styles.submenu}>
            <NavigationSubitem
              to={Router.InvoicesPurchaseCreate()}
              icon="add-circle-regular"
              label={t("invoices.createPurchase")}
            />
          </View>
        ))
        .otherwise(() => null)}

      <NavigationItem to={Router.InvoicesProductsList()} label={t("invoices.productsServices")} />
      <NavigationItem to={Router.InvoicesTicketList()} label={t("invoices.tickets")} />

      {match(route)
        .with({ name: "InvoicesTicketList" }, () => (
          <View style={styles.submenu}>
            <NavigationSubitem
              to={Router.InvoicesTicketCreate()}
              icon="add-circle-regular"
              label={t("invoices.createTicket")}
            />
          </View>
        ))
        .otherwise(() => null)}
    </View>
  );
};
