import { DecimalInput } from "@components/DecimalInput";
import { RightPanel } from "@components/RightPanel";
import { Button } from "@components/forms/Button";
import Select from "@components/forms/Select";
import { Option } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { Space } from "@swan-io/lake/src/components/Space";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { useEffect, useState } from "react";
import { GestureResponderEvent, Pressable, StyleSheet, Text, View } from "react-native";
import { useForm } from "react-ux-form";
import {
  Product,
  ProductsDocument,
  SalesCategory,
  SalesInvoiceCreateStaticDocument,
} from "../../../graphql/crm";
import {
  backgroundColorVariants,
  borderColorVariants,
  fontColorVariants,
} from "../../../styles/constants";
import { t } from "../../../utils/i18n";
import { CloseIcon } from "../../../utils/icons";
import {
  passValidatorTrue,
  validateNullableRequired,
  validateNumericNullableRequired,
  validateRequired,
} from "../../../utils/validations";
import { useNestedForm } from "../../components/NestedForm";
import { Concept, CreateSalesConceptState } from "../types";
import { extractSalesStatic } from "../utils";
import { ConceptTotal } from "./ConceptTotal";
import { ProductCreate } from "./ProductCreate";
import { SalesRightPanel } from "./SalesRightPanel";

const styles = StyleSheet.create({
  line: {
    backgroundColor: backgroundColorVariants.neutral50,
    borderWidth: 1,
    borderColor: borderColorVariants.neutral200,
    borderRadius: 8,
    padding: 12,
  },
  lineSelected: {
    backgroundColor: backgroundColorVariants.primary100,
    borderWidth: 2,
    borderColor: borderColorVariants.primary500,
    borderRadius: 8,
    padding: 12,
  },
  rightpanel: {
    paddingTop: 40,
    paddingLeft: 56,
    paddingRight: 27,
  },
  red: {
    color: fontColorVariants.destructive500,
  },
  column: {
    flexBasis: "calc(50% - 12px)",
  },
  actions: {
    flexDirection: "column",
    alignItems: "stretch",
  },
  action: {
    flexDirection: "row",
    alignItems: "center",
    gap: 4,
    marginTop: 4,
  },
  actionText: {
    fontSize: 12,
    fontWeight: "500",
    color: fontColorVariants.neutral500,
  },
  firstLine: {
    display: "grid",
    gridTemplateColumns: "3fr 1fr 1.5fr 1.5fr 1.5fr",
    gap: 12,
  },
  secondLine: {
    display: "grid",
    gridTemplateColumns: "2fr 2fr 1fr 1.5fr",
    gap: 12,
  },
  gap: {
    gap: 8,
  },
  options: {
    height: 0,
    overflow: "hidden",
    transitionDuration: "0.3s",
    transitionProperty: "height",
  },
  optionsOpened: {
    height: "180px",
  },
  moreOptions: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },
});

type Props = {
  large?: boolean;
  concept: Concept;
  deleteDisabled: boolean;
  onChange: (concept: Concept) => void;
  onDelete: () => void;
  isActive: boolean;
  setCurrentId: () => void;
  disabled?: boolean;
  saveDraft: boolean;
};

export const SalesConceptItem = ({
  large = true,
  concept,
  deleteDisabled,
  onChange,
  onDelete,
  isActive,
  setCurrentId,
  disabled = false,
  saveDraft,
}: Props) => {
  const [showProductCreate, setShowProductCreate] = useState(false);

  const [moreOptions, setMoreOptions] = useState(false);
  const [open, setOpen] = useState(false);

  const { Field, setFieldValue, getFieldState, listenFields, submitForm } =
    useForm<CreateSalesConceptState>({
      id: { initialValue: concept.id },
      productId: {
        initialValue: () => {
          if (concept.productId === -1 && concept.description) {
            concept.productId = undefined;
            return -1;
          } else if (concept.productId !== -1) {
            return concept.productId;
          }
        },
        validate: passValidatorTrue(validateNumericNullableRequired, saveDraft),
      },
      description: {
        initialValue: concept.description,
        validate: passValidatorTrue(validateRequired, saveDraft),
      },
      unitType: { initialValue: concept.unitType ?? "" },
      unitPrice: { initialValue: concept.unitPrice ?? "" },
      price: { initialValue: concept.price },
      vat: {
        initialValue: concept.vat ?? "",
        validate: passValidatorTrue(validateRequired, saveDraft),
      },
      quantity: {
        initialValue: concept.quantity ?? 1,
        validate: passValidatorTrue(validateNumericNullableRequired, saveDraft),
      },
      irpf: { initialValue: concept.irpf ?? "" },
      discountAmount: { initialValue: concept.discountAmount || 0 },
      discountPercentage: { initialValue: concept.discountPercentage || 0 },
      expenseType: { initialValue: concept.expenseType ?? "corriente" },
      type: { initialValue: concept.type ?? false },
      subtotal: { initialValue: concept.subtotal ?? "" },
      category: {
        initialValue: concept?.category,
        validate: passValidatorTrue(validateNullableRequired, saveDraft),
      },
      subcategory: {
        initialValue: concept?.subcategory,
        validate: passValidatorTrue(validateNullableRequired, saveDraft),
      },
    });

  useEffect(() => {
    setFieldValue("subtotal", concept.subtotal ?? "");
    setSubtotalAux(concept.subtotal ?? "");
  }, [concept.subtotal]);

  useEffect(() => {
    setFieldValue("unitPrice", concept.unitPrice ?? "");
    setUnitPriceAux(concept.unitPrice ?? "");
  }, [concept.unitPrice]);

  const { data } = useUrqlQuery({ query: SalesInvoiceCreateStaticDocument }, []);

  const { data: productsQuery, reload } = useUrqlQuery(
    {
      query: ProductsDocument,
      variables: {
        first: 100 as never,
        filters: {} as never,
      },
    },
    [],
  );

  const products = productsQuery
    .toOption()
    .flatMap(data => data.toOption())
    .flatMap(({ products }) => Option.fromNullable(products))
    .map(products => {
      if ("edges" in products && Array.isArray(products.edges)) {
        return products.edges.map(({ node }) => node as unknown as Product);
      } else if (Array.isArray(products)) {
        return products;
      }
      return [];
    })
    .getWithDefault([]) as Product[] | { id: number; description: string }[];

  const productsItems = products?.map(product => ({
    name: product.description,
    value: Number(product.id),
  }));
  productsItems.unshift({ name: "Manual", value: -1 });

  const irpfType = extractSalesStatic(data, "irpfType");
  const unitType = extractSalesStatic(data, "unitType");
  const vatType = extractSalesStatic(data, "vatType");
  const discountType = extractSalesStatic(data, "discountType");

  useEffect(() => {
    const removeListenerProduct = listenFields(["productId"], ({ productId }) => {
      if (!products.length || concept.productId === productId?.value) {
        return;
      }
      const prod = products.filter(p => p.id === productId?.value?.toString())?.[0] as Product;
      if (prod !== undefined) {
        setFieldValue("description", prod.description ?? "");
        setFieldValue("unitType", prod.unitType ?? "");
        setFieldValue("unitPrice", prod.unitPrice as string);
        setFieldValue("vat", prod.vat);
        setFieldValue("irpf", prod.irpf ?? "");
        setFieldValue("subtotal", "");
        setFieldValue("category", (prod.category as SalesCategory) ?? "");
        setFieldValue("subcategory", (prod.subcategory as string) ?? "");
      }
    });

    return () => removeListenerProduct();
  }, [products, concept.productId, listenFields, setFieldValue]);

  useEffect(() => {
    const removeListener = listenFields(
      [
        "productId",
        "description",
        "quantity",
        "unitType",
        "vat",
        "irpf",
        "discountAmount",
        "discountPercentage",
        "category",
        "subcategory",
        "price",
      ],
      () => submitForm(values => onChange(values as Concept)),
    );

    return () => removeListener();
  }, [listenFields, onChange, submitForm]);

  const [discountTypeValue, setDiscountTypeValue] = useState("percentage");

  const [unitPriceAux, setUnitPriceAux] = useState<string>(String(concept.unitPrice ?? ""));
  const [subtotalAux, setSubtotalAux] = useState<string>(String(concept.subtotal ?? ""));

  useEffect(() => {
    if (!unitPriceAux && !subtotalAux) {
      return;
    }

    setFieldValue("unitPrice", unitPriceAux);
    setFieldValue("subtotal", subtotalAux);
    submitForm(values => onChange(values as Concept));
  }, [unitPriceAux, subtotalAux]);

  useEffect(() => {
    setOpen(isActive);
  }, [isActive]);

  useEffect(() => {
    submitForm(values => onChange(values as Concept));
  }, [saveDraft]);

  const { addForm, addError } = useNestedForm();

  useEffect(() => {
    addForm(() => {
      submitForm(
        values => onChange(values as Concept),
        errors => {
          addError("error");
          if (errors.category != undefined || errors.subcategory != undefined) {
            setCurrentId();
            setOpen(true);
          }
        },
      );
    });
  }, []);

  const stopPropagation = (event: GestureResponderEvent) => {
    event.stopPropagation();
  };

  return (
    <>
      <Pressable
        style={isActive ? styles.lineSelected : styles.line}
        onPress={() => {
          setCurrentId();
          setOpen(true);
        }}
      >
        <Field name="id">{() => null}</Field>
        <Field name="type">{() => null}</Field>
        <Field name="category">{() => null}</Field>
        <Field name="subcategory">{() => null}</Field>

        <Box
          direction={large ? "row" : "column"}
          alignItems={large ? "start" : "stretch"}
          justifyContent="spaceBetween"
          style={styles.firstLine}
        >
          <View>
            <Field name="description">
              {({ value, onChange, error }) => {
                const { value: productId } = getFieldState("productId");
                if (productId === -1) {
                  return (
                    <LakeLabel
                      label={t("invoices.description")}
                      style={styles.column}
                      render={id => (
                        <Pressable onPress={stopPropagation}>
                          <LakeTextInput
                            id={id}
                            value={value}
                            error={error}
                            onChangeText={onChange}
                            disabled={disabled}
                          />
                        </Pressable>
                      )}
                    />
                  );
                }
                return <></>;
              }}
            </Field>

            <Field name="productId">
              {({ value, onChange, error }) => {
                if (value === -1) {
                  return <></>;
                }
                return (
                  <LakeLabel
                    label={t("invoices.productOrService")}
                    style={[styles.column, styles.actions]}
                    render={() => (
                      <LakeSelect
                        items={productsItems}
                        value={value}
                        hideErrors={error === undefined}
                        error={error}
                        onValueChange={onChange}
                        disabled={disabled}
                      />
                    )}
                    actions={
                      <Pressable style={styles.action} onPress={() => setShowProductCreate(true)}>
                        <Icon name="add-filled" size={12} color={fontColorVariants.neutral500} />
                        <Text style={styles.actionText}>{t("invoices.newProduct")}</Text>
                      </Pressable>
                    }
                  />
                );
              }}
            </Field>
          </View>

          <ProductCreate
            visible={showProductCreate}
            onPressClose={() => {
              setShowProductCreate(false);
              reload();
            }}
          />

          <Field name="quantity">
            {({ value, onChange, error }) => (
              <LakeLabel
                label={t("invoices.quantity")}
                style={styles.column}
                extra={() => <Text style={styles.red}>*</Text>}
                render={id => (
                  <Pressable onPress={stopPropagation}>
                    <DecimalInput
                      id={id}
                      value={String(value)}
                      error={error}
                      onChangeDecimal={nextValue => {
                        setFieldValue("subtotal", "");
                        onChange(Number(nextValue));
                      }}
                      disabled={disabled}
                    />
                  </Pressable>
                )}
              />
            )}
          </Field>

          <Field name="unitType">
            {Select({
              label: t("invoices.unitType"),
              items: unitType,
              disabled,
              hasEmptyRow: true,
            })}
          </Field>

          <Field name="unitPrice">
            {({ error }) => (
              <LakeLabel
                label={t("invoices.unitPrice")}
                style={styles.column}
                extra={() => <Text style={styles.red}>*</Text>}
                render={id => (
                  <Pressable onPress={stopPropagation}>
                    <DecimalInput
                      id={id}
                      value={unitPriceAux}
                      error={error}
                      onChangeDecimal={nextValue => {
                        setSubtotalAux("");
                        setUnitPriceAux(nextValue);
                      }}
                      disabled={disabled}
                    />
                  </Pressable>
                )}
              />
            )}
          </Field>

          <Field name="subtotal">
            {({ error }) => (
              <LakeLabel
                label={t("invoices.price")}
                style={styles.column}
                extra={() => <Text style={styles.red}>*</Text>}
                render={id => (
                  <DecimalInput
                    id={id}
                    value={subtotalAux}
                    error={error}
                    onChangeDecimal={nextValue => {
                      setUnitPriceAux("");
                      setSubtotalAux(nextValue);
                    }}
                    disabled={disabled}
                  />
                )}
              />
            )}
          </Field>
        </Box>

        <Box
          direction={large ? "row" : "column"}
          alignItems={large ? "start" : "stretch"}
          justifyContent="spaceBetween"
          style={styles.secondLine}
        >
          <Field name="vat">
            {Select({
              label: t("invoices.iva"),
              items: vatType,
              disabled,
            })}
          </Field>

          <Field name="irpf">
            {Select({
              label: t("invoices.irpf"),
              items: irpfType,
              disabled,
              hasEmptyRow: true,
            })}
          </Field>

          {discountTypeValue === "amount" && (
            <Field name="discountAmount">
              {({ value, onChange, error }) => (
                <LakeLabel
                  label={t("invoices.discount")}
                  style={styles.column}
                  render={id => (
                    <Pressable onPress={stopPropagation}>
                      <DecimalInput
                        id={id}
                        value={value.toString()}
                        hideErrors={error === undefined}
                        error={error}
                        onChangeDecimal={nextValue => {
                          onChange(Number(nextValue));
                        }}
                        disabled={disabled}
                      />
                    </Pressable>
                  )}
                />
              )}
            </Field>
          )}

          {discountTypeValue === "percentage" && (
            <Field name="discountPercentage">
              {({ value, onChange, error }) => (
                <LakeLabel
                  label={t("invoices.discount")}
                  style={styles.column}
                  render={id => (
                    <Pressable onPress={stopPropagation}>
                      <DecimalInput
                        id={id}
                        value={value.toString()}
                        hideErrors={error === undefined}
                        error={error}
                        onChangeDecimal={value => {
                          onChange(Number(value));
                        }}
                        disabled={disabled}
                      />
                    </Pressable>
                  )}
                />
              )}
            </Field>
          )}

          <LakeLabel
            label={t("invoices.discountType")}
            render={() => (
              <LakeSelect
                items={discountType}
                value={discountTypeValue}
                onValueChange={value => {
                  setFieldValue("discountAmount", 0);
                  setFieldValue("discountPercentage", 0);
                  setDiscountTypeValue(value);
                }}
                hideErrors={true}
                disabled={disabled}
              />
            )}
          />
        </Box>

        <Box style={[styles.options, moreOptions && styles.optionsOpened]}>
          <Space height={24} />

          <Box style={styles.moreOptions}>
            <View></View>

            <View>
              <ConceptTotal concept={concept} />
            </View>
          </Box>
        </Box>

        <Space height={24} />

        <Box direction="row" justifyContent="end" style={styles.gap}>
          <Button
            onPress={() => setMoreOptions(!moreOptions)}
            mode="tertiary"
            icon={
              moreOptions ? (
                <Icon name="arrow-up-regular" size={16} />
              ) : (
                <Icon name="arrow-down-regular" size={16} />
              )
            }
            reverse={true}
          >
            {t("invoices.viewTotal")}
          </Button>

          <Button
            onPress={onDelete}
            mode="tertiary"
            disabled={deleteDisabled}
            icon={<Icon name="delete-regular" size={16} />}
            reverse={true}
          >
            {t("invoices.removeItem")}
          </Button>
        </Box>
      </Pressable>

      <RightPanel visible={isActive && open} onPressClose={() => setOpen(false)}>
        <View style={styles.rightpanel}>
          <Pressable onPress={() => setOpen(false)}>
            <CloseIcon />
          </Pressable>

          <SalesRightPanel
            form={{
              Field,
              setFieldValue,
              getFieldState,
              listenFields,
              onChange: () => submitForm(values => onChange(values as Concept)),
            }}
          />
        </View>
      </RightPanel>
    </>
  );
};
