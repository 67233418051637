import { CompanyInfo } from "@components/CompanyInfo";
import { Sidebar } from "@components/Sidebar";
import { UserCompanyNavigation } from "@components/UserCompanyNavigation";
import { WithPartnerAccentColor } from "@swan-io/lake/src/components/WithPartnerAccentColor";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { useResponsive } from "@swan-io/lake/src/hooks/useResponsive";
import { SkipToContent } from "@swan-io/shared-business/src/components/SkipToContent";
import { ScrollView, StyleSheet, View } from "react-native";
import { useUser } from "../features/context/UserContext";
import { COLORS } from "../values/colors";

const styles = StyleSheet.create({
  background: {
    flexShrink: 1,
    flexGrow: 1,
    backgroundColor: COLORS.BACKGROUND,
  },
  container: {
    flexShrink: 1,
    flexGrow: 1,
  },
  desktopContainer: {
    flexDirection: "row",
    width: "100%",
    marginHorizontal: "auto",
  },
  root: {
    ...commonStyles.fill,
    paddingTop: 24,
  },
});

export const Company = () => {
  const { desktop } = useResponsive();

  const { company } = useUser();

  return (
    <WithPartnerAccentColor color="#498AF2">
      <SkipToContent />

      <View style={styles.background}>
        <View style={[styles.container, desktop && styles.desktopContainer]}>
          {desktop && (
            <Sidebar>
              <UserCompanyNavigation />
            </Sidebar>
          )}

          <View style={styles.root}>
            <ScrollView style={commonStyles.fill} role="main">
              {company && <CompanyInfo company={company} />}
            </ScrollView>
          </View>
        </View>
      </View>
    </WithPartnerAccentColor>
  );
};
