import { Dict } from "@swan-io/boxed";
import { FilterChooser } from "@swan-io/lake/src/components/FilterChooser";
import {
  FilterCheckboxDef,
  FilterDateDef,
  FiltersStack,
  FiltersState,
} from "@swan-io/lake/src/components/Filters";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { useEffect, useMemo, useState } from "react";
import { ContractType, Static } from "../../../graphql/crm";
import { locale, t } from "../../../utils/i18n";
import { staticToItem, staticToItemLower } from "../../../utils/utils";
import {
  isAfterEmitedAtSelectable,
  isBeforeEmitedAtSelectable,
  validateAfterEmitedAt,
  validateBeforeEmitedAt,
} from "../../../utils/validations";

type Props = {
  filters: EmployeeFiltersState;
  onChange: (values: Partial<EmployeeFiltersState>) => void;
  categories: Static[];
  contractTypes: Static[];
};

const startDateFromFilter: FilterDateDef = {
  type: "date",
  label: t("hr.filters.startDateFrom"),
  cancelText: t("common.cancel"),
  submitText: t("common.filters.apply"),
  noValueText: t("common.none"),
  dateFormat: locale.dateFormat,
  validate: validateAfterEmitedAt,
  isSelectable: isAfterEmitedAtSelectable,
};

const startDateToFilter: FilterDateDef = {
  type: "date",
  label: t("hr.filters.startDateTo"),
  cancelText: t("common.cancel"),
  submitText: t("common.filters.apply"),
  noValueText: t("common.none"),
  dateFormat: locale.dateFormat,
  validate: validateBeforeEmitedAt,
  isSelectable: isBeforeEmitedAtSelectable,
};

const endDateFromFilter: FilterDateDef = {
  type: "date",
  label: t("hr.filters.endDateFrom"),
  cancelText: t("common.cancel"),
  submitText: t("common.filters.apply"),
  noValueText: t("common.none"),
  dateFormat: locale.dateFormat,
  validate: validateAfterEmitedAt,
  isSelectable: isAfterEmitedAtSelectable,
};

const endDateToFilter: FilterDateDef = {
  type: "date",
  label: t("hr.filters.endDateTo"),
  cancelText: t("common.cancel"),
  submitText: t("common.filters.apply"),
  noValueText: t("common.none"),
  dateFormat: locale.dateFormat,
  validate: validateBeforeEmitedAt,
  isSelectable: isBeforeEmitedAtSelectable,
};

const categoryFilter: FilterCheckboxDef<string> = {
  type: "checkbox",
  label: t("hr.category"),
  submitText: t("common.filters.apply"),
  checkAllLabel: t("common.filters.all"),
  items: [],
};

const contractTypeFilter: FilterCheckboxDef<string> = {
  type: "checkbox",
  label: t("hr.contractType"),
  submitText: t("common.filters.apply"),
  checkAllLabel: t("common.filters.all"),
  items: [],
};

const filtersDefinition = {
  startDateFrom: startDateFromFilter,
  startDateTo: startDateToFilter,
  endDateFrom: endDateFromFilter,
  endDateTo: endDateToFilter,
  category: categoryFilter,
  contractType: contractTypeFilter,
};

export type EmployeeFiltersState = FiltersState<typeof filtersDefinition> & {
  search?: string | undefined;
};

export const ListFilter = ({ filters, onChange, categories, contractTypes }: Props) => {
  const filtersWithoutSearch = useMemo(() => {
    const { search, ...filtersWithoutSearch } = filters;
    return filtersWithoutSearch;
  }, [filters]);

  categoryFilter.items = staticToItemLower<string>(categories);
  contractTypeFilter.items = staticToItem<ContractType>(contractTypes);

  const availableFilters: { name: keyof typeof filtersWithoutSearch; label: string }[] = useMemo(
    () => [
      {
        name: "startDateFrom",
        label: t("hr.filters.startDateFrom"),
      },
      {
        name: "startDateTo",
        label: t("hr.filters.startDateTo"),
      },
      {
        name: "endDateFrom",
        label: t("hr.filters.endDateFrom"),
      },
      {
        name: "endDateTo",
        label: t("hr.filters.endDateTo"),
      },
      {
        name: "category",
        label: t("hr.category"),
      },
      {
        name: "contractType",
        label: t("hr.contractType"),
      },
    ],
    [],
  );

  const [openFilters, setOpenFilters] = useState(() =>
    Dict.entries(filtersWithoutSearch)
      .filter(([, value]) => isNotNullish(value))
      .map(([name]) => name),
  );

  useEffect(() => {
    setOpenFilters(openFilters => {
      const currentlyOpenFilters = new Set(openFilters);
      const openFiltersNotYetInState = Dict.entries(filtersWithoutSearch)
        .filter(([name, value]) => isNotNullish(value) && !currentlyOpenFilters.has(name))
        .map(([name]) => name);
      return [...openFilters, ...openFiltersNotYetInState];
    });
  }, [filtersWithoutSearch]);

  return (
    <>
      <FiltersStack
        definition={filtersDefinition}
        filters={filters}
        openedFilters={openFilters}
        onChangeFilters={value => onChange({ ...value })}
        onChangeOpened={setOpenFilters}
      />

      <FilterChooser
        filters={filtersWithoutSearch}
        openFilters={openFilters}
        label={t("common.filters")}
        title={t("common.chooseFilter")}
        onAddFilter={filter => setOpenFilters(openFilters => [...openFilters, filter])}
        availableFilters={availableFilters}
      />
    </>
  );
};
