import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { colors, spacings } from "@swan-io/lake/src/constants/design";
import { StyleSheet, View } from "react-native";
import { formatCurrency } from "../../utils/i18n";
import { getAlign } from "../../utils/utils";

const styles = StyleSheet.create({
  cell: {
    display: "flex",
    paddingHorizontal: spacings[8],
    flexGrow: 1,
    flexDirection: "row",
    alignItems: "center",
    width: 1,
    justifyContent: "center",
  },
});

type Props = {
  price: null | number;
  align?: "center" | "left" | "right";
};

export const PriceCell = ({ price, align }: Props) => {
  const priceNumber = Number(price);
  const data =
    price !== null && !Number.isNaN(priceNumber) ? formatCurrency(priceNumber, "EUR") : "-";
  return (
    <View
      style={{
        ...styles.cell,
        justifyContent: getAlign(align) as
          | "center"
          | "flex-start"
          | "flex-end"
          | "space-between"
          | "space-around"
          | "space-evenly",
      }}
    >
      <LakeText align="center" variant="smallMedium" color={colors.gray[600]}>
        {data}
      </LakeText>
    </View>
  );
};
