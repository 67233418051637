import { AutoWidthImage } from "@swan-io/lake/src/components/AutoWidthImage";
import { Box } from "@swan-io/lake/src/components/Box";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { Space } from "@swan-io/lake/src/components/Space";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { useState } from "react";
import { Pressable, ScrollView, StyleSheet, Text, View } from "react-native";
import { useForm } from "react-ux-form";
import { match } from "ts-pattern";
import background from "../assets/img/bg-option.svg";
import flower from "../assets/img/flower.svg";
import flowers from "../assets/img/flowers.svg";
import logo from "../assets/img/logo-trexx.svg";
import Input from "../components/forms/Input";
import { CompanyQuery, CompanyStaticDocument, Static, UpdateCompanyDocument } from "../graphql/crm";
import { common } from "../styles/common";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { FormCompanyState } from "../utils/types";
import { extractQueryData, validateRequired } from "../utils/validations";
import { COLORS } from "../values/colors";
import { Button } from "./forms/Button";
import Select from "./forms/Select";

const styles = StyleSheet.create({
  page: {
    padding: 64,
  },
  title: {
    fontSize: 28,
    fontWeight: "500",
    textWrap: "balance",
  },
  options: {
    gap: 20,
    marginVertical: 48,
  },
  option: {
    gap: 12,
    borderRadius: 20,
    padding: 22,
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
  },
  optionTitle: {
    fontSize: 22,
  },
  icon: {
    position: "absolute",
    top: 0,
    right: 0,
    backgroundColor: COLORS.WHITE,
    padding: 12,
    borderRadius: 50,
    transform: "rotate(-45deg)",
  },
  card: {
    width: "100%",
    maxWidth: 472,
    minHeight: 660,
    marginHorizontal: "auto",
    padding: 40,
    borderRadius: 16,
    boxShadow:
      "0 1.871px 1.497px 0 rgba(108, 73, 172, 0.02), 0 4.496px 3.597px 0 rgba(108, 73, 172, 0.03), 0 8.466px 6.773px 0 rgba(108, 73, 172, 0.04), 0 15.101px 12.081px 0 rgba(108, 73, 172, 0.04), 0 28.246px 22.596px 0 rgba(108, 73, 172, 0.05), 0 67.609px 54.087px 0 rgba(108, 73, 172, 0.07)",
  },
  form: {
    gap: 32,
    marginVertical: 24,
  },
});

const CONSTITUTION = {
  AUTONOMO: "autonomo",
  EMPRESA: "empresa",
};

export function OnboardingCompany({ data }: { data: CompanyQuery }) {
  const { company } = data;

  const { data: staticData } = useUrqlQuery({ query: CompanyStaticDocument }, []);

  const status = extractQueryData(staticData, "value.value.companyStatic.status") as Static[];
  const types = extractQueryData(staticData, "value.value.companyStatic.types") as Static[];
  const employees = extractQueryData(staticData, "value.value.companyStatic.employees") as Static[];

  const { Field, submitForm } = useForm<FormCompanyState>({
    name: {
      initialValue: company?.name ?? "",
      sanitize: value => value.trim(),
      validate: validateRequired,
    },
    nif: { initialValue: company?.nif ?? "", validate: validateRequired },
    phone: {
      initialValue: company?.phone ?? "",
      validate: validateRequired,
    },
    constitution: { initialValue: company?.constitution ?? "", validate: validateRequired },
    companyType: { initialValue: company?.companyType ?? "", validate: validateRequired },
    numEmployees: { initialValue: company?.numEmployees ?? "", validate: validateRequired },
    status: { initialValue: company?.status ?? "", validate: validateRequired },
  });

  const [, updateCompany] = useUrqlMutation(UpdateCompanyDocument);

  const handleSubmit = () => {
    submitForm(values => {
      updateCompany({
        input: {
          id: company?.id,
          name: values.name ?? "",
          nif: values.nif,
          phone: values.phone,
          constitution,
          companyType: values.companyType,
          numEmployees: values.numEmployees,
          status: values.status,
        },
      }).mapOk(data => {
        match(data.updateCompany)
          .with({ __typename: "OperationInfo" }, data => {
            showToast({
              variant: "error",
              title: data.messages[0]?.message ?? "",
              autoClose: true,
            });
          })
          .otherwise(() => {
            Router.push("PlansPage");
          });
      });
    });
  };

  const [constitution, setConstitution] = useState("");

  return (
    <>
      <ScrollView centerContent={true} style={styles.page}>
        <AutoWidthImage sourceUri={logo} height={48} alt="Trexx" />

        <Box direction={"row"} style={common.h100}>
          <ScrollView centerContent={true} style={common.bgWhite}>
            <Box justifyContent="spaceBetween" style={styles.card}>
              {match(constitution)
                .with(CONSTITUTION.AUTONOMO, () => (
                  <>
                    <View>
                      <LakeHeading level={1} style={styles.title}>
                        {t("onboarding.selfEmployed")}
                      </LakeHeading>

                      <Box style={styles.form}>
                        <Field name="name">
                          {Input({
                            label: t("common.nameComplete"),
                          })}
                        </Field>

                        <Field name="nif">
                          {Input({
                            label: t("contact.nif"),
                          })}
                        </Field>

                        <Field name="phone">
                          {Input({
                            label: t("common.phone"),
                          })}
                        </Field>

                        <Field name="status">
                          {Select({
                            label: t("onboarding.status"),
                            items: status?.map(status => ({
                              value: status.id,
                              name: status.name,
                            })),
                          })}
                        </Field>
                      </Box>
                    </View>

                    <Box>
                      <Button size="small" onPress={handleSubmit}>
                        {t("common.save")}
                      </Button>

                      <Space height={12} />

                      <Button size="small" mode="tertiary" onPress={() => setConstitution("")}>
                        {t("common.cancel")}
                      </Button>
                    </Box>
                  </>
                ))
                .with(CONSTITUTION.EMPRESA, () => (
                  <>
                    <View>
                      <LakeHeading level={1} style={styles.title}>
                        {t("onboarding.company")}
                      </LakeHeading>

                      <Box style={styles.form}>
                        <Field name="nif">
                          {Input({
                            label: t("contact.nif"),
                          })}
                        </Field>

                        <Field name="phone">
                          {Input({
                            label: t("common.phone"),
                          })}
                        </Field>

                        <Field name="name">
                          {Input({
                            label: t("common.companyName"),
                          })}
                        </Field>

                        <Field name="companyType">
                          {Select({
                            label: t("onboarding.companyType"),
                            items: types.map(type => ({
                              value: type.id,
                              name: type.name,
                            })),
                          })}
                        </Field>

                        <Field name="numEmployees">
                          {Select({
                            label: t("onboarding.numEmployees"),
                            items: employees.map(employee => ({
                              value: employee.id,
                              name: employee.name,
                            })),
                          })}
                        </Field>
                      </Box>
                    </View>

                    <Box>
                      <Button size="small" onPress={handleSubmit}>
                        {t("common.save")}
                      </Button>

                      <Space height={12} />

                      <Button size="small" mode="tertiary" onPress={() => setConstitution("")}>
                        {t("common.cancel")}
                      </Button>
                    </Box>
                  </>
                ))
                .otherwise(() => (
                  <View>
                    <LakeHeading level={1} style={styles.title}>
                      {t("onboarding.title")}
                    </LakeHeading>

                    <Box style={styles.options}>
                      <Pressable onPress={() => setConstitution(CONSTITUTION.AUTONOMO)}>
                        <Box direction="row" alignItems="center" style={styles.option}>
                          <Box style={styles.icon}>
                            <Icon name="arrow-right-regular" size={16} />
                          </Box>

                          <AutoWidthImage sourceUri={flower} height={35} />

                          <View>
                            <Text style={[styles.optionTitle, common.weight600]}>
                              {t("onboarding.selfEmployed")}
                            </Text>

                            <Text>{t("onboarding.selfEmployedSubtitle")}</Text>
                          </View>
                        </Box>
                      </Pressable>

                      <Pressable onPress={() => setConstitution(CONSTITUTION.EMPRESA)}>
                        <Box direction="row" alignItems="center" style={styles.option}>
                          <Box style={styles.icon}>
                            <Icon name="arrow-right-regular" size={16} />
                          </Box>

                          <AutoWidthImage sourceUri={flowers} height={35} />

                          <View>
                            <Text style={[styles.optionTitle, common.weight600]}>
                              {t("onboarding.company")}
                            </Text>

                            <Text>{t("onboarding.companySubtitle")}</Text>
                          </View>
                        </Box>
                      </Pressable>
                    </Box>
                  </View>
                ))}
            </Box>
          </ScrollView>
        </Box>
      </ScrollView>
    </>
  );
}
