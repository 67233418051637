import { DecimalInput } from "@components/DecimalInput";
import { RightPanel } from "@components/RightPanel";
import { Box } from "@swan-io/lake/src/components/Box";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { Space } from "@swan-io/lake/src/components/Space";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { useEffect, useState } from "react";
import { GestureResponderEvent, Pressable, StyleSheet, Text, View } from "react-native";
import { useForm } from "react-ux-form";
import { Button } from "../../../components/forms/Button";
import Select from "../../../components/forms/Select";
import { PurchaseInvoiceCreateStaticDocument } from "../../../graphql/crm";
import {
  backgroundColorVariants,
  borderColorVariants,
  fontColorVariants,
} from "../../../styles/constants";
import { t } from "../../../utils/i18n";
import { CloseIcon } from "../../../utils/icons";
import {
  validateNullableRequired,
  validateNumericNullableRequired,
  validateRequired,
} from "../../../utils/validations";
import { Concept, CreatePurchaseConceptState, PurchaseConceptState } from "../types";
import { extractPurchaseStatic } from "../utils";
import { ConceptTotal } from "./ConceptTotal";
import { PurchaseRightPanel } from "./PurchaseRightPanel";

const styles = StyleSheet.create({
  line: {
    backgroundColor: backgroundColorVariants.neutral50,
    borderWidth: 1,
    borderColor: borderColorVariants.neutral200,
    borderRadius: 8,
    padding: 12,
  },
  lineSelected: {
    backgroundColor: backgroundColorVariants.primary100,
    borderWidth: 2,
    borderColor: borderColorVariants.primary500,
    borderRadius: 8,
    padding: 12,
  },
  rightpanel: {
    paddingTop: 40,
    paddingLeft: 56,
    paddingRight: 27,
  },
  red: {
    color: fontColorVariants.destructive500,
  },
  column: {
    flexBasis: "calc(50% - 12px)",
  },
  firstLine: {
    display: "grid",
    gridTemplateColumns: "3fr 1fr 1.5fr 1.5fr 1.5fr",
    gap: 12,
  },
  secondLine: {
    display: "grid",
    gridTemplateColumns: "2fr 2fr 1fr 1.5fr",
    gap: 12,
  },
  gap: {
    gap: 8,
  },
  options: {
    height: 0,
    overflow: "hidden",
    transitionDuration: "0.3s",
    transitionProperty: "height",
  },
  optionsOpened: {
    height: "180px",
  },
  moreOptions: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },
});

type Props = {
  large?: boolean;
  concept: PurchaseConceptState;
  concepts: PurchaseConceptState[];
  invoiceType?: string;
  onChange: (concept: Concept) => void;
  onDelete: () => void;
  isActive: boolean;
  setCurrentId: () => void;
};

export const PurchaseConceptItem = ({
  large = true,
  concept,
  concepts,
  onChange,
  onDelete,
  isActive,
  setCurrentId,
  invoiceType,
}: Props) => {
  const [moreOptions, setMoreOptions] = useState(false);
  const [open, setOpen] = useState<boolean>(false);

  const { Field, getFieldState, setFieldValue, listenFields, submitForm } =
    useForm<CreatePurchaseConceptState>({
      id: { initialValue: concept.id },
      description: { initialValue: concept.description, validate: validateRequired },
      unitType: { initialValue: concept.unitType ?? "" },
      unitPrice: { initialValue: concept.unitPrice ?? "" },
      price: { initialValue: concept.price },
      vat: { initialValue: concept.vat ?? "", validate: validateNullableRequired },
      quantity: { initialValue: concept.quantity ?? 1, validate: validateNumericNullableRequired },
      irpf: { initialValue: concept.irpf ?? "" },
      discountAmount: { initialValue: concept.discountAmount || 0 },
      discountPercentage: { initialValue: concept.discountPercentage || 0 },
      expenseType: { initialValue: concept.expenseType ?? "" },
      type: { initialValue: concept.type ?? false },
      subtotal: { initialValue: concept.subtotal ?? "" },
      category: { initialValue: concept?.category as string },
      subcategory: { initialValue: concept?.subcategory as string },
      usefulLife: { initialValue: (concept?.usefulLife as number) ?? 0 },
      amortizationRate: { initialValue: (concept?.amortizationRate as number) ?? 0 },
    });

  useEffect(() => {
    setFieldValue("subtotal", concept.subtotal ?? "");
    setSubtotalAux(concept.subtotal ?? "");
  }, [concept.subtotal]);

  useEffect(() => {
    setFieldValue("unitPrice", concept.unitPrice ?? "");
    setUnitPriceAux(concept.unitPrice ?? "");
  }, [concept.unitPrice]);

  const { data } = useUrqlQuery({ query: PurchaseInvoiceCreateStaticDocument }, []);

  const irpfType = extractPurchaseStatic(data, "irpfType");
  const unitType = extractPurchaseStatic(data, "unitType");
  const vatType = extractPurchaseStatic(data, "vatType");
  const discountType = extractPurchaseStatic(data, "discountType");

  useEffect(() => {
    const removeListener = listenFields(
      [
        "description",
        "quantity",
        "unitType",
        "vat",
        "irpf",
        "discountAmount",
        "discountPercentage",
        "category",
        "subcategory",
        "usefulLife",
        "amortizationRate",
      ],
      () => submitForm(values => onChange(values as Concept)),
    );

    return () => removeListener();
  }, [listenFields, onChange, submitForm]);

  const [discountTypeValue, setDiscountTypeValue] = useState("percentage");

  const [unitPriceAux, setUnitPriceAux] = useState<string>(String(concept.unitPrice ?? ""));
  const [subtotalAux, setSubtotalAux] = useState<string>(String(concept.subtotal ?? ""));

  useEffect(() => {
    if (!unitPriceAux && !subtotalAux) {
      return;
    }

    setFieldValue("unitPrice", unitPriceAux);
    setFieldValue("subtotal", subtotalAux);

    submitForm(values => {
      onChange(values as Concept);
    });
  }, [unitPriceAux, subtotalAux]);

  useEffect(() => {
    setOpen(isActive);
  }, [isActive]);

  const handleClick = () => {
    setCurrentId();
    setOpen(true);
  };

  const stopPropagation = (event: GestureResponderEvent) => {
    event.stopPropagation();
  };

  return (
    <>
      <Pressable style={isActive ? styles.lineSelected : styles.line} onPress={handleClick}>
        <Field name="id">{() => <></>}</Field>
        <Field name="expenseType">{() => <></>}</Field>

        <Box
          direction={large ? "row" : "column"}
          alignItems={large ? "start" : "stretch"}
          justifyContent="spaceBetween"
          style={styles.firstLine}
        >
          <Field name="description">
            {({ value, onChange, error }) => (
              <LakeLabel
                label={t("invoices.description")}
                style={styles.column}
                render={id => (
                  <Pressable onPress={stopPropagation}>
                    <LakeTextInput id={id} value={value} error={error} onChangeText={onChange} />
                  </Pressable>
                )}
              />
            )}
          </Field>

          <Field name="quantity">
            {({ value, onChange, error }) => (
              <LakeLabel
                label={t("invoices.quantity")}
                style={styles.column}
                extra={() => <Text style={styles.red}>*</Text>}
                render={id => (
                  <Pressable onPress={stopPropagation}>
                    <DecimalInput
                      id={id}
                      value={String(value)}
                      error={error}
                      onChangeDecimal={nextValue => {
                        setFieldValue("subtotal", "");
                        onChange(Number(nextValue));
                      }}
                    />
                  </Pressable>
                )}
              />
            )}
          </Field>

          <Field name="unitType">
            {Select({
              label: t("invoices.unitType"),
              items: unitType,
              hasEmptyRow: true,
            })}
          </Field>

          <Field name="unitPrice">
            {({ error }) => (
              <LakeLabel
                label={t("invoices.unitPrice")}
                style={styles.column}
                extra={() => <Text style={styles.red}>*</Text>}
                render={id => (
                  <Pressable onPress={stopPropagation}>
                    <DecimalInput
                      id={id}
                      value={unitPriceAux}
                      error={error}
                      onChangeDecimal={nextValue => {
                        setSubtotalAux("");
                        setUnitPriceAux(nextValue);
                      }}
                    />
                  </Pressable>
                )}
              />
            )}
          </Field>

          <Field name="subtotal">
            {({ error }) => (
              <LakeLabel
                label={t("invoices.price")}
                style={styles.column}
                extra={() => <Text style={styles.red}>*</Text>}
                render={id => (
                  <Pressable onPress={stopPropagation}>
                    <DecimalInput
                      id={id}
                      value={subtotalAux}
                      error={error}
                      onChangeDecimal={nextValue => {
                        setUnitPriceAux("");
                        setSubtotalAux(nextValue);
                      }}
                    />
                  </Pressable>
                )}
              />
            )}
          </Field>
        </Box>

        <Box
          direction={large ? "row" : "column"}
          alignItems={large ? "start" : "stretch"}
          justifyContent="spaceBetween"
          style={styles.secondLine}
        >
          <Field name="vat">
            {Select({
              label: t("invoices.iva"),
              items: vatType,
              required: true,
            })}
          </Field>

          <Field name="irpf">
            {Select({
              label: t("invoices.irpf"),
              items: irpfType,
              hasEmptyRow: true,
            })}
          </Field>

          {discountTypeValue === "amount" && (
            <Field name="discountAmount">
              {({ value, onChange, error }) => (
                <LakeLabel
                  label={t("invoices.discount")}
                  style={styles.column}
                  render={id => (
                    <Pressable onPress={stopPropagation}>
                      <DecimalInput
                        id={id}
                        value={value.toString()}
                        hideErrors={error === undefined}
                        error={error}
                        onChangeDecimal={nextValue => {
                          onChange(Number(nextValue));
                        }}
                      />
                    </Pressable>
                  )}
                />
              )}
            </Field>
          )}

          {discountTypeValue === "percentage" && (
            <Field name="discountPercentage">
              {({ value, onChange, error }) => (
                <LakeLabel
                  label={t("invoices.discount")}
                  style={styles.column}
                  render={id => (
                    <Pressable onPress={stopPropagation}>
                      <DecimalInput
                        id={id}
                        value={value.toString()}
                        hideErrors={error === undefined}
                        error={error}
                        onChangeDecimal={value => {
                          onChange(Number(value));
                        }}
                      />
                    </Pressable>
                  )}
                />
              )}
            </Field>
          )}

          <LakeLabel
            label={t("invoices.discountType")}
            render={() => (
              <LakeSelect
                items={discountType}
                value={discountTypeValue}
                onValueChange={value => {
                  setFieldValue("discountAmount", 0);
                  setFieldValue("discountPercentage", 0);
                  setDiscountTypeValue(value);
                }}
                hideErrors={true}
              />
            )}
          />
        </Box>

        <Box style={[styles.options, moreOptions && styles.optionsOpened]}>
          <Space height={24} />

          <Box style={styles.moreOptions}>
            <View></View>

            <View>
              <ConceptTotal concept={{ ...concept, category: undefined } as Concept} />
            </View>
          </Box>
        </Box>

        <Space height={24} />

        <Box direction="row" justifyContent="end" style={styles.gap}>
          <Button
            onPress={() => setMoreOptions(!moreOptions)}
            mode="tertiary"
            icon={
              moreOptions ? (
                <Icon name="arrow-up-regular" size={16} />
              ) : (
                <Icon name="arrow-down-regular" size={16} />
              )
            }
            reverse={true}
          >
            {t("invoices.viewTotal")}
          </Button>

          <Button
            onPress={onDelete}
            mode="tertiary"
            disabled={concepts.length === 1}
            icon={<Icon name="delete-regular" size={16} />}
            reverse={true}
          >
            {t("invoices.removeItem")}
          </Button>
        </Box>
      </Pressable>

      <RightPanel visible={isActive && open} onPressClose={() => setOpen(false)}>
        <View style={styles.rightpanel}>
          <Pressable onPress={() => setOpen(false)}>
            <CloseIcon />
          </Pressable>

          <PurchaseRightPanel
            invoiceType={invoiceType}
            form={{
              Field,
              setFieldValue,
              getFieldState,
              listenFields,
              onChange: () => submitForm(values => onChange(values as Concept)),
            }}
          />
        </View>
      </RightPanel>
    </>
  );
};
