import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { match } from "ts-pattern";
import { useQuery } from "urql";
import { AuthCodeDocument, CompanyDocument } from "../graphql/crm";
import { Router, oauthSwanRoutes } from "./routes";
import { handlerErrors } from "./validations";

export const CheckAuth = () => {
  useQuery({ query: CompanyDocument });

  return null;
};

export const ManageParamsOauthSwan = (routeName: (typeof oauthSwanRoutes)[number]) => {
  const [, authCode] = useUrqlMutation(AuthCodeDocument);
  const route = Router.useRoute([routeName]);
  match(route)
    .with({ name: routeName }, ({ params }) => {
      // Consent from swan
      if (params?.state !== undefined && params?.code !== undefined) {
        // Informar a backend del código
        authCode({
          input: {
            authCode: params?.code,
            state: params?.state,
          },
        }).mapOk(data => {
          match(data.authCode)
            .with({ __typename: "OperationInfo" }, handlerErrors)
            .otherwise(() => {});
        });
        Router.push(routeName);
        // Gestionar también la url de consentimiento
      } else if (params?.status !== undefined && params?.status[0] === "Accepted") {
        Router.push(routeName);
      }
      const { code, state, ...rest } = params;
      return rest;
    })
    .otherwise(() => null);
};
