import { AutoWidthImage } from "@swan-io/lake/src/components/AutoWidthImage";
import { StyleSheet, Text, View } from "react-native";
import { ProjectInfo as ProjectInfoGraphQL } from "../../../graphql/crm";
import { backgroundColorVariants, fontColorVariants } from "../../../styles/constants";
import { formatCurrency, t } from "../../../utils/i18n";
import EmployeeCard from "../../hr/components/employees/Card";
import { ProjectGraph } from "./ProjectGraph";

const styles = StyleSheet.create({
  outerContainer: {
    width: 750,
    height: 420,
    position: "relative",
    overflow: "hidden",
    borderRadius: 20,
    backgroundColor: backgroundColorVariants.white,
    border: "2px solid #FFFFFF",
    boxShadow:
      "0px 61.607px 49.285px 0px rgba(201, 201, 201, 0.07), 0px 25.738px 20.59px 0px rgba(108, 73, 172, 0.05), 0px 13.761px 11.009px 0px rgba(108, 73, 172, 0.04), 0px 7.714px 6.171px 0px rgba(108, 73, 172, 0.04), 0px 4.097px 3.278px 0px rgba(108, 73, 172, 0.03), 0px 1.705px 1.364px 0px rgba(108, 73, 172, 0.02)",
  },
  backgroundContainer: {
    position: "absolute",
    top: "-205%",
    left: "-45%",
    width: "200%",
    height: "200%",
    backgroundImage: "linear-gradient(180deg, #D44D19 0.19%, #5299EA 100.19%)",
    opacity: 0.6,
    transform: "rotate(-90deg)",
    filter: "blur(21.57756805419922px)",
  },
  contentContainer: {
    width: "100%",
    height: "100%",
    padding: 15,
    position: "relative",
    zIndex: 1,
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "20%",
    opacity: 1,
  },
  descriptionContainer: {
    paddingHorizontal: 10,
    width: "100%",
    height: "12%",
  },
  employeeContainer: {
    display: "flex",
    flexDirection: "column",
    paddingHorizontal: 10,
    width: "100%",
    height: "33%",
  },
  graphContainer: {
    width: "100%",
    height: "35%",
    paddingHorizontal: 10,
  },
  graphInnerContainer: {
    width: "100%",
    height: "90%",
  },
  nameContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: 3,
    width: "40%",
    height: "100%",
    padding: 10,
  },
  logoContainer: {
    width: "20%",
    height: "100%",
    padding: 10,
  },
  label: {
    fontSize: 15,
    color: fontColorVariants.white,
    fontWeight: "400",
  },
  value: {
    fontSize: 40,
    fontWeight: "400",
    color: fontColorVariants.white,
  },
  result: {
    fontSize: 40,
    fontWeight: "500",
    color: fontColorVariants.white,
  },
  employeeCardContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
    width: "100%",
  },
});

type ProjectInfoProps = {
  project: ProjectInfoGraphQL;
};

const maxEmployeesToShow = 4;

export const ProjectInfo = ({ project }: ProjectInfoProps) => {
  return (
    <View style={styles.outerContainer}>
      <View style={styles.backgroundContainer} />

      <View style={styles.contentContainer}>
        <View style={styles.titleContainer}>
          <View style={styles.nameContainer}>
            <Text style={styles.label}>{t("project")}</Text>
            <Text style={styles.value}>{project?.name}</Text>
          </View>

          <View style={styles.nameContainer}>
            <Text style={styles.label}>{t("project.result")}</Text>
            <Text style={styles.result}>{formatCurrency(Number(project?.result), "EUR")}</Text>
          </View>

          <View style={styles.logoContainer}>
            {project?.logo != null && (
              <AutoWidthImage sourceUri={project?.logo} height={60} width={60} />
            )}
          </View>
        </View>

        <View style={styles.descriptionContainer}>
          <Text numberOfLines={2} ellipsizeMode="tail" style={styles.label}>
            {project?.description}
          </Text>
        </View>

        <View style={styles.employeeContainer}>
          <Text style={styles.label}>{t("project.employees")}</Text>

          <View style={styles.employeeCardContainer}>
            {project?.employees
              .slice(0, maxEmployeesToShow)
              .map(employee => (
                <EmployeeCard
                  key={employee.id}
                  name={employee.name}
                  role={employee.role as string}
                  photo={employee.photo}
                />
              ))}
          </View>
        </View>

        <View style={styles.graphContainer}>
          <Text style={styles.label}>{t("project.analysis")}</Text>

          <View style={styles.graphInnerContainer}>
            <ProjectGraph graphData={project?.graphData} />
          </View>
        </View>
      </View>
    </View>
  );
};
