import { NavigationItem } from "@components/NavigationItem";
import { View } from "react-native";
import { t } from "../../../utils/i18n";
import { Router } from "../../../utils/routes";
import { checkRule } from "../../../utils/subscription";
import { useUser } from "../../context/UserContext";

export const Navigation = () => {
  const { subscription } = useUser();

  return (
    <View role="navigation">
      <NavigationItem
        to={Router.HrPayrollsRoot()}
        label={t("common.payrolls")}
        isLocked={!checkRule(subscription, "has_hhrr")}
      />

      <NavigationItem
        to={Router.HrEmployeesRoot()}
        label={t("common.employees")}
        isLocked={!checkRule(subscription, "has_hhrr")}
      />
    </View>
  );
};
