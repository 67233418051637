import { Box } from "@swan-io/lake/src/components/Box";
import { Space } from "@swan-io/lake/src/components/Space";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { StyleSheet, View } from "react-native";
import { useForm } from "react-ux-form";
import { match } from "ts-pattern";
import { ChangePasswordDocument, OperationInfo } from "../graphql/crm";
import { t } from "../utils/i18n";
import { ChangePasswordState } from "../utils/types";
import { handlerFieldErrors, tapError, validateRequired } from "../utils/validations";
import { Heading } from "./Heading";
import { Button } from "./forms/Button";
import Input from "./forms/Input";

const styles = StyleSheet.create({
  form: {
    maxWidth: 400,
    paddingVertical: 16,
    paddingHorizontal: 24,
  },
});

export const ChangePassword = () => {
  const { Field, setFieldError, submitForm } = useForm<ChangePasswordState>({
    oldPassword: { initialValue: "", validate: validateRequired },
    newPassword: { initialValue: "", validate: validateRequired },
    passwordRepeat: {
      initialValue: "",
      validate: (value, values) => {
        if (value !== values.getFieldState("newPassword").value) {
          return t("common.passwordsNotMatch");
        }
      },
    },
  });

  const [, update] = useUrqlMutation(ChangePasswordDocument);

  const onSave = () => {
    submitForm(values => {
      update({
        input: {
          oldPassword: values.oldPassword ?? "",
          newPassword: values.newPassword ?? "",
        },
      })
        .tapError(tapError)
        .mapOk(data => {
          match(data.changePassword)
            .with({ __typename: "OperationInfo" }, () =>
              handlerFieldErrors<ChangePasswordState>(
                data.changePassword as OperationInfo,
                setFieldError,
              ),
            )
            .otherwise(() => {
              showToast({
                variant: "success",
                title: t("common.savedSuccessfully"),
                autoClose: true,
              });
            });
        });
    });
  };

  return (
    <>
      <Heading title={t("common.changePassword")} />

      <View style={styles.form}>
        <Field name="oldPassword">
          {Input({
            label: t("common.oldPassword"),
            required: true,
            secureTextEntry: true,
          })}
        </Field>

        <Field name="newPassword">
          {Input({
            label: t("common.newPassword"),
            required: true,
            secureTextEntry: true,
          })}
        </Field>

        <Field name="passwordRepeat">
          {Input({
            label: t("common.passwordRepeat"),
            required: true,
            secureTextEntry: true,
          })}
        </Field>

        <Space height={16} />

        <Box direction="row" justifyContent="end">
          <Button onPress={onSave}>{t("common.save")}</Button>
        </Box>
      </View>
    </>
  );
};
