import { CheckAuth } from "../utils/auth";
import { Router } from "../utils/routes";
import { Redirect } from "./Redirect";

export const ProjectRootRedirect = () => {
  return (
    <>
      <CheckAuth />
      <Redirect to={Router.SummaryRoot()} />
    </>
  );
};
