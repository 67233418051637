import { Button } from "@components/forms/Button";
import Input from "@components/forms/Input";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { useEffect, useState } from "react";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { Pressable, StyleSheet, View } from "react-native";
import { useForm } from "react-ux-form";
import { match } from "ts-pattern";
import {
  SalesInvoiceRelayQuery,
  SpeechToTextDocument,
  TextToInvoiceDocument,
} from "../../../graphql/crm";
import { backgroundColorVariants } from "../../../styles/constants";
import { t } from "../../../utils/i18n";
import { CheckBoolRuleLock, checkRule, useSubscription } from "../../../utils/subscription";
import { extractQueryData, validateRequired } from "../../../utils/validations";
import { useLoading } from "../../context/LoadingContext";
import { useUser } from "../../context/UserContext";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "auto",
    maxWidth: "100%",
    padding: 16,
    marginTop: 16,
    marginBottom: 16,
    backgroundColor: backgroundColorVariants.white,
    borderRadius: 8,
    boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.10)",
    transition: "1s ease",
  },
  containerOpen: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "100%",
    padding: 16,
    marginTop: 16,
    marginBottom: 16,
    backgroundColor: backgroundColorVariants.white,
    borderRadius: 8,
    boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.10)",
    transition: "1s ease",
  },
  audioContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  audioRecorderContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "10%",
    outlineWidth: 0,
  },
  audioTrack: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: 0,
    transition: "0.5s ease",
  },
  audio: {
    width: "100%",
  },
  audioTextContainer: {
    width: 0,
    transition: "0.5s ease",
    height: 0,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  audioTextContainerOpen: {
    height: "auto",
    transition: "0.5s ease",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 16,
  },
  audioTrackOpen: {
    width: "90%",
    transition: "0.5s ease",
  },
  lock: {
    paddingLeft: 8,
  },
});

type AudioVoiceRecorderProps = {
  setSTTInvoice: (invoice: SalesInvoiceRelayQuery) => void;
};

export const AudioVoiceRecorder = ({ setSTTInvoice }: AudioVoiceRecorderProps) => {
  const { subscription } = useUser();
  const [audioSrc, setAudioSrc] = useState<string | null>(null);
  const [isTypingComplete, setIsTypingComplete] = useState(false);
  const { setLoading } = useLoading();
  const [, textToInvoice] = useUrqlMutation(TextToInvoiceDocument);
  const [, speechToText] = useUrqlMutation(SpeechToTextDocument);

  type FormValues = {
    audioText: string;
  };

  const { Field, submitForm, setFieldValue } = useForm<FormValues>({
    audioText: { initialValue: "", validate: validateRequired },
  });

  const handleFormSubmit = () => {
    setLoading(true);
    submitForm(values => {
      textToInvoice({
        input: {
          textPrompt: values.audioText as string,
        },
      }).mapOk(data => {
        setLoading(false);
        match(data.textToInvoice)
          .with({ __typename: "SalesInvoiceRelayQuery" }, data => {
            setSTTInvoice(data as SalesInvoiceRelayQuery);
          })
          .with({ __typename: "OperationInfo" }, (info: { messages: { message: string }[] }) => {
            showToast({
              title: info.messages[0]?.message as string,
              variant: "error",
              autoClose: true,
            });
          });
      });
    });
  };

  const simulateTypewriterEffect = (
    text: string,
    setFieldValueWritter: typeof setFieldValue,
    onFinish: () => void,
  ) => {
    let index = 0;
    const interval = setInterval(() => {
      if (index <= text.length) {
        const nextChar = text.slice(0, index);
        setFieldValueWritter("audioText", nextChar);
        index++;
      } else {
        clearInterval(interval);
        onFinish();
      }
    }, 50);
  };

  const addAudioElement = (blob: Blob) => {
    setLoading(true);
    setFieldValue("audioText", "");
    setIsTypingComplete(false);
    const url = URL.createObjectURL(blob);
    setAudioSrc(url);
    const fileAux = new File([blob], `audio_${Date.now()}.webm`, { type: "audio/webm" });
    speechToText({ file: fileAux }).mapOk(data => {
      setLoading(false);
      match(data.speechToText)
        .with({ __typename: "SpeechToTextOutput" }, data => {
          const transcribedText = extractQueryData(data, "text") as string;
          simulateTypewriterEffect(transcribedText, setFieldValue, () => setIsTypingComplete(true));
        })
        .with({ __typename: "OperationInfo" }, (info: { messages: { message: string }[] }) => {
          showToast({
            title: info.messages[0]?.message as string,
            variant: "error",
            autoClose: true,
          });
        });
    });
  };

  const recorderControls = useAudioRecorder();
  useEffect(() => {
    recorderControls.stopRecording();
  }, [recorderControls.startRecording]);

  const { showUpgrade } = useSubscription();

  return (
    <View style={Boolean(audioSrc) ? styles.containerOpen : styles.container}>
      <View style={styles.audioContainer}>
        <Pressable
          style={styles.audioRecorderContainer}
          onPress={() => {
            checkRule(subscription, "has_voice_assistant") ? setAudioSrc("") : showUpgrade({});
          }}
        >
          <AudioRecorder
            onRecordingComplete={blob =>
              checkRule(subscription, "has_voice_assistant") ? addAudioElement(blob) : null
            }
            audioTrackConstraints={{
              noiseSuppression: true,
              echoCancellation: true,
            }}
            downloadFileExtension="webm"
            mediaRecorderOptions={{
              audioBitsPerSecond: 128000,
            }}
            showVisualizer={true}
            recorderControls={recorderControls}
          />

          <View style={styles.lock}>{CheckBoolRuleLock(subscription, "has_voice_assistant")}</View>
        </Pressable>

        <View style={Boolean(audioSrc) ? styles.audioTrackOpen : styles.audioTrack}>
          <audio controls={true} style={styles.audio}>
            <source src={audioSrc as string} type="audio/wav" />
          </audio>
        </View>
      </View>

      <View style={Boolean(audioSrc) ? styles.audioTextContainerOpen : styles.audioTextContainer}>
        <Field name="audioText">
          {Input({
            disabled: !isTypingComplete,
            label: t("invoice.extractText"),
            multiline: true,
            customOnChange: (value: string) => {
              setFieldValue("audioText", value);
            },
          })}
        </Field>

        <Button onPress={handleFormSubmit} disabled={!isTypingComplete}>
          {t("invoice.generate")}
        </Button>
      </View>
    </View>
  );
};
