import {
  DatePicker as Component,
  DatePickerProps,
} from "@swan-io/shared-business/src/components/DatePicker";
import { StyleSheet, View } from "react-native";

const styles = StyleSheet.create({
  disabled: {
    pointerEvents: "none",
    "--color-background-default-accented": "var(--color-gray-50)",
  },
});

type Props = DatePickerProps & {
  disabled: boolean;
};

export const DatePicker = (props: Props) => {
  const { disabled = false } = props;

  return (
    <View style={disabled && styles.disabled}>
      <Component {...props} />
    </View>
  );
};
