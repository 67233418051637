import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { colors, spacings } from "@swan-io/lake/src/constants/design";
import { StyleSheet, View, ViewStyle } from "react-native";
import { getAlign } from "../../utils/utils";

const styles = StyleSheet.create({
  cell: {
    display: "flex",
    paddingHorizontal: spacings[8],
    flexGrow: 1,
    flexDirection: "row",
    alignItems: "center",
    width: 1,
  },
});

export const DefaultCell = ({
  data,
  align,
  style,
  children,
}: {
  data: string;
  align?: "left" | "right" | "center";
  style?: ViewStyle;
  children?: React.ReactNode;
}) => (
  <View
    style={{
      ...styles.cell,
      ...style,
      justifyContent: getAlign(align as "left" | "right" | "center") as
        | "center"
        | "flex-start"
        | "flex-end"
        | "space-between"
        | "space-around"
        | "space-evenly",
    }}
  >
    <LakeText align={align} variant="smallMedium" color={colors.gray[600]} style={style}>
      {data}
    </LakeText>

    {children}
  </View>
);
