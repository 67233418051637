/* eslint-disable react-native/no-inline-styles */
import { AutoWidthImage } from "@swan-io/lake/src/components/AutoWidthImage";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { useState } from "react";
import { ScrollView, StyleSheet, Text } from "react-native";
import logo from "../assets/img/logo-trexx.svg";
import { Button, ButtonGroup } from "../components/forms/Button";
import { LabelInput } from "../components/forms/Input";
import { SelectField } from "../components/forms/Select";
import { ArrowLeft, ArrowRight } from "../utils/icons";
import { COLORS } from "../values/colors";

const styles = StyleSheet.create({
  page: {
    width: "100%",
    marginHorizontal: "auto",
    padding: 16,
  },
  container: {
    gap: 16,
  },
});

export const DesignSystemPage = () => {
  const [inputValue, setInputValue] = useState("");

  return (
    <ScrollView style={styles.page}>
      <Box direction="column" style={styles.container}>
        <AutoWidthImage sourceUri={logo} height={48} alt="Trexx" />
        <LakeHeading level={1}>Design System</LakeHeading>
        <LakeHeading level={2}>Colors</LakeHeading>

        <Box direction="column" style={styles.container}>
          <GroupColor search="PRIMARY" />
          <GroupColor search="NEUTRAL" />
          <GroupColor search="GRAY" />
          <GroupColor search="SUCCESS" />
          <GroupColor search="DESTRUCTIVE" />
        </Box>

        <LakeHeading level={2}>Buttons</LakeHeading>
        <Button>Default</Button>

        <ButtonGroup>
          <Button style="group">Button 1</Button>
          <Button style="group">Button 2</Button>
          <Button style="group">Button 3</Button>
        </ButtonGroup>

        <ButtonGroup transparent={true}>
          <Button style="group" disabled={true}>
            Button 1
          </Button>
        </ButtonGroup>

        <Box direction="row" style={styles.container}>
          <GroupButton mode="primary" />
          <GroupButton mode="secondary" />
          <GroupButton mode="tertiary" />
        </Box>

        <Box style={styles.container}>
          <LakeHeading level={2}>Form</LakeHeading>

          <LabelInput
            label="Label"
            placeholder="Placeholder"
            value={inputValue}
            onChange={setInputValue}
          />

          <SelectField
            label="Label"
            items={[
              { name: "Option 1", value: "1" },
              { name: "Option 2", value: "2" },
              { name: "Option 3", value: "3" },
            ]}
          />
        </Box>
      </Box>
    </ScrollView>
  );
};

const GroupColor = ({ search }: { search: string }) => (
  <Box direction="row" style={{ flexWrap: "wrap" }}>
    {Object.entries(COLORS).map(([key, value]) => {
      return (
        key.includes(search) && (
          <Box key={key}>
            <Box
              style={{
                width: 112,
                height: 112,
                backgroundColor: value,
              }}
            />

            <Text style={{ fontSize: 11, whiteSpace: "nowrap" }}>{key}</Text>
            <Text style={{ fontSize: 11, whiteSpace: "nowrap" }}>{value}</Text>
          </Box>
        )
      );
    })}
  </Box>
);

const GroupButton = ({ mode }: { mode: "primary" | "secondary" | "tertiary" }) => (
  <Box style={styles.container}>
    <Box direction="column" alignItems="start" style={styles.container}>
      <Button mode={mode} size="large">
        Large
      </Button>

      <Button mode={mode}>Medium (default)</Button>

      <Button mode={mode} size="small">
        Small
      </Button>

      <Button mode={mode} disabled={true}>
        Disabled
      </Button>

      <Button mode={mode} icon={ArrowRight}>
        Icon (default)
      </Button>

      <Button mode={mode} icon={ArrowLeft} reverse={true}>
        Icon (reverse)
      </Button>
    </Box>

    <Box direction="column" alignItems="start" style={styles.container}>
      <Button mode={mode} size="large" style="outlined">
        Large
      </Button>

      <Button mode={mode} style="outlined">
        Medium (default)
      </Button>

      <Button mode={mode} size="small" style="outlined">
        Small
      </Button>

      <Button mode={mode} style="outlined" disabled={true}>
        Disabled
      </Button>
    </Box>
  </Box>
);
