import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { SalesInvoiceDocument, SalesInvoiceRelayQuery } from "../../../graphql/crm";
import { Router } from "../../../utils/routes";
import { extractQueryData } from "../../../utils/validations";

type Params = {
  proformaId?: string;
  quoteId?: string;
  deliveryId?: string;
};

const useGetSalesInvoiceData = (params?: Params) => {
  const id = params?.proformaId ?? params?.deliveryId ?? params?.quoteId;

  const { data } = useUrqlQuery({
    query: SalesInvoiceDocument,
    variables: {
      id: id as string,
    },
    pause: id == null,
  });

  if (data.isDone()) {
    data.mapError(data => {
      showToast({
        variant: "error",
        title: data.message.replace("[GraphQL]", ""),
        autoClose: true,
      });
      Router.push("InvoicesSalesList");
    });

    const invoiceData = extractQueryData(
      data,
      "value.value.salesInvoice",
    ) as SalesInvoiceRelayQuery;

    if (
      invoiceData?.status !== "PROFORMA" &&
      invoiceData?.status !== "QUOTE" &&
      invoiceData?.status !== "DELIVERY"
    ) {
      showToast({
        variant: "error",
        title: "Invoice is not Proforma, Delivery or Quote",
        autoClose: true,
      });
      Router.push("InvoicesSalesList");
    }

    return invoiceData;
  }
};

export default useGetSalesInvoiceData;
