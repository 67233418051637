import { Growth as Chart } from "@components/chart/Growth";
import { Box } from "@swan-io/lake/src/components/Box";
import { StyleSheet, Text, View } from "react-native";
import { GrowthOutput } from "../../graphql/crm";
import { fontColorVariants } from "../../styles/constants";
import { t } from "../../utils/i18n";

const styles = StyleSheet.create({
  row: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: 36,
    paddingHorizontal: 30,
    marginTop: 12,
  },
  chart: {
    position: "relative",
    display: "grid",
    placeContent: "center",
    height: 100,
  },
  percent: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: 30,
    fontWeight: "500",
    color: fontColorVariants.white,
  },
  symbol: {
    fontSize: 16,
  },
  white: {
    fontSize: 14,
    color: fontColorVariants.white,
    textAlign: "center",
    whiteSpace: "nowrap",
    marginTop: 12,
  },
});

type Props = {
  data: GrowthOutput;
};

const MAX_PERIOD = 100;

export const Growth = ({ data }: Props) => {
  const previousPeriod = Math.round(data?.previousPeriod as number);
  const previousYear = Math.round(data?.previousYear as number);

  const previousPeriodDataset =
    previousPeriod <= 0
      ? [MAX_PERIOD, 0]
      : previousPeriod >= MAX_PERIOD
        ? [0, MAX_PERIOD]
        : [previousPeriod, MAX_PERIOD - previousPeriod];
  const previousYearDataset =
    previousYear <= 0
      ? [MAX_PERIOD, 0]
      : previousYear >= MAX_PERIOD
        ? [0, MAX_PERIOD]
        : [previousYear, MAX_PERIOD - previousYear];

  if (data == null) {
    return null;
  }

  return (
    <Box direction="row" style={styles.row}>
      <View>
        <View style={styles.chart}>
          <Chart
            datasets={[
              {
                data: previousPeriodDataset,
              },
            ]}
          />

          <Text style={styles.percent}>
            {previousPeriod}

            <Text style={styles.symbol}>%</Text>
          </Text>
        </View>

        <Text style={styles.white}>{t("common.previousPeriod")}</Text>
      </View>

      <View>
        <View style={styles.chart}>
          <Chart
            datasets={[
              {
                data: previousYearDataset,
              },
            ]}
          />

          <Text style={styles.percent}>
            {previousYear}

            <Text style={styles.symbol}>%</Text>
          </Text>
        </View>

        <Text style={styles.white}>{t("common.previousYear")}</Text>
      </View>
    </Box>
  );
};
