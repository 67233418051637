import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { breakpoints, spacings } from "@swan-io/lake/src/constants/design";
import { StyleSheet } from "react-native";
import { match } from "ts-pattern";
import { NotFoundPage } from "../../../pages/NotFoundPage";
import { Router } from "../../../utils/routes";
import { List } from "../components/List";
import { ContactType } from "../components/ListFilter";

const styles = StyleSheet.create({
  root: {
    ...commonStyles.fill,
    paddingTop: spacings[24],
  },
});

export const Area = () => {
  const route = Router.useRoute(["CrmContactsList"]);
  return (
    <>
      {match(route)
        .with({ name: "CrmContactsList" }, ({ params }) => (
          <ResponsiveContainer breakpoint={breakpoints.large} style={styles.root}>
            {() => (
              <List
                create={params.new}
                importVisible={params.import != null}
                search={params.search}
                type={params.type as ContactType[]}
              />
            )}
          </ResponsiveContainer>
        ))
        .otherwise(() => (
          <NotFoundPage />
        ))}
    </>
  );
};
