import { Box } from "@swan-io/lake/src/components/Box";
import { StyleSheet, Text, ViewStyle } from "react-native";
import { common } from "../../../../styles/common";

const styles = StyleSheet.create({
  row: {
    display: "flex",
    marginTop: 2,
    marginBottom: 2,
    minHeight: 32,
    alignItems: "center",
  },
  pl1: {
    paddingLeft: 16,
  },
  values: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    width: 300,
    gap: 12,
  },
});

export const RowMultiHeader = ({
  label1,
  label2,
  label3,
  style = styles.pl1,
}: {
  label1?: string;
  label2?: string;
  label3?: string;
  style?: ViewStyle;
}) => (
  <Box direction="row" alignItems="center" justifyContent="end" style={[style, styles.row]}>
    <Box direction="row" justifyContent="spaceBetween" style={styles.values}>
      <Text>{label1}</Text>
      <Text style={common.textCenter}>{label2}</Text>
      <Text style={common.textRight}>{label3}</Text>
    </Box>
  </Box>
);
