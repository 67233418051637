import { DatePickerComplex } from "@components/forms/DatePickerComplex";
import { AutoWidthImage } from "@swan-io/lake/src/components/AutoWidthImage";
import { useState } from "react";
import { StyleSheet, Text, View, ViewStyle } from "react-native";
import arrow from "../assets/icons/arrow.svg";
import { backgroundColorVariants, fontColorVariants } from "../styles/constants";
import { encodeDateISO } from "../utils/date";
import { t } from "../utils/i18n";

const styles = StyleSheet.create({
  calendar: {
    display: "flex",
    alignItems: "center",
    gap: 4,
    userSelect: "none",
    paddingVertical: 6,
    paddingHorizontal: 12,
    color: fontColorVariants.gray500,
    fontSize: 12,
    fontWeight: "600",
    backgroundColor: backgroundColorVariants.gray100,
  },
});

type Props = {
  dateFrom?: Date;
  dateTo?: Date;
  setDates: (dates: { dateFrom: string; dateTo: string }) => void;
  style?: ViewStyle;
};

export const Calendar = ({ dateFrom, dateTo, setDates, style }: Props) => {
  const [openCalendar, setOpenCalendar] = useState(false);

  const [from, setFrom] = useState(dateFrom);
  const [to, setTo] = useState(dateTo);

  return (
    <View>
      <Text onPress={() => setOpenCalendar(!openCalendar)} style={styles.calendar}>
        <Text>
          {from && to
            ? `${from.toLocaleDateString()} - ${to.toLocaleDateString()}`
            : t("common.all")}
        </Text>

        <AutoWidthImage sourceUri={arrow} height={16} />
      </Text>

      {openCalendar && (
        <DatePickerComplex
          from={from}
          to={to}
          onChange={dates => {
            const [start, end] = dates;
            setFrom(start ?? from);
            setTo(end ?? to);

            setDates({
              dateFrom: encodeDateISO(start?.toString() as string),
              dateTo: encodeDateISO(end?.toString() as string),
            });

            setOpenCalendar(false);
          }}
          style={style}
        />
      )}
    </View>
  );
};
