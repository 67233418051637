import { ColumnConfig, PlainListView } from "@components/PlainListView";
import { DefaultCell } from "@components/forms/DefaultCell";
import { PriceCell } from "@components/forms/PriceCell";
import { LinkConfig } from "@swan-io/lake/src/components/FixedListView";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { ReactElement, ReactNode, useState } from "react";
import { StyleSheet, ViewStyle } from "react-native";
import { PayrollRelay, Static } from "../../../graphql/crm";
import { encodeDateISO } from "../../../utils/date";
import { t } from "../../../utils/i18n";
import { COLORS } from "../../../values/colors";
import { ColumnHeader } from "../../invoices/components/ColumnHeader";
import KebabMenu from "./KebabMenu";
import { PayrollUpdateStatusModal } from "./PayrollUpdateStatusModal";
import { SendModal } from "./SendModal";
import { StatusCell } from "./StatusCell";

const styles = StyleSheet.create({
  header: {
    fontWeight: "600",
    "--color-gray-900": COLORS.WHITE,
    "--text-medium-font-size": 12,
  },
});

type Props = {
  data: PayrollRelay[];
  loading?: { isLoading: boolean; count: number };
  onEndReached: () => void;
  onActiveRowChange: (element: HTMLElement) => void;
  onRefreshRequest: () => void;
  activeRowId?: string;
  renderEmptyList: () => ReactNode;
  getRowLink: (item: LinkConfig<PayrollRelay, ExtraInfo>) => ReactElement;
  statusItems: Static[];
};
type ExtraInfo = undefined;

export const DetailsList = ({
  data,
  loading,
  onEndReached,
  activeRowId,
  getRowLink,
  onActiveRowChange,
  onRefreshRequest,
  renderEmptyList,
  statusItems,
}: Props) => {
  const [showSentModal, setShowSentModal] = useState(false);
  const [updateStatusModal, setUpdateStatusModal] = useState<boolean>(false);
  const [payroll, setPayroll] = useState<PayrollRelay>();

  const columns: ColumnConfig<PayrollRelay, ExtraInfo>[] = [
    {
      id: "issue_date",
      width: "grow",
      title: t("hr.issueDate"),
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => <DefaultCell data={`${encodeDateISO(item.issueDate ?? "")}`} />,
    },
    {
      id: "period",
      width: 100,
      title: t("hr.period"),
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => (
        <DefaultCell
          data={`${encodeDateISO(item.periodStart ?? "")} ${encodeDateISO(item.periodEnd ?? "-")}`}
        />
      ),
    },
    {
      id: "name",
      width: "grow",
      title: t("hr.name"),
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => <DefaultCell data={item.employee.name} />,
    },
    {
      id: "nif",
      width: "grow",
      title: t("contact.nif"),
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => <DefaultCell data={item.employee.nif ?? ""} />,
    },
    {
      id: "status",
      width: "grow",
      title: t("common.status"),
      renderTitle: ({ title }) => <ColumnHeader text={title} align="center" />,
      renderCell: ({ item }) => (
        <StatusCell
          status={item.status ?? ""}
          text={statusItems?.find(c => c.value === item.status)?.name ?? ""}
          paymentDate={encodeDateISO(item.datePaid ?? "")}
          onChangeStatus={() => {
            if (item.status !== "paid") {
              setPayroll(item);
              setUpdateStatusModal(true);
            }
          }}
          onAction={() => null}
          align="center"
        />
      ),
    },
    {
      id: "totalEarned",
      width: 120,
      title: t("hr.payroll.totalEarned"),
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => <PriceCell price={item.totalEarned as number} />,
    },
    {
      id: "totalDeductionsCompany",
      width: 120,
      title: t("hr.payroll.totalDeductionsCompany"),
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => <PriceCell price={item.totalDeductionsCompany as number} />,
    },
    {
      id: "irpfAmount",
      width: 120,
      title: t("hr.payroll.irpfAmount"),
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => <PriceCell price={item.irpfAmount as number} />,
    },
    {
      id: "options",
      width: 80,
      title: "",
      renderTitle: ({ title }) => <ColumnHeader text={title} align="center" />,
      renderCell: ({ item }) => (
        <KebabMenu payroll={item} setShowSentModal={setShowSentModal} setPayroll={setPayroll} />
      ),
    },
  ];

  const smallColumns: ColumnConfig<PayrollRelay, ExtraInfo>[] = [
    {
      id: "issue_date",
      width: "grow",
      title: t("hr.issueDate"),
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => <DefaultCell data={`${encodeDateISO(item.issueDate ?? "")}`} />,
    },
    {
      id: "period",
      width: 100,
      title: t("hr.period"),
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => (
        <DefaultCell
          data={`${encodeDateISO(item.periodStart ?? "")} ${encodeDateISO(item.periodEnd ?? "-")}`}
        />
      ),
    },
    {
      id: "name",
      width: "grow",
      title: t("hr.name"),
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => <DefaultCell data={item.employee.name} />,
    },
    {
      id: "nif",
      width: "grow",
      title: t("contact.nif"),
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => <DefaultCell data={item.employee.nif ?? ""} />,
    },
    {
      id: "status",
      width: "grow",
      title: t("common.status"),
      renderTitle: ({ title }) => <ColumnHeader text={title} align="center" />,
      renderCell: ({ item }) => (
        <StatusCell
          status={item.status ?? ""}
          text={statusItems?.find(c => c.value === item.status)?.name ?? ""}
          paymentDate={encodeDateISO(item.datePaid ?? "")}
          onChangeStatus={() => {
            if (item.status !== "paid") {
              setPayroll(item);
              setUpdateStatusModal(true);
            }
          }}
          onAction={() => null}
          align="center"
        />
      ),
    },
    {
      id: "totalCompanyAmount",
      width: 120,
      title: t("hr.payroll.totalCompanyAmount"),
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => <PriceCell price={item.totalCompanyAmount as number} />,
    },
    {
      id: "irpfAmount",
      width: 120,
      title: t("hr.payroll.irpfAmount"),
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => <PriceCell price={item.irpfAmount as number} />,
    },
    {
      id: "options",
      width: 80,
      title: "",
      renderTitle: ({ title }) => <ColumnHeader text={title} align="center" />,
      renderCell: ({ item }) => (
        <KebabMenu payroll={item} setShowSentModal={setShowSentModal} setPayroll={setPayroll} />
      ),
    },
  ];

  return (
    <>
      <PlainListView
        withoutScroll={true}
        data={data}
        keyExtractor={item => item.id as string}
        headerHeight={36}
        rowHeight={63}
        headerStyle={styles.header as ViewStyle}
        headerBackgroundColor="#EDEDED"
        groupHeaderHeight={36}
        extraInfo={undefined}
        columns={columns}
        smallColumns={smallColumns}
        activeRowId={activeRowId}
        onActiveRowChange={onActiveRowChange}
        loading={loading}
        onEndReached={onEndReached}
        getRowLink={getRowLink}
        renderEmptyList={renderEmptyList}
        breakpoint={breakpoints.large}
      />

      <PayrollUpdateStatusModal
        visible={updateStatusModal}
        onPressClose={() => {
          onRefreshRequest();
          setUpdateStatusModal(false);
        }}
        payroll={payroll}
      />

      <SendModal
        visible={showSentModal}
        onPressClose={() => {
          setShowSentModal(false);
        }}
        payroll={payroll}
      />
    </>
  );
};
