import { useEffect, useState } from "react";
import { Dimensions, StyleSheet, View } from "react-native";
import { ProjectInfo as ProjectInfoGraphQL } from "../../../graphql/crm"; // Alias this import
import { ProjectInfo } from "./ProjectInfo";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    padding: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  projectsContainer: {
    width: "100%",
    display: "grid",
    justifyItems: "center",
    alignItems: "center",

    gap: 40,
  },
});

type ProjectsListProps = {
  projects: ProjectInfoGraphQL[];
};

export const ProjectList = ({ projects }: ProjectsListProps) => {
  const [numColumns, setNumColumns] = useState(1);

  const updateNumColumns = () => {
    const screenWidth = Dimensions.get("window").width;
    const projectInfoWidth = 750;
    const newNumColumns = Math.floor(screenWidth / (projectInfoWidth + 40)); // Including gap
    setNumColumns(newNumColumns);
  };

  useEffect(() => {
    updateNumColumns();
    Dimensions.addEventListener("change", updateNumColumns);
  }, []);

  const projectsContainerStyle = {
    ...styles.projectsContainer,
    gridTemplateColumns: `repeat(${numColumns}, 1fr)`,
  };
  return (
    <View style={styles.container}>
      <View style={projectsContainerStyle}>
        {projects.map((project: ProjectInfoGraphQL) => (
          <ProjectInfo key={project.id} project={project} />
        ))}
      </View>
    </View>
  );
};
