import { ColumnConfig, PlainListView } from "@components/PlainListView";
import { LinkConfig } from "@swan-io/lake/src/components/FixedListView";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { ReactElement, ReactNode } from "react";
import { StyleSheet, ViewStyle } from "react-native";
import { ContactRelay } from "../../../graphql/crm";
import { t } from "../../../utils/i18n";
import { COLORS } from "../../../values/colors";
import { ColumnHeader } from "../../invoices/components/ColumnHeader";
import { DefaultCell } from "./../../../components/forms/DefaultCell";
import { NameCell, TypeCell } from "./ListCells";

const styles = StyleSheet.create({
  header: {
    fontWeight: "600",
    "--color-gray-900": COLORS.WHITE,
    "--text-medium-font-size": 12,
  },
});

type Props = {
  contacts: ContactRelay[];
  loading?: { isLoading: boolean; count: number };
  onEndReached: () => void;
  onActiveRowChange: (element: HTMLElement) => void;
  activeRowId?: string;
  renderEmptyList: () => ReactNode;
  getRowLink: (item: LinkConfig<ContactRelay, ExtraInfo>) => ReactElement;
};

type ExtraInfo = undefined;

const columns: ColumnConfig<ContactRelay, ExtraInfo>[] = [
  {
    id: "type",
    width: 150,
    title: t("contact.type"),
    renderTitle: ({ title }) => <ColumnHeader text={title} />,
    renderCell: ({ item }) => <TypeCell typename={item.type} />,
  },
  {
    id: "name",
    width: "grow",
    title: t("contact.name"),
    renderTitle: ({ title }) => <ColumnHeader text={title} />,
    renderCell: ({ item }) => <NameCell name={item.name} />,
  },
  {
    id: "nif",
    width: "grow",
    title: t("contact.nif"),
    renderTitle: ({ title }) => <ColumnHeader text={title} align="center" />,
    renderCell: ({ item }) => <DefaultCell data={item.nif ?? ""} align="center" />,
  },
];

const smallColumns: ColumnConfig<ContactRelay, ExtraInfo>[] = columns.filter(c =>
  ["type", "name"].includes(c.id),
);

export const DetailsList = ({
  contacts,
  loading,
  onEndReached,
  activeRowId,
  getRowLink,
  onActiveRowChange,
  renderEmptyList,
}: Props) => {
  return (
    <PlainListView
      withoutScroll={true}
      data={contacts}
      keyExtractor={item => item.id as string}
      headerHeight={36}
      rowHeight={63}
      headerStyle={styles.header as ViewStyle}
      headerBackgroundColor="#EDEDED"
      groupHeaderHeight={36}
      extraInfo={undefined}
      columns={columns}
      smallColumns={smallColumns}
      activeRowId={activeRowId}
      onActiveRowChange={onActiveRowChange}
      loading={loading}
      onEndReached={onEndReached}
      getRowLink={getRowLink}
      renderEmptyList={renderEmptyList}
      breakpoint={breakpoints.tiny}
    />
  );
};
