import AsyncStorage from "@react-native-async-storage/async-storage";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { useEffect, useState } from "react";
import { StyleSheet, ViewStyle } from "react-native";
import {
  backgroundColorVariants,
  borderColorVariants,
  fontColorVariants,
} from "../styles/constants";
import { t } from "../utils/i18n";
import { Button } from "./forms/Button";

const styles = StyleSheet.create({
  container: {
    paddingVertical: 12,
    paddingHorizontal: 20,
    backgroundColor: backgroundColorVariants.neutral100,
    borderWidth: 1,
    borderColor: borderColorVariants.neutral200,
    borderRadius: 8,
    boxShadow: "none",
    gap: 8,
  },
  title: {
    fontSize: 16,
    color: fontColorVariants.neutral800,
  },
  text: {
    fontSize: 12,
    fontWeight: "500",
    color: fontColorVariants.neutral500,
  },
  link: {
    color: fontColorVariants.primary500,
    textDecorationLine: "underline",
  },
  buttons: {
    gap: 8,
  },
});

type Props = {
  id?: string;
  title: string;
  text: string;
  style?: ViewStyle;
};

export function CustomTile({ id, title, text, style }: Props) {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (id == null) {
      return;
    }

    AsyncStorage.getItem(id)
      .then(value => {
        if (value === "true") {
          setVisible(false);
        }
      })
      .catch(() => {});
  }, [id]);

  if (!visible) {
    return null;
  }

  const parseText = (text: string) => {
    return text.split("\n").map((line, index) => {
      const links = line.split(/(\[.*?\]\(.*?\))/g);
      return (
        <LakeText key={index} style={styles.text}>
          {links.map(link => parseLine(link))}
        </LakeText>
      );
    });
  };

  const parseLine = (text: string) => {
    const linkMatch = text.match(/\[(.+)\]\((.+)\)/);
    if (linkMatch) {
      const [, text, url] = linkMatch;
      return (
        <LakeText
          style={[styles.text, styles.link]}
          onPress={() => {
            window.open(url, "_blank");
          }}
        >
          {text}
        </LakeText>
      );
    }
    return <LakeText style={styles.text}>{text}</LakeText>;
  };

  return (
    <Tile style={[styles.container, style]}>
      <LakeText style={styles.title}>{title}</LakeText>

      {parseText(text)}

      <Space height={4} />

      <Box direction="row" style={styles.buttons}>
        <Button size="small" onPress={() => setVisible(false)}>
          {t("customTile.ok")}
        </Button>

        {id != null && (
          <Button
            mode="tertiary"
            size="small"
            onPress={() => {
              setVisible(false);
              AsyncStorage.setItem(id, "true").catch(() => {});
            }}
          >
            {t("customTile.neverShowAgain")}
          </Button>
        )}
      </Box>
    </Tile>
  );
}
