import { ChoicePicker } from "@components/ChoicePicker";
import { DecimalInput } from "@components/DecimalInput";
import { IconChoice } from "@components/IconChoice";
import { Button } from "@components/forms/Button";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { Space } from "@swan-io/lake/src/components/Space";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { useEffect, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { CreatePurchaseState, PurchaseCategoryConcept } from "../../../features/invoices/types";
import {
  PurchaseCategory,
  PurchaseInvoiceCreateStaticDocument,
  PurchaseSubcategory,
} from "../../../graphql/crm";
import { common } from "../../../styles/common";
import { t } from "../../../utils/i18n";
import { extractQueryData } from "../../../utils/validations";
import { CategorySuggestion } from "./PurchaseCategorySuggestion";

const styles = StyleSheet.create({
  title: {
    fontSize: 20,
    fontWeight: "600",
    marginBottom: 8,
  },
  subtitle: {
    marginBottom: 8,
  },
  column: {
    flexBasis: "calc(50% - 12px)",
  },
  depreciableContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

type Props = {
  form: PurchaseCategoryConcept;
  invoiceType?: string;
};

export const PurchaseRightPanel = ({ form, invoiceType }: Props) => {
  const [subcategories, setSubcategories] = useState<PurchaseSubcategory[]>([]);
  const [categories, setCategories] = useState<PurchaseCategory[]>([]);
  const [depreciable, setDepreciable] = useState(false);

  const { data } = useUrqlQuery({ query: PurchaseInvoiceCreateStaticDocument }, []);
  const PurchaseCreateStaticData = extractQueryData(
    data,
    "value.value.purchaseInvoiceCreateStatic",
  );
  const currentCategories = extractQueryData(
    PurchaseCreateStaticData,
    "currentCategories",
  ) as PurchaseCategory[];
  const nonCurrentCategories = extractQueryData(
    PurchaseCreateStaticData,
    "nonCurrentCategories",
  ) as PurchaseCategory[];
  const allSubcategories = extractQueryData(
    PurchaseCreateStaticData,
    "subcategories",
  ) as PurchaseSubcategory[];

  useEffect(() => {
    setCategories(invoiceType === "current" ? currentCategories : nonCurrentCategories);
  }, [invoiceType]);

  function changeFields({ category }: Partial<CreatePurchaseState>) {
    setSubcategories(allSubcategories.filter(subcategory => subcategory.categoryId == category));
  }

  useEffect(() => {
    const listener = form.listenFields(["category", "subcategory"], ({ category }) => {
      changeFields({ category: category?.value });
    });

    changeFields({ category: form.getFieldState("category").value });

    return () => listener();
  }, [form.listenFields, data]);

  const renderItem = (item: string, selected?: string) => {
    const category = categories.find(c => c.id === item);
    return (
      category && (
        <IconChoice
          sourceUri={category.iconUrl as string}
          name={category.description}
          isSelected={category?.id === selected}
        />
      )
    );
  };

  const renderItemSubcategories = (item: string, selected?: string) => {
    const subCategory = subcategories.find(c => c.id === item);
    return (
      subCategory && (
        <IconChoice
          sourceUri={subCategory.iconUrl as string}
          name={subCategory.description}
          isSelected={subCategory?.id === selected}
        />
      )
    );
  };

  const checkDepreciable = (subcategory: string) => {
    const subcategoryData = allSubcategories.find(c => c.id === subcategory);
    if (subcategoryData) {
      setDepreciable(subcategoryData.depreciable);
    }
  };

  const getSubtitle = (subcategory?: string) => {
    const categoryName = categories.find(
      c => c.id == form.getFieldState("category")?.value,
    )?.description;
    const subcategoryName = subcategories.find(c => c.id === subcategory)?.description;

    if (categoryName != null) {
      return `${categoryName}${subcategoryName != null ? ` / ${subcategoryName}` : ""}`;
    }
  };

  useEffect(() => {
    checkDepreciable(form.getFieldState("subcategory")?.value as string);
  }, [form.getFieldState("subcategory")?.value]);

  return (
    <>
      <Space height={24} />

      <CategorySuggestion
        categories={[...currentCategories, ...nonCurrentCategories]}
        subcategories={allSubcategories}
        concept={form}
      />

      <Space height={24} />

      <form.Field name="category">
        {({ value, onChange, error }) =>
          subcategories.length === 0 ? (
            <>
              <Text style={styles.title}>{t("invoices.purchaseCategory")}</Text>

              <ChoicePicker
                items={categories.map(({ id }) => id)}
                renderItem={item => renderItem(item, value)}
                value={value}
                onChange={value => {
                  onChange(value);
                  form.onChange();
                }}
              />

              <Text style={common.error}>{error}</Text>
            </>
          ) : null
        }
      </form.Field>

      <form.Field name="subcategory">
        {({ value, onChange, error }) =>
          subcategories.length > 0 ? (
            <>
              <Text style={styles.title}>{t("invoices.subCategory")}</Text>
              <Text style={styles.subtitle}>{getSubtitle(value)}</Text>

              <ChoicePicker
                items={subcategories.map(({ id }) => id)}
                renderItem={item => renderItemSubcategories(item, value)}
                value={value}
                onChange={value => {
                  onChange(value);
                  form.onChange();
                }}
              />

              <Text style={common.error}>{error}</Text>
              <Space height={16} />

              <Button
                size="small"
                mode="tertiary"
                style="outlined"
                onPress={() => {
                  form.setFieldValue("category", undefined);
                  form.setFieldValue("subcategory", undefined);
                }}
              >
                {t("invoices.backToCategories")}
              </Button>
            </>
          ) : null
        }
      </form.Field>

      {depreciable && (
        <View style={styles.depreciableContainer}>
          <form.Field name="usefulLife">
            {({ value, onChange, error }) => (
              <LakeLabel
                label={t("invoices.usefulLife")}
                style={styles.column}
                render={id => (
                  <DecimalInput
                    id={id}
                    value={value?.toString()}
                    hideErrors={error === undefined}
                    error={error}
                    onChangeDecimal={value => {
                      onChange(Number(value));
                    }}
                  />
                )}
              />
            )}
          </form.Field>

          <form.Field name="amortizationRate">
            {({ value, onChange, error }) => (
              <LakeLabel
                label={t("invoices.amortizationRate")}
                style={styles.column}
                render={id => (
                  <DecimalInput
                    id={id}
                    value={value?.toString()}
                    hideErrors={error === undefined}
                    error={error}
                    onChangeDecimal={value => {
                      onChange(Number(value));
                    }}
                  />
                )}
              />
            )}
          </form.Field>
        </View>
      )}
    </>
  );
};
