import { BottomPanel } from "@swan-io/lake/src/components/BottomPanel";
import { Box } from "@swan-io/lake/src/components/Box";
import { Space } from "@swan-io/lake/src/components/Space";
import { useEffect, useState } from "react";
import { FaCamera } from "react-icons/fa6";
import { Image, Pressable, StyleSheet, Text, View } from "react-native";
import { backgroundColorVariants } from "../../../styles/constants";
import { t } from "../../../utils/i18n";
import { UploadFile } from "./UploadFile";
import { WebCam } from "./WebCam";

const styles = StyleSheet.create({
  container: {
    position: "sticky",
    top: 16,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    left: 0,
    backgroundColor: backgroundColorVariants.transparent,
    height: "100%",
    maxHeight: "calc(100vh - 32px)",
    width: "25%",
    borderRadius: 15,
    zIndex: 999,
    marginRight: 20,
    transition: "all 0.5s ease",
  },
  containerFile: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    left: 0,
    backgroundColor: backgroundColorVariants.white,
    height: "100%",
    maxHeight: "calc(100vh - 32px)",
    borderRadius: 15,
    zIndex: 999,
    marginRight: 20,
    width: "36%",
    transition: "all 0.5s ease",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    left: 0,
    backgroundColor: backgroundColorVariants.transparent,
    width: "fit-content",
    borderRadius: 15,
    zIndex: 999,
    marginRight: 20,
    transition: "all 0.5s ease",
  },
  pdf: {
    width: "100%",
    height: "100%",
    backgroundSize: "unset",
  },
  openCam: {
    display: "flex",
    alignItems: "center",
    backgroundColor: backgroundColorVariants.white,
    padding: 32,
    borderWidth: 1,
    borderColor: backgroundColorVariants.gray200,
    borderStyle: "dashed",
    borderRadius: 5,
  },
  openCamHover: {
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.08)",
  },
  openCamText: {
    fontSize: 16,
  },
  openCamSubtext: {
    fontSize: 16,
    color: backgroundColorVariants.gray400,
  },
});

type Props = {
  inputFile?: string;
  setOpen: (open: boolean) => void;
  onUpload: (file: File) => Promise<void>;
};

const PdfViewer = ({ url }: { url: string }) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    Image.getSize(url, (width, height) => setDimensions({ width, height }));
  }, [url]);

  return (
    <View style={styles.pdf}>
      {url.includes(".pdf") ? (
        <iframe src={url} style={styles.pdf}>
          This browser does not support PDFs. Please download the PDF to view it:{" "}
          <a href={url}>Download PDF</a>.
        </iframe>
      ) : (
        <Image
          source={{ uri: url }}
          style={[
            styles.pdf,
            {
              width: dimensions.width,
              height: dimensions.height,
            },
          ]}
          resizeMode="contain"
        />
      )}
    </View>
  );
};

export const LeftPanel = ({ inputFile, setOpen, onUpload }: Props) => {
  const [file, setFile] = useState<string>(inputFile as string);
  const [cameraImage, setCameraImage] = useState<File | null>(null);

  const [hover, setHover] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (Boolean(inputFile)) {
      setFile(inputFile as string);
    }
  }, [inputFile]);

  return (
    <View
      style={[
        styles.container,
        file.includes(".pdf") ? styles.containerFile : styles.imageContainer,
      ]}
    >
      {Boolean(file) ? (
        <PdfViewer url={`${file}`} />
      ) : (
        <View>
          <UploadFile onUpload={onUpload} cameraImage={cameraImage} />
          <Space height={16} />

          <Pressable
            onHoverIn={() => setHover(true)}
            onHoverOut={() => setHover(false)}
            onPress={() => {
              setOpen(false);
              setVisible(true);
            }}
          >
            <Box style={[styles.openCam, hover && styles.openCamHover]}>
              <FaCamera size={32} color={"#6366f1"} />
              <Space height={12} />
              <Text style={styles.openCamText}>{t("invoices.scan")}</Text>
              <Space height={4} />
              <Text style={styles.openCamSubtext}>{t("invoices.scanText")}</Text>
            </Box>
          </Pressable>

          <BottomPanel visible={visible} onPressClose={() => setVisible(false)}>
            <WebCam setCameraImage={setCameraImage} setVisible={setVisible} />
          </BottomPanel>
        </View>
      )}
    </View>
  );
};
