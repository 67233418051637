import { StyleSheet, View } from "react-native";
import { backgroundColorVariants } from "../styles/constants";

const styles = StyleSheet.create({
  contain: {
    minWidth: 250,
    paddingTop: 64,
    paddingHorizontal: 4,
    backgroundColor: backgroundColorVariants.sidebar,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

type Props = {
  children?: React.ReactNode;
};

export const Sidebar = ({ children }: Props) => {
  return children != null && <View style={styles.contain}>{children}</View>;
};
