import { env } from "../../utils/env";

export const get_pdf = async (id: string) => {
  return fetch(`${env.BACKEND_URL}/api/invoice/pdf/${id}`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/pdf",
      "X-User-Timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });
};
