import { AutoWidthImage } from "@swan-io/lake/src/components/AutoWidthImage";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { ReactNode } from "react";
import { StyleSheet, Text, View } from "react-native";
import { COLORS } from "../values/colors";

type Props = {
  subtitle: string;
  description: string;
  icon: string;
  children?: ReactNode;
};

export const InfoMarkenting = ({ subtitle, description, icon }: Props) => {
  return (
    <Box direction="row" alignItems="center" style={styles.group}>
      <AutoWidthImage sourceUri={icon} height={25} alt={subtitle} />

      <View style={styles.text}>
        <LakeHeading level={2} style={styles.subtitle}>
          {subtitle}
        </LakeHeading>

        <Text style={styles.description}>{description}</Text>
      </View>
    </Box>
  );
};

const styles = StyleSheet.create({
  group: {
    gap: 17,
  },
  text: {
    width: "calc(100% - 45px)",
    gap: 5,
  },
  subtitle: {
    fontSize: 20,
    fontWeight: "600",
    color: COLORS.WHITE,
  },
  description: {
    fontSize: 14,
    lineHeight: 21,
    color: COLORS.WHITE,
  },
});
