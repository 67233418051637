import { AutoWidthImage } from "@swan-io/lake/src/components/AutoWidthImage";
import { Box } from "@swan-io/lake/src/components/Box";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { useResponsive } from "@swan-io/lake/src/hooks/useResponsive";
import { StyleSheet, Text, View, ViewStyle } from "react-native";
import {
  backgroundColorVariants,
  borderColorVariants,
  fontColorVariants,
} from "../styles/constants";

const styles = StyleSheet.create({
  box: {
    flexWrap: "wrap",
    borderRadius: 25,
    boxShadow:
      "0 67.609px 54.087px 0 rgba(201, 201, 201, 0.07), 0 28.246px 22.596px 0 rgba(108, 73, 172, 0.05), 0 15.101px 12.081px 0 rgba(108, 73, 172, 0.04), 0 8.466px 6.773px 0 rgba(108, 73, 172, 0.04), 0 4.496px 3.597px 0 rgba(108, 73, 172, 0.03), 0 1.871px 1.497px 0 rgba(108, 73, 172, 0.02)",
    paddingVertical: 24,
    paddingHorizontal: 18,
    backgroundColor: backgroundColorVariants.dark,
    color: fontColorVariants.white,
    marginBottom: 12,
  },
  boxMobile: {
    paddingHorizontal: 6,
  },
  image: {
    position: "absolute",
    top: -48,
    right: 30,
    zIndex: 1,
  },
  item: {
    paddingHorizontal: 24,
  },
  itemMobile: {
    paddingVertical: 6,
    paddingHorizontal: 12,
  },
  title: {
    fontSize: 18,
    color: fontColorVariants.white,
  },
  value: {
    fontSize: 28,
    lineHeight: 45,
    fontWeight: "600",
    color: fontColorVariants.white,
  },
  line: {
    width: 1,
    height: 65,
    backgroundColor: borderColorVariants.white,
  },
});

type Item = {
  title: string;
  value: string;
};

type Props = {
  data: Item[];
  image: string;
  imageHeight: number;
  style?: ViewStyle;
};

export const HorizonalValues = ({ data, image, imageHeight, style }: Props) => {
  const { desktop } = useResponsive(breakpoints.medium);

  return (
    <Box
      direction="row"
      alignItems="center"
      style={[styles.box, !desktop && styles.boxMobile, style]}
    >
      {desktop && <AutoWidthImage sourceUri={image} height={imageHeight} style={styles.image} />}

      {data.map((item, index) => (
        <Box direction="row" key={index}>
          <Box style={[styles.item, !desktop && styles.itemMobile]}>
            <Text style={styles.title}>{item.title}</Text>
            <Text style={styles.value}>{item.value}</Text>
          </Box>

          {desktop && index < data.length - 1 && <View style={styles.line} />}
        </Box>
      ))}
    </Box>
  );
};
